import { SxProps, TableRow, alpha } from '@mui/material';
import { useTheme } from '@mui/material/styles';

interface StyledTableRowProps {
  sx?: SxProps;
  onClick?: () => void;
  children: JSX.Element | JSX.Element[] | string;
}

export default function StyledTableRow({ sx, onClick, children }: StyledTableRowProps) {
  const theme = useTheme();
  return (
    <TableRow
      onClick={onClick}
      sx={{
        '& td': {
          padding: '1em 0',
          textAlign: 'start'
        },
        '&:hover': {
          backgroundColor: alpha(theme.palette.text.secondary, 0.1)
        },
        ...sx
      }}>
      {children}
    </TableRow>
  );
}
