import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
  Divider
} from '@mui/material';
import WarningButton from '../buttons/WarningButton';
import GreyButton from '../buttons/GreyButton';

type ConfirmationDialogProps = {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  title: string;
  content: string;
};

export function WarningConfirmDialog({ open, onClose, onConfirm, title, content }: ConfirmationDialogProps) {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>
        <Typography variant="h4" fontWeight="bold" sx={{ paddingBottom: '0.5em' }}>
          {title}
        </Typography>
        <Divider />
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Typography variant="body2">{content}</Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <GreyButton variant="contained" onClick={onClose}>
          Close
        </GreyButton>
        <WarningButton variant="contained" onClick={onConfirm}>
          Confirm
        </WarningButton>
      </DialogActions>
    </Dialog>
  );
}
