import { MenuItem, Select, Stack, SxProps } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Controller, Control, FieldError } from 'react-hook-form';
import ErrorFormInput from '../error';

interface SelectFormInputProps {
  parentSx?: SxProps;
  sx?: SxProps;
  required?: boolean;
  control: Control<any>;
  name: string;
  error?: FieldError;
  options: SelectFormInputOptionsProps[];
}

export interface SelectFormInputOptionsProps {
  name: string;
  value: string;
}

export default function SelectFormInput({
  parentSx,
  sx,
  required = false,
  control,
  name,
  error,
  options
}: SelectFormInputProps) {
  const theme = useTheme();

  return (
    <Stack sx={parentSx}>
      <Controller
        rules={{ required: required }}
        control={control}
        name={name}
        defaultValue={''}
        render={({ field }) => (
          <Select
            {...field}
            IconComponent={KeyboardArrowDownIcon}
            sx={{
              backgroundColor: theme.palette.background.default,
              minWidth: '12em',
              '& .MuiSvgIcon-root': {
                fontSize: '1.5em',
                color: theme.palette.text.primary
              },
              ...sx
            }}>
            {options.map((option) => {
              return (
                <MenuItem key={option.value} value={option.value}>
                  {option.name}
                </MenuItem>
              );
            })}
          </Select>
        )}
      />
      {error && <ErrorFormInput error={error} />}
    </Stack>
  );
}
