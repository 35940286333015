/* eslint-disable react-hooks/exhaustive-deps */
import { MenuItem, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { DarkUpperSection, LoadingSpinner, PageSection, StyledButton, StyledSelect } from '../../components';
import { useAlert, useCurrentUser, useDatastore } from '../../hooks';
import { useEffect, useState } from 'react';
import { UserDetailsProps } from '../../datastore/userDetails';
import { LocationProps, UnknownLocation, LocationType } from '../../datastore/locations';
import { PrinterProps } from '../../datastore/printers';
import { useNavigate } from 'react-router-dom';
import { useDirtyContext } from '../../hooks/useDirtyProvider';
import { ConfirmationDialog } from '../../components/modals/ConfirmDialog';

export default function Settings() {
  const theme = useTheme();
  const user = useCurrentUser();
  const navigate = useNavigate();
  const { isDirty, setIsDirty } = useDirtyContext();
  const [details, setDetails] = useState<UserDetailsProps>({} as UserDetailsProps);
  const [locations, setLocations] = useState<LocationProps[]>([]);
  const [printers, setPrinters] = useState<PrinterProps[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [navigateDestination, setNavigateDestination] = useState('');
  const [originalLocationName, setOriginalLocationName] = useState<string>('');
  const [originalPrinter, setOriginalPrinter] = useState<PrinterProps | null>();
  const [openDirtyDialog, setOpenDirtyDialog] = useState(false);
  const store = useDatastore();
  const alert = useAlert();

  useEffect(() => {
    async function getData() {
      const results = await store.userDetails.get(user.cognitoUser.username);
      if (results) {
        setDetails(results);
        setOriginalLocationName(results.location?.name || '');
        setOriginalPrinter(results.printer ?? null);
        setIsLoading(false);
      }
    }

    async function getLocationData() {
      const results = await store.locations.getList();
      const mainLocations = results?.filter((l) => l.type === LocationType.mainLocation && !l.isDisabled);
      if (mainLocations) {
        setLocations(mainLocations);
      }
    }

    getData();
    getLocationData();
  }, []);

  useEffect(() => {
    async function getPrinterData() {
      const results = await store.printers.getAll();
      const enabledPrinters = results?.filter((p) => !p.isDisabled);
      if (enabledPrinters) {
        setPrinters(enabledPrinters);
      }
    }
    getPrinterData();
  }, [details.printer]);

  function updateLocation(name: string) {
    const location = locations.find((l) => l.name === name);
    const isOriginalLocation = location?.name === originalLocationName;
    setDetails((prevDetails) => ({
      ...prevDetails,
      location,
      printer: originalPrinter
    }));

    setIsDirty(!isOriginalLocation);
  }

  async function updatePrinter(value: string | null) {
    if (value) {
      const newPrinter = await store.printers.getById(value);
      setDetails((prevState) => ({
        ...prevState,
        printer: newPrinter
      }));

      setIsDirty(newPrinter?.id !== originalPrinter?.id);
    }
  }

  async function handleSaveDetails() {
    if (details?.location?.id === undefined) {
      alert('Please select a location', 'warning');
      return;
    }
    const results = await store.userDetails.update(details);
    updateDetails(results);
  }

  function updateDetails(results: UserDetailsProps) {
    if (results) {
      setDetails(results);
      setIsDirty(false);
      setOriginalLocationName(results.location?.name || '');
      setOriginalPrinter(results.printer);
      alert('Settings updated.', 'success');
    } else {
      alert('Could not update settings', 'error');
    }
  }

  function handleNavigateAdmin(destination: string) {
    setNavigateDestination(destination);
    if (isDirty) {
      setOpenDirtyDialog(true);
    } else {
      navigate(`/settings/${destination}`);
    }
  }

  const orderedLocations = [
    { value: '', name: 'Choose Location', id: crypto.randomUUID() },
    ...locations.sort((a, b) => a.name.localeCompare(b.name))
  ].filter((location) => location.name !== UnknownLocation.Name);

  const orderedPrinters = printers.sort((a, b) => a.name.localeCompare(b.name));

  return (
    <>
      <DarkUpperSection>
        <Stack spacing={4} sx={{ padding: '1em 0' }}>
          <Typography variant="h2" fontWeight="bold" color={theme.palette.primary.contrastText}>
            Settings
          </Typography>
          <Stack direction="row" spacing={4} sx={{ alignItems: 'center' }}>
            <Typography variant="h4" color={theme.palette.primary.contrastText}>
              Set your location:
            </Typography>
            <LoadingSpinner isLoading={isLoading}>
              <StyledSelect value={details?.location?.name || ''} onChange={(e) => updateLocation(e)}>
                {orderedLocations?.map((location) => (
                  <MenuItem key={location.id} value={location.name}>
                    {location.name}
                  </MenuItem>
                ))}
              </StyledSelect>
            </LoadingSpinner>
            <Stack sx={{ flex: 1, alignItems: 'end' }}>
              <StyledButton
                variant="contained"
                sx={{ whiteSpace: 'nowrap' }}
                onClick={handleSaveDetails}
                disabled={!isDirty}>
                Save Changes
              </StyledButton>
            </Stack>
          </Stack>
        </Stack>
      </DarkUpperSection>
      <PageSection>
        <Stack spacing={4} sx={{ padding: '2em 0' }}>
          <Stack spacing={1}>
            <Typography variant="h4" fontWeight="bold">
              Configure Printer
            </Typography>
            <Typography>Set your printer</Typography>
          </Stack>
          <LoadingSpinner isLoading={isLoading}>
            <Stack direction="row" spacing={2} justifyContent="space-between" width="100%">
              <Stack spacing={2} sx={{ width: '15%' }}>
                <StyledSelect
                  value={details!.printer?.id || ''}
                  onChange={(e) => updatePrinter(e)}
                  disabled={!details?.location?.id}
                  sx={{ width: 'auto' }}>
                  {orderedPrinters.map((printer) => (
                    <MenuItem key={printer.id} value={printer.id}>
                      {printer.name}
                    </MenuItem>
                  ))}
                </StyledSelect>
              </Stack>
            </Stack>
          </LoadingSpinner>
        </Stack>
      </PageSection>
      {user.isAdmin && (
        <PageSection>
          <Stack spacing={4} sx={{ padding: '2em 0' }}>
            <Stack spacing={1}>
              <Typography variant="h4" fontWeight="bold">
                Admin Settings
              </Typography>
              <Typography color={theme.palette.text.secondary}>Manage FTL settings</Typography>
            </Stack>
            <LoadingSpinner isLoading={isLoading}>
              <Stack direction="row" spacing={2} justifyContent="space-between" width="100%">
                <Stack spacing={2} sx={{ width: '20%' }}>
                  <StyledButton
                    variant="contained"
                    sx={{ whiteSpace: 'nowrap' }}
                    onClick={() => handleNavigateAdmin('locations')}>
                    Location Settings
                  </StyledButton>
                  <StyledButton
                    variant="contained"
                    sx={{ whiteSpace: 'nowrap' }}
                    onClick={() => handleNavigateAdmin('printers')}>
                    Printer Settings
                  </StyledButton>
                </Stack>
              </Stack>
            </LoadingSpinner>
          </Stack>
        </PageSection>
      )}

      <ConfirmationDialog
        open={openDirtyDialog}
        onClose={() => setOpenDirtyDialog(false)}
        onConfirm={() => navigate(`/settings/${navigateDestination}`)}
        title="Unsaved Changes"
        content="You have unsaved changes. Are you sure you want to leave the page?"
      />
    </>
  );
}
