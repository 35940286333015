import Dialog from '@mui/material/Dialog';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';

interface LoadingModalProps {
  isLoading: boolean;
  titleText: string;
  bodyText: string;
}

export default function LoadingModal({ isLoading, titleText, bodyText }: LoadingModalProps) {
  return (
    <Dialog open={isLoading} maxWidth="xs" fullWidth>
      <Stack
        spacing={3}
        sx={{
          padding: '1em 2em',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center'
        }}>
        <Typography variant="h4" fontWeight="bold">
          {titleText}
        </Typography>
        <CircularProgress />
        <Typography variant="body2">{bodyText}</Typography>
      </Stack>
    </Dialog>
  );
}
