import { Checkbox, FormControlLabel, Stack } from '@mui/material';
import { Controller, Control, FieldError } from 'react-hook-form';
import ErrorFormInput from '../error';

interface CheckboxFormInputProps {
  control: Control<any>;
  name: string;
  label?: string;
  error?: FieldError;
}

export default function CheckboxFormInput({ control, name, label, error }: CheckboxFormInputProps) {
  return (
    <Stack>
      <Controller
        control={control}
        name={name}
        defaultValue={false}
        render={({ field }) => (
          <FormControlLabel
            control={<Checkbox {...field} onChange={(e) => field.onChange(e.target.checked)} />}
            label={label?.length ? label : ''}
            checked={field.value}
          />
        )}
      />
      {error && <ErrorFormInput error={error} />}
    </Stack>
  );
}
