/* eslint-disable react-hooks/exhaustive-deps */
import { Dialog, Divider, IconButton, Stack, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import StyledButton from '../buttons/StyledButton';
import { SubmitHandler, useForm } from 'react-hook-form';
import { NumberFieldFormInput } from '../formInputs';
import type { Dimensions } from '../../common';

interface WeightDimensionsFormProps {
  open: boolean;
  data?: Dimensions;
  onSubmit: (data: Dimensions) => void;
  onClose: () => void;
  onBack?: () => void;
}

export default function WeightDimensionsForm({ open, onSubmit, onClose, onBack, data }: WeightDimensionsFormProps) {
  const {
    handleSubmit,
    control,
    formState: { errors }
  } = useForm<Dimensions>({ defaultValues: data });

  const onSubmitForm: SubmitHandler<Dimensions> = (data) => onSubmit(data);

  return (
    <Dialog open={open} maxWidth="xs" fullWidth>
      <form onSubmit={handleSubmit(onSubmitForm)}>
        <Stack spacing={3} sx={{ padding: '1em 2em' }}>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            {onBack && (
              <IconButton onClick={onBack}>
                <ArrowBackIcon />
              </IconButton>
            )}
            <Typography variant="h4" fontWeight="bold">
              Weight + Dimensions
            </Typography>
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Stack>
          <Typography variant="body2">
            All SSCCs and large items must include accurate weights and dimensions before shipping.
          </Typography>
          <Divider />
          <Stack spacing={1}>
            <Typography>Weight</Typography>
            <NumberFieldFormInput
              name="weight"
              label="Enter Weight"
              control={control}
              required
              endIcon={<Typography>KG</Typography>}
              error={errors['weight']}
            />
          </Stack>
          <Divider />
          <Stack spacing={1}>
            <Typography>Dimensions</Typography>
            <Typography variant="body2">
              Please enter the item dimensions in <b>Centimetres</b>
            </Typography>
            <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center">
              <NumberFieldFormInput
                name="height"
                label="Height"
                control={control}
                required
                sx={{ minWidth: 'auto' }}
                error={errors['height']}
                wholeNumbersOnly
              />
              <NumberFieldFormInput
                name="width"
                label="Width"
                control={control}
                required
                sx={{ minWidth: 'auto' }}
                error={errors['width']}
                wholeNumbersOnly
              />
              <NumberFieldFormInput
                name="depth"
                label="Depth"
                control={control}
                required
                sx={{ minWidth: 'auto' }}
                error={errors['depth']}
                wholeNumbersOnly
              />
            </Stack>
          </Stack>

          <StyledButton variant="contained" type="submit">
            Next: Move to Staging
          </StyledButton>
        </Stack>
      </form>
    </Dialog>
  );
}
