import { Grid, SxProps } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import logo from '../../assets/images/headerlogo.png';

type UpperSectionProps = {
  sx?: SxProps;
  children?: string | JSX.Element | JSX.Element[];
};

export function PageSection({ children, sx }: UpperSectionProps) {
  return (
    <Grid container sx={sx} marginTop={'4em'} columns={14}>
      <Grid item xs />
      <Grid item xs={12}>
        {children}
      </Grid>
      <Grid item xs />
    </Grid>
  );
}

export function DarkUpperSection({ children }: UpperSectionProps) {
  const theme = useTheme();
  return (
    <PageSection
      sx={{
        backgroundColor: theme.palette.secondary.main,
        position: 'sticky',
        top: '4em',
        zIndex: 1
      }}>
      {children}
    </PageSection>
  );
}

export function PageSectionWithLogo({ children, sx }: UpperSectionProps) {
  return (
    <Grid container sx={sx} columns={14}>
      <img src={logo} alt="Logo" height="35em" style={{ paddingTop: '0.30em', paddingLeft: '0.50em' }} />
      <Grid item xs />
      <Grid item xs={12}>
        {children}
      </Grid>
      <Grid item xs />
    </Grid>
  );
}
