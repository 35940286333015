import { InputAdornment, SxProps, TextField } from '@mui/material';
import { useTheme } from '@mui/material/styles';

interface StyledTextFieldProps {
  sx?: SxProps;
  placeholder?: string;
  value?: string | null;
  startIcon?: JSX.Element;
  endIcon?: JSX.Element;
  onKeyPress?: (value: string) => void;
  onChange?: (value: string) => void;
}

export default function StyledTextField({
  sx,
  placeholder,
  value,
  onChange,
  onKeyPress,
  startIcon,
  endIcon
}: StyledTextFieldProps) {
  const theme = useTheme();

  return (
    <TextField
      value={value}
      placeholder={placeholder}
      InputProps={{
        startAdornment: startIcon ? <InputAdornment position="start">{startIcon}</InputAdornment> : null,
        endAdornment: endIcon ? <InputAdornment position="end">{endIcon}</InputAdornment> : null,
        sx: {
          '& input': {
            padding: '0.8em 1em'
          }
        }
      }}
      onChange={(e) => (onChange ? onChange(e.target.value) : null)}
      onKeyDown={(e) => (onKeyPress ? onKeyPress(e.key) : null)}
      sx={{
        backgroundColor: theme.palette.background.default,
        borderRadius: '4px',
        minWidth: '12em',
        '& .MuiSvgIcon-root': {
          fontSize: '1.5em',
          color: theme.palette.text.primary
        },
        ...sx
      }}
    />
  );
}
