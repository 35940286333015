import { createContext, useContext, useEffect, useState } from 'react';
import Login from '../pages/login';
import { Auth, DataStore, Hub } from 'aws-amplify';
import { useLoading } from './useLoading';

export enum Roles {
  readonly = 'ReadOnly',
  admin = 'Admin',
  operator = 'Operator'
}

interface UserProviderProps {
  children?: React.ReactNode;
}

interface CurrentUserProps {
  cognitoUser?: any;
  name: string;
  email: string;
  role: string;
  isAdmin: boolean;
  isReadOnly: boolean;
}

const UserContext = createContext<CurrentUserProps>({
  cognitoUser: {},
  name: '',
  email: '',
  role: '',
  isAdmin: false,
  isReadOnly: true
});

const useCurrentUser = () => useContext(UserContext);

const UserLoginProvider = ({ children }: UserProviderProps) => {
  const [cognitoUser, setCognitoUser] = useState<any>(null);
  const setLoading = useLoading();

  useEffect(() => {
    const listener = Hub.listen('auth', ({ payload: { event, data } }) => {
      switch (event) {
        case 'signIn':
        case 'cognitoHostedUI':
          getUser().then((userData) => {
            setCognitoUser(userData);
          });
          break;
        case 'signOut':
          logout();
          break;
        case 'signIn_failure':
        case 'cognitoHostedUI_failure':
          console.error('Sign in failure', data);
          break;
        case 'tokenRefresh_failure':
          console.error('token refresh failed');
          logout();
          break;
      }
    });

    getUser().then((userData) => {
      setCognitoUser(userData);
    });

    return () => {
      listener();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function getUser() {
    try {
      setLoading(true);
      const userData = await Auth.currentAuthenticatedUser({ bypassCache: true });
      setLoading(false);
      return userData;
    } catch (e) {
      setLoading(false);
      console.error('Not signed in, error: ', e);
    }
  }

  function login() {
    //note: add a field to federated sign in to specify the identity provider once it's set up.
    Auth.federatedSignIn({ customProvider: 'LoginWithOkta' });
  }

  function logout() {
    setCognitoUser(null);
    DataStore.clear();
  }

  function getGroups() {
    if (cognitoUser?.attributes) {
      const customGroups: string = cognitoUser?.attributes['custom:groups'];

      if (customGroups) {
        const parsed = customGroups
          .replace('[', '')
          .replace(']', '')
          .split(',')
          .map((item) => item.trim().toLowerCase());
        return parsed;
      }
    }
    return [];
  }

  function getRole() {
    if (!cognitoUser?.attributes) {
      return Roles.readonly;
    }

    const groups = getGroups();
    const env = process.env.REACT_APP_ENV;
    const groupEnvironment = env === 'dev' ? 'dev' : env === 'nonprod' ? 'npd' : 'prd';
    const rolesInPriorityOrder = [
      `right.freighttransportlogistic.${groupEnvironment}.admin`,
      `right.freighttransportlogistic.${groupEnvironment}.operator`,
      `right.freighttransportlogistic.${groupEnvironment}.readonly`
    ];

    const highestRole = rolesInPriorityOrder.find((role) => groups.includes(role));

    switch (highestRole?.split('.')[3]) {
      case 'admin':
        return Roles.admin;
      case 'operator':
        return Roles.operator;
      case 'readonly':
      default:
        return Roles.readonly;
    }
  }
  const role = getRole();
  const currentUser = {
    cognitoUser,
    name: `${cognitoUser?.attributes?.given_name} ${cognitoUser?.attributes?.family_name}`,
    email: cognitoUser?.attributes?.email,
    role: role,
    isAdmin: role === Roles.admin,
    isReadOnly: role === Roles.readonly
  };

  const value = currentUser;

  return cognitoUser && Object.entries(cognitoUser).length ? (
    <UserContext.Provider value={value}>{children}</UserContext.Provider>
  ) : (
    <Login login={login} />
  );
};

export { useCurrentUser, UserLoginProvider };
