import { TableHead, TableRow } from '@mui/material';
import { useTheme } from '@mui/material/styles';

interface StyledTableHeadProps {
  children: JSX.Element | JSX.Element[];
}

export default function StyledTableHead({ children }: StyledTableHeadProps) {
  const theme = useTheme();

  return (
    <TableHead>
      <TableRow
        sx={{
          '& th': {
            color: theme.palette.text.secondary,
            padding: '1em 0 1.5em 0',
            borderBottomWidth: '0',
            textAlign: 'start'
          }
        }}>
        {children}
      </TableRow>
    </TableHead>
  );
}
