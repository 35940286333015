/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  FormControlLabel,
  IconButton,
  Pagination,
  Stack,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { DarkUpperSection, LoadingSpinner, PageSection, StyledButton } from '../../../components';
import { useEffect, useState } from 'react';
import { useAlert, useCurrentUser, useDatastore } from '../../../hooks';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import { PrinterProps } from '../../../datastore/printers';
import EditPrinterForm from './modals/EditPrinterModal';
import CreatePrinterForm from './modals/CreatePrinterModal';
import dayjs from 'dayjs';
import { adminActionType } from '../../../datastore/adminActionHistory';

export default function EditPrinters() {
  const theme = useTheme();
  const navigate = useNavigate();
  const store = useDatastore();
  const alert = useAlert();
  const user = useCurrentUser();
  const [printers, setPrinters] = useState<PrinterProps[]>([]);
  const [selectedPrinter, setSelectedPrinter] = useState<PrinterProps | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [filterText, setFilterText] = useState('');
  const [showDisabled, setShowDisabled] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [openCreatePrinter, setOpenCreatePrinter] = useState(false);
  const [openEditPrinter, setOpenEditPrinter] = useState(false);

  useEffect(() => {
    async function fetchPrinters() {
      try {
        setIsLoading(true);
        const results = await store.printers.getAll();
        if (!results) {
          alert('No printers found', 'warning');
          return;
        }
        setPrinters(results);
      } catch (error) {
        console.error('Error fetching printers:', error);
        alert('Error retrieving printers', 'error');
      } finally {
        setIsLoading(false);
      }
    }

    fetchPrinters();
  }, [store.locations]);

  function handlePageChange(event: React.ChangeEvent<unknown>, value: number) {
    setCurrentPage(value);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  function handleEditClick(printer: PrinterProps) {
    setSelectedPrinter(printer);
    setOpenEditPrinter(true);
  }

  async function submitEditForm(data: PrinterProps) {
    try {
      const originalPrinter = printers.find((print) => print.id === data.id);
      setPrinters(printers.map((print) => (print.id === data.id ? data : print)));

      const updatedPrinter = await store.printers.update(data);

      await store.adminActionHistories.create({
        id: crypto.randomUUID(),
        actionType: adminActionType.PRINTING_UPDATE,
        beforeAction: originalPrinter ? JSON.stringify(originalPrinter) : 'Error writing before action',
        afterAction: updatedPrinter ? JSON.stringify(updatedPrinter) : 'Error writing after action',
        actionBy: user.email,
        actionTime: dayjs()
      });

      alert('Updated Printer', 'success');
    } catch (error) {
      console.log('Error updating printer:', error);
      alert('Error updating printer', 'error');
    } finally {
      setOpenEditPrinter(false);
      setSelectedPrinter(null);
    }
  }

  async function createPrinterSubmit(data: PrinterProps) {
    try {
      setPrinters([...printers, data]);
      data.id = crypto.randomUUID();

      const createdPrinter = await store.printers.create(data);

      await store.adminActionHistories.create({
        id: crypto.randomUUID(),
        actionType: adminActionType.PRINTING_CREATE,
        beforeAction: null,
        afterAction: createdPrinter ? JSON.stringify(createdPrinter) : 'Error writing create action',
        actionBy: user.email,
        actionTime: dayjs()
      });

      alert('Created Printer', 'success');
    } catch (error) {
      console.log('Error creating printer:', error);
      alert('Error creating printer', 'error');
    } finally {
      setOpenCreatePrinter(false);
    }
  }

  const filteredPrinters = printers.filter(
    (printer) => printer.name.toLowerCase().includes(filterText.toLowerCase()) && (showDisabled || !printer.isDisabled)
  );
  const totalNumberOfPages = Math.ceil(filteredPrinters.length / 25);
  const paginatedPrinters = filteredPrinters.slice((currentPage - 1) * 25, currentPage * 25);

  if (!user.isAdmin) return null;

  return (
    <>
      <DarkUpperSection>
        <Stack spacing={4} sx={{ padding: '1em 0' }}>
          <Stack direction="row" alignItems="center">
            <IconButton onClick={() => navigate('/settings')}>
              <ArrowBackIcon fontSize="large" sx={{ color: theme.palette.primary.contrastText }} />
            </IconButton>
            <Typography variant="h2" fontWeight="bold" color={theme.palette.primary.contrastText}>
              Settings
            </Typography>
          </Stack>
          <Stack spacing={2}>
            <Typography variant="h4" color={theme.palette.primary.contrastText}>
              Modify Printers
            </Typography>
          </Stack>
        </Stack>
      </DarkUpperSection>
      <LoadingSpinner isLoading={isLoading}>
        <PageSection>
          <Stack spacing={4} sx={{ padding: '2em 0' }}>
            <Stack>
              <TextField
                label="Filter by Name"
                value={filterText}
                onChange={(e) => setFilterText(e.target.value)}
                variant="outlined"
                sx={{ flexGrow: 1 }}
              />
            </Stack>
            <Stack sx={{ flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}>
              <FormControlLabel
                control={<Switch checked={showDisabled} onChange={(e) => setShowDisabled(e.target.checked)} />}
                label="Show Disabled Printers"
              />
              <Stack direction="row" spacing={2}>
                <StyledButton variant="contained" onClick={() => setOpenCreatePrinter(true)}>
                  Create New Printer
                </StyledButton>
              </Stack>
            </Stack>
          </Stack>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <b>Name</b>
                </TableCell>
                <TableCell>
                  <b>IP Address</b>
                </TableCell>
                <TableCell>
                  <b>Port</b>
                </TableCell>
                <TableCell>
                  <b>Actions</b>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {paginatedPrinters.map((printer) => (
                <TableRow key={printer.id}>
                  <TableCell>{printer.name}</TableCell>
                  <TableCell>{printer.printerIP}</TableCell>
                  <TableCell>{printer.printerPort}</TableCell>
                  <TableCell>
                    <Button onClick={() => handleEditClick(printer)}>Edit</Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Stack spacing={2} direction="row" justifyContent="center" sx={{ paddingTop: '1em', paddingBottom: '1em' }}>
            <Pagination count={totalNumberOfPages} page={currentPage} onChange={handlePageChange} color="primary" />
          </Stack>
        </PageSection>
      </LoadingSpinner>
      <EditPrinterForm
        open={openEditPrinter}
        onClose={() => setOpenEditPrinter(false)}
        printer={selectedPrinter}
        onSubmit={submitEditForm}
      />
      <CreatePrinterForm
        open={openCreatePrinter}
        onClose={() => setOpenCreatePrinter(false)}
        onSubmit={createPrinterSubmit}
      />
    </>
  );
}
