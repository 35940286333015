export const navbarListItemsTextEnum = {
  RECEIPT: 'Receipt POs',
  SSCC: 'Manage SSCCs',
  REPRINT: 'Search & Reprint',
  MANIFEST: 'Receive Manifest',
  SETTINGS: 'Settings',
  LOG_OUT: 'Log Out'
};

export const navbarListItemsIconEnum = {
  RECEIPT: 'ReceiptIcon',
  SSCC: 'InventoryIcon',
  REPRINT: 'PrintIcon',
  MANIFEST: 'LocalShippingIcon',
  SETTINGS: 'ContentPasteIcon',
  LOG_OUT: 'LogoutIcon'
};

export const navbarListItems = [
  {
    text: navbarListItemsTextEnum.RECEIPT,
    icon: navbarListItemsIconEnum.RECEIPT,
    isAdmin: false,
    path: '/receipt-purchase-orders'
  },
  {
    text: navbarListItemsTextEnum.SSCC,
    icon: navbarListItemsIconEnum.SSCC,
    isAdmin: false,
    path: '/manage-ssccs'
  },
  {
    text: navbarListItemsTextEnum.REPRINT,
    icon: navbarListItemsIconEnum.REPRINT,
    isAdmin: false,
    path: '/search-reprint'
  },
  {
    text: navbarListItemsTextEnum.MANIFEST,
    icon: navbarListItemsIconEnum.MANIFEST,
    isAdmin: false,
    path: '/receive-manifest'
  },
  {
    text: navbarListItemsTextEnum.SETTINGS,
    icon: navbarListItemsIconEnum.SETTINGS,
    isAdmin: false,
    path: '/settings'
  },
  {
    text: navbarListItemsTextEnum.LOG_OUT,
    icon: navbarListItemsIconEnum.LOG_OUT,
    isAdmin: false,
    path: '/logout'
  }
];
