import { ReceiptStatuses, ValidReceiptStatuses } from './types';

export enum OrderLineStatus {
  created = 'Created',
  received = 'Received',
  partial = 'Partial'
}

export enum ReceiptPoStatus {
  created = 'created',
  received = 'received',
  available = 'available'
}

export enum ReceiptStatus {
  issued = 'Issued',
  pending_receipt = 'Pending Receipt',
  buyer_hold = 'Buyer Hold',
  cancelled = 'Cancelled',
  closed = 'Closed',
  contract_hold = 'Contract Hold',
  currency_hold = 'Currency Hold',
  draft = 'Draft',
  error = 'Error',
  expensed = 'Expensed',
  payment_authorization_hold = 'Payment Authorization Hold',
  soft_closed = 'Soft Closed',
  supplier_hold = 'Supplier Hold',
  supplier_window_hold = 'Supplier Window Hold',
  created = 'Created',
  received = 'Received',
  soft_closed_for_invoicing = 'Soft Closed for Invoicing',
  soft_closed_for_receiving = 'Soft Closed for Receiving',
  partially_received = 'Partially Received'
}

export enum SsccStatus {
  open = 'Open',
  staged = 'Staged',
  manifested = 'Manifested',
  inTransit = 'In Transit',
  approaching = 'Approaching',
  delivered = 'Delivered',
  cancelled = 'Cancelled'
}

export enum ManifestPackageStatus {
  received = 'Received',
  notReceived = 'Not Received',
  partial = 'Partially Received',
  qm = 'QM Raised',
  direct = 'Direct Receipted',
  qm_pending = 'QM Pending',
  direct_pending = 'Direct Pending'
}

export enum ManifestLegStatus {
  inTransit = 'IN_TRANSIT',
  approaching = 'APPROACHING',
  awaitingDeparture = 'AWAITING_DEPARTURE'
}

export enum FormattedManifestLegStatus {
  arrived = 'Arrived',
  intransit = 'In Transit',
  approaching = 'Approaching'
}

export const receiptStatusRecord: Record<ReceiptStatuses, string> = {
  issued: ReceiptStatus.issued,
  pending_receipt: ReceiptStatus.pending_receipt,
  buyer_hold: ReceiptStatus.buyer_hold,
  cancelled: ReceiptStatus.cancelled,
  closed: ReceiptStatus.closed,
  contract_hold: ReceiptStatus.contract_hold,
  currency_hold: ReceiptStatus.currency_hold,
  draft: ReceiptStatus.draft,
  error: ReceiptStatus.error,
  expensed: ReceiptStatus.expensed,
  payment_authorization_hold: ReceiptStatus.payment_authorization_hold,
  soft_closed: ReceiptStatus.soft_closed,
  supplier_hold: ReceiptStatus.supplier_hold,
  supplier_window_hold: ReceiptStatus.supplier_window_hold,
  created: ReceiptStatus.created,
  received: ReceiptStatus.received,
  soft_closed_for_invoicing: ReceiptStatus.soft_closed_for_invoicing,
  soft_closed_for_receiving: ReceiptStatus.soft_closed_for_receiving,
  partially_received: ReceiptStatus.partially_received
};

export const validReceiptStatusRecord: Record<ValidReceiptStatuses, string> = {
  created: ReceiptStatus.created,
  partially_received: ReceiptStatus.partially_received
};

export function isReceiptStatus(status: string): status is keyof typeof receiptStatusRecord {
  return status in receiptStatusRecord;
}

export function isValidReceiptStatus(status: string): status is keyof typeof validReceiptStatusRecord {
  return status in validReceiptStatusRecord;
}
