import { API, graphqlOperation } from 'aws-amplify';
import { GraphQLResult } from '@aws-amplify/api-graphql';
import { postItem } from '../queries/queries';

export type PostAsns = {
  payload?: string[];
  error: string;
};

type PostAsnsResponse = {
  postItem: string;
};

export async function postManifestAsns(body: string): Promise<PostAsns> {
  const genericResponseError = {
    payload: undefined,
    error: 'An error occurred while updating manifest Asns.'
  };
  try {
    const result = (await API.graphql(graphqlOperation(postItem, { body }))) as GraphQLResult<PostAsnsResponse>;

    if (result.data && result.data.postItem) {
      const poResult = JSON.parse(result.data.postItem);
      if (poResult.statusCode === 200) {
        return { payload: poResult.body, error: '' };
      }
    }
    return genericResponseError;
  } catch (e) {
    console.error('Error updating Manifest Asns: ', e);
    return genericResponseError;
  }
}
