import { useState, useEffect } from 'react';
import { DataStore } from 'aws-amplify';
import { SSCC } from '../models';
import { SSCCProps, convertSsccToProps } from '../datastore/ssccs';
import { SsccStatus } from '../common';
import dayjs from 'dayjs';

export default function useObserveSsccs(dateLimit: number | null) {
  const [ssccs, setSsccs] = useState<SSCCProps[]>([]);

  useEffect(() => {
    const retrieveData = async () => {
      const date = dateLimit && dateLimit !== 0 ? dayjs().subtract(dateLimit, 'days') : null;
      const today = dayjs().add(1, 'day').toISOString();
      const endDateString = date?.toISOString() ?? '';
      const entities: SSCC[] = await DataStore.query(SSCC, (c) => c.and((c) => [c.open.between(endDateString, today)]));
      console.debug('useObserveSsccs:', entities.length, 'ssccs fetched');
      const validSsccs = entities
        .map((sscc) => convertSsccToProps(sscc))
        .filter((sscc): sscc is SSCCProps => sscc !== undefined);

      setSsccs(validSsccs.filter((sscc) => sscc.status !== SsccStatus.cancelled));
    };
    const subscription = DataStore.observe(SSCC).subscribe(retrieveData);
    retrieveData();
    return () => {
      subscription.unsubscribe();
    };
  }, [dateLimit]);

  return ssccs;
}
