import { Dialog, IconButton, Stack, Typography, TextField } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { SubmitHandler, useForm, Controller } from 'react-hook-form';
import { SSCCProps } from '../../../datastore/ssccs';
import { StyledButton } from '../../../components';
import { useEffect } from 'react';

interface EditSsccFormProps {
  open: boolean;
  sscc: SSCCProps | null;
  onSubmit: (data: SSCCProps) => void;
  onClose: () => void;
}

export default function EditSsccForm({ open, sscc, onSubmit, onClose }: EditSsccFormProps) {
  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors }
  } = useForm<SSCCProps>();

  useEffect(() => {
    if (sscc) {
      let ssccKey: keyof SSCCProps;
      for (ssccKey in sscc) {
        setValue(ssccKey, sscc[ssccKey]);
      }
    }
  }, [sscc, setValue]);

  const onSubmitForm: SubmitHandler<SSCCProps> = (data) => onSubmit(data);

  return (
    <Dialog open={open} maxWidth="xs" fullWidth>
      <form onSubmit={handleSubmit(onSubmitForm)}>
        <Stack spacing={3} sx={{ padding: '1em 2em' }}>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Typography variant="h4" fontWeight="bold">
              Edit SSCC
            </Typography>
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Stack>
          <Controller
            name="nickname"
            control={control}
            render={({ field }) => <TextField label="Nickname" fullWidth {...field} error={!!errors.nickname} />}
          />
          <Controller
            name="stagingLocation"
            control={control}
            render={({ field }) => (
              <TextField label="Staging Location" fullWidth {...field} error={!!errors.stagingLocation} />
            )}
          />
          <StyledButton variant="contained" type="submit">
            Save Changes
          </StyledButton>
        </Stack>
      </form>
    </Dialog>
  );
}
