/* eslint-disable react-hooks/exhaustive-deps */

import { Dialog, Divider, IconButton, Stack, Table, TableCell, Tooltip, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import StyledButton from '../buttons/StyledButton';
import SearchIcon from '@mui/icons-material/Search';
import { useCallback, useEffect, useState } from 'react';
import StyledTextField from '../textField/StyledTextField';
import LightInfoButton from '../buttons/LightInfoButton';
import { SSCCProps, SsccType } from '../../datastore/ssccs';
import StyledTableRow from '../tables/StyledTableRow';
import dayjs from 'dayjs';
import NewSsccForm, { NewSsccModel } from './NewSSCCForm';
import { LocationProps, UnknownLocation, LocationType } from '../../datastore/locations';
import { useAlert, useCurrentUser, useDatastore, useIsOnline } from '../../hooks';
import { buildSSCC, buildReceiptEvent, SsccStatus } from '../../common';
import { sumQuantity } from '../../common/helperFunctions/sscc';

interface ReassignToSSCCFormProps {
  open: boolean;
  onSubmit: (ssccChoice: string) => void;
  onClose: () => void;
  onBack: () => void;
  isLargeItem: boolean;
  locationName: string;
  originSsccId: string;
}

export default function ReassignToSSCCForm({
  open,
  onSubmit,
  onClose,
  onBack,
  isLargeItem,
  locationName,
  originSsccId
}: ReassignToSSCCFormProps) {
  const [search, setSearch] = useState('');
  const [searchResults, setSearchResults] = useState<SSCCProps[]>([]);
  const [ssccChoice, setSSCCChoice] = useState<string | null>();
  const [locations, setLocations] = useState<LocationProps[]>([]);
  const [openNewModal, setOpenNewModal] = useState(false);
  const [userLocation, setUserLocation] = useState<LocationProps>();
  const [newSSCCId, setNewSSCCId] = useState<string | null>(null);
  const [timer, setTimer] = useState<NodeJS.Timeout>();
  const [hasCreatedSSCC, setHasCreatedSSCC] = useState(false);
  const [originLocation, setOriginLocation] = useState<LocationProps>();
  const { isOnline } = useIsOnline();
  const store = useDatastore();
  const theme = useTheme();
  const alert = useAlert();
  const user = useCurrentUser();

  const getData = useCallback(async () => {
    try {
      const locationResults = await store.locations.getList();
      const originLocation = locationResults?.find((l) => l.name === locationName);
      setOriginLocation(originLocation);
      const isMainLocation =
        originLocation?.type === LocationType.mainLocation && originLocation.name !== UnknownLocation.Name;
      const isSubLocation = originLocation?.type === LocationType.subLocation;
      const ssccResults = await store.ssccs.getList(search);
      const userDetails = await store.userDetails.get(user.cognitoUser.username);
      if (ssccResults && locationResults) {
        const allLocations = locationResults?.filter((l) => !l.isDisabled);
        setLocations(allLocations);

        const filterSSCCs = (sscc: SSCCProps) =>
          !sscc.lock &&
          sscc.id !== originSsccId &&
          userDetails.location?.id === sscc.originLocationId &&
          !(sscc.staged || sscc.manifested || sscc.inTransit || sscc.approaching || sscc.delivered);

        let filteredSSCCs = [];
        if (!search) {
          if (isMainLocation) {
            const hasChildren = allLocations.some((l) => l.parentLocationId === originLocation.id);

            if (hasChildren) {
              // For parent locations, only show SSCCs destined for the parent
              filteredSSCCs = ssccResults.filter(
                (sscc) => sscc.destinationLocationId === originLocation.id && filterSSCCs(sscc)
              );
            } else {
              // For parent locations without children, or if it's a child location
              const relevantLocationIds = allLocations
                .filter((l) => l.parentLocationId === originLocation.id || l.id === originLocation.id)
                .map((l) => l.id);

              filteredSSCCs = ssccResults.filter(
                (sscc) => relevantLocationIds.includes(sscc.destinationLocationId) && filterSSCCs(sscc)
              );
            }
          } else if (isSubLocation) {
            // For sub-locations, include SSCCs destined for this sub-location or its parent
            filteredSSCCs = ssccResults.filter(
              (sscc) =>
                (originLocation?.id === sscc.destinationLocationId ||
                  originLocation?.parentLocationId === sscc.destinationLocationId) &&
                filterSSCCs(sscc)
            );
          } else {
            // Unknown location so no filtering is required.
            filteredSSCCs = ssccResults.filter(filterSSCCs);
          }
          setSearchResults(filteredSSCCs);
        } else {
          setSearchResults(ssccResults.filter(filterSSCCs));
        }
      }

      if (userDetails.location) {
        setUserLocation(userDetails?.location);
      }
    } catch (error) {
      console.error('Error retrieving ReassignToSSCC Data: ', error);
    }
  }, [store, search, user.cognitoUser.username, locationName]);

  useEffect(() => {
    getData();
  }, [getData]);

  useEffect(() => {
    clearTimeout(timer);
    const timeoutId = setTimeout(() => getData(), 500);
    setTimer(timeoutId);

    return () => {
      clearTimeout(timer);
    };
  }, [search, newSSCCId]);

  function onSearchChange(value: string) {
    setSearch(value);
  }

  function onSubmitForm() {
    if (!ssccChoice) {
      alert('Please select an SSCC', 'warning');
      return;
    }
    onSubmit(ssccChoice);
  }

  async function onCreateNew(data: NewSsccModel) {
    if (!userLocation) {
      alert('You must set a location in `settings` before you can create an SSCC', 'warning');
      return;
    }
    const newSSCC = buildSSCC({
      locationAlias: userLocation.alias,
      destinationLocationId: data.site,
      originLocationId: userLocation.id,
      isLargeItem: isLargeItem,
      isTransportBooking: false,
      transportBookingDestination: null,
      height: data.height,
      width: data.width,
      depth: data.depth,
      weight: undefined,
      ssccStatus: SsccStatus.open,
      ssccType: SsccType.standard,
      stagingLocation: undefined,
      nickname: data.nickname
    });
    const foundLocation = locations.find((location) => location.id === data.site);
    const newSite = buildReceiptEvent({
      name: foundLocation?.name || '',
      site: foundLocation?.alias || ''
    });

    if (!newSSCC) {
      alert('An error has occured whilst creating the SSCC, try again.', 'warning');
      return;
    }

    newSSCC.receiptedEvents.push(newSite);

    try {
      await store.ssccs.create(newSSCC);
      alert('Created SSCC', 'success');
    } catch (error) {
      alert('An error occured when creating the SSCC', 'error');
      console.error(error);
    }
    setNewSSCCId(newSSCC.id);
    setSSCCChoice(newSSCC.id);
    setSearch('');
    setOpenNewModal(false);
  }

  const orderedResults = searchResults.sort((a, b) => {
    const dateA = dayjs(a.open).unix();
    const dateB = dayjs(b.open).unix();
    return dateA > dateB ? -1 : 1;
  });

  return (
    <>
      <Dialog open={open} maxWidth="xs" fullWidth>
        <Stack spacing={3} sx={{ padding: '1em 2em' }}>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <IconButton onClick={onBack}>
              <ArrowBackIcon />
            </IconButton>
            <Typography variant="h4" fontWeight="bold">
              Reassign to SSCC
            </Typography>
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Stack>
          <StyledTextField
            value={search}
            onChange={onSearchChange}
            startIcon={<SearchIcon />}
            placeholder="Search by SSCC or Site"
          />
          <LightInfoButton
            variant="contained"
            disabled={hasCreatedSSCC}
            onClick={() => {
              setHasCreatedSSCC(true);
              setOpenNewModal(true);
            }}>
            Create a New SSCC
          </LightInfoButton>
          <Divider />
          {search.length > 0 ? (
            <>
              <Typography>
                <b>Search Results:</b>
              </Typography>
              <Stack spacing={1} direction="row" justifyContent="space-between">
                <Table>
                  {searchResults.length > 0 ? (
                    <>
                      {searchResults.slice(0, 5).map((sscc) => {
                        return (
                          <StyledTableRow
                            key={sscc.id}
                            onClick={() => setSSCCChoice(sscc.id)}
                            sx={ssccChoice === sscc.id ? { backgroundColor: theme.palette.primary.light } : {}}>
                            <TableCell>
                              <b>{sscc.id}</b>
                            </TableCell>
                            <TableCell>
                              <Stack>
                                <Typography>{sscc.nickname}</Typography>
                                <Typography variant="body2">
                                  {locations.find((location) => location.id === sscc.destinationLocationId)?.alias}
                                </Typography>
                              </Stack>
                            </TableCell>
                            <TableCell>
                              {sscc?.receiptedEvents?.reduce((acc, site) => {
                                const siteTotal = site?.packages?.reduce(sumQuantity, 0) || 0;
                                return acc + siteTotal;
                              }, 0)}{' '}
                              items
                            </TableCell>
                          </StyledTableRow>
                        );
                      })}
                    </>
                  ) : (
                    <Typography variant="body2">No Search Results</Typography>
                  )}
                </Table>
              </Stack>
            </>
          ) : (
            <>
              <Typography variant="body2">Recently Used:</Typography>
              <Stack spacing={1} direction="row" justifyContent="space-between">
                <Table>
                  <tbody>
                    {orderedResults.slice(0, 5).map((sscc) => {
                      return (
                        <StyledTableRow
                          key={sscc.id}
                          onClick={() => setSSCCChoice(sscc.id)}
                          sx={ssccChoice === sscc.id ? { backgroundColor: theme.palette.primary.light } : {}}>
                          <TableCell>
                            <b>{sscc.id}</b>
                          </TableCell>
                          <TableCell>
                            <Stack>
                              <Typography>{sscc.nickname}</Typography>
                              <Typography variant="body2">
                                {locations.find((location) => location.id === sscc.destinationLocationId)?.alias}
                              </Typography>
                            </Stack>
                          </TableCell>
                          <TableCell>
                            {sscc?.receiptedEvents?.reduce((acc, site) => {
                              const siteTotal = site?.packages?.reduce(sumQuantity, 0) || 0;
                              return acc + siteTotal;
                            }, 0)}{' '}
                            items
                          </TableCell>
                        </StyledTableRow>
                      );
                    })}
                  </tbody>
                </Table>
              </Stack>
            </>
          )}

          {isOnline ? (
            <StyledButton variant="contained" onClick={onSubmitForm}>
              Complete + Print
            </StyledButton>
          ) : (
            <Tooltip title="You are offline. This feature is not available when offline." enterTouchDelay={0}>
              <StyledButton variant="contained">Complete + Print</StyledButton>
            </Tooltip>
          )}
        </Stack>
      </Dialog>
      {openNewModal && (
        <NewSsccForm
          open={openNewModal}
          onClose={() => setOpenNewModal(false)}
          onSubmit={onCreateNew}
          sites={locations
            .map((location) => ({ name: location.name, value: location.id, type: location.type }))
            .filter((location) => location.name !== UnknownLocation.Name)}
          prePopulateSite
          originLocation={originLocation}
        />
      )}
    </>
  );
}
