export function getProcessAsLargeItemStorage(username: string) {
  const storageKey = `largeItem_${username}`;
  const savedProcessAsLargeItem = localStorage.getItem(storageKey) === 'true';
  return savedProcessAsLargeItem;
}

export function setProcessAsLargeItemStorage(value: boolean, username: string) {
  const storageKey = `largeItem_${username}`;
  const booleanToStringValue = value ? 'true' : 'false';
  localStorage.setItem(storageKey, booleanToStringValue);
}
