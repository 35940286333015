/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  FormControlLabel,
  IconButton,
  Pagination,
  Stack,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { DarkUpperSection, LoadingSpinner, PageSection, StyledButton } from '../../../components';
import { useEffect, useState } from 'react';
import { LocationProps, UnknownLocation, LocationType } from '../../../datastore/locations';
import { useAlert, useCurrentUser, useDatastore, useIsOnline } from '../../../hooks';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import AddLocationForm from './modals/AddLocationModal';
import EditLocationForm from './modals/EditLocationModal';
import OfflineCard from '../../../components/cards/OfflineCard';
import { adminActionType } from '../../../datastore/adminActionHistory';
import dayjs from 'dayjs';

export default function ManageLocations() {
  const theme = useTheme();
  const navigate = useNavigate();
  const store = useDatastore();
  const alert = useAlert();
  const user = useCurrentUser();
  const { isOnline } = useIsOnline();
  const [locations, setLocations] = useState<LocationProps[]>([]);
  const [selectedLocation, setSelectedLocation] = useState<LocationProps | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [filterText, setFilterText] = useState('');
  const [showDisabled, setShowDisabled] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [openAddLocation, setOpenAddLocation] = useState(false);

  useEffect(() => {
    async function fetchLocations() {
      setIsLoading(true);
      try {
        const results = await store.locations.getList();
        const filterUnknown = results?.filter((location) => location.name !== UnknownLocation.Name);
        if (!filterUnknown) {
          alert('No locations found', 'warning');
          return;
        }
        setLocations(filterUnknown);
      } catch (error) {
        console.error('Error fetching locations:', error);
        alert('Error retrieving locations', 'error');
      }
      setIsLoading(false);
    }

    fetchLocations();
  }, [store.locations]);

  const filteredLocations = locations.filter(
    (location) =>
      location.name?.toLowerCase().includes(filterText?.toLowerCase()) &&
      (showDisabled || !location.isDisabled) &&
      location.type === LocationType.mainLocation
  );

  const totalNumberOfPages = Math.ceil(filteredLocations.length / 25);
  const paginatedLocations = filteredLocations.slice((currentPage - 1) * 25, currentPage * 25);

  function handlePageChange(event: React.ChangeEvent<unknown>, value: number) {
    setCurrentPage(value);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  function handleEditClick(location: LocationProps, event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    event.stopPropagation();
    setSelectedLocation(location);
    setOpenEditModal(true);
  }

  async function submitEditForm(data: LocationProps) {
    try {
      if (data.name === UnknownLocation.Name) {
        alert('Cannot set a locations name to "Unknown"', 'warning');
        return;
      }
      data.deliveryDays = Number(data.deliveryDays);
      setLocations(locations.map((loc) => (loc.id === data.id ? data : loc)));

      const updatedLocation = await store.locations.update(data);
      const originalLocation = locations.find((loc) => loc.id === data.id);

      await store.adminActionHistories.create({
        id: crypto.randomUUID(),
        actionType: adminActionType.LOCATION_UPDATE,
        beforeAction: originalLocation ? JSON.stringify(originalLocation) : 'Error writing before action',
        afterAction: updatedLocation ? JSON.stringify(updatedLocation) : 'Error writing after action',
        actionBy: user.email,
        actionTime: dayjs()
      });

      alert('Updated Location', 'success');
    } catch (error) {
      console.log('Error updating location:', error);
      alert('Error updating location', 'error');
    } finally {
      setOpenEditModal(false);
      setSelectedLocation(null);
    }
  }

  async function handleAddLocationSubmit(data: LocationProps) {
    try {
      if (data.name === UnknownLocation.Name) {
        alert('Cannot create location with name "Unknown"', 'warning');
        return;
      }
      setLocations([...locations, data]);
      data.id = crypto.randomUUID();
      data.deliveryDays = Number(data.deliveryDays);
      data.type = LocationType.mainLocation;

      const createdLocation = await store.locations.create(data);
      await store.adminActionHistories.create({
        id: crypto.randomUUID(),
        actionType: adminActionType.LOCATION_CREATE,
        beforeAction: null,
        afterAction: createdLocation ? JSON.stringify(createdLocation) : 'Error writing create action',
        actionBy: user.email,
        actionTime: dayjs()
      });

      alert('Created Location', 'success');
    } catch (error) {
      console.log('Error creating location:', error);
      alert('Error creating location', 'error');
    } finally {
      setOpenAddLocation(false);
    }
  }

  if (!user.isAdmin) return null;

  return (
    <>
      <DarkUpperSection>
        <Stack spacing={4} sx={{ padding: '1em 0' }}>
          <Stack direction="row" alignItems="center">
            <IconButton onClick={() => navigate('/settings')}>
              <ArrowBackIcon fontSize="large" sx={{ color: theme.palette.primary.contrastText }} />
            </IconButton>
            <Typography variant="h2" fontWeight="bold" color={theme.palette.primary.contrastText}>
              Settings
            </Typography>
          </Stack>
          <Stack spacing={2}>
            <Typography variant="h4" color={theme.palette.primary.contrastText}>
              Modify Main Locations
            </Typography>
          </Stack>
        </Stack>
      </DarkUpperSection>
      {isOnline ? (
        <LoadingSpinner isLoading={isLoading}>
          <PageSection>
            <Stack spacing={4} sx={{ padding: '2em 0' }}>
              <TextField
                label="Filter by Name"
                value={filterText}
                onChange={(e) => setFilterText(e.target.value)}
                variant="outlined"
                sx={{ flexGrow: 1 }}
              />
              <Stack direction="row" spacing={2} justifyContent="space-between" sx={{ width: '100%' }}>
                <FormControlLabel
                  control={<Switch checked={showDisabled} onChange={(e) => setShowDisabled(e.target.checked)} />}
                  label="Show Disabled Sites"
                />
                <StyledButton variant="contained" onClick={() => setOpenAddLocation(true)}>
                  Add Location
                </StyledButton>
              </Stack>
            </Stack>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <b>Name</b>
                  </TableCell>
                  <TableCell>
                    <b>Alias</b>
                  </TableCell>
                  <TableCell>
                    <b>Address</b>
                  </TableCell>
                  <TableCell>
                    <b>Suburb (Postcode)</b>
                  </TableCell>
                  <TableCell>
                    <b>Contact Name</b>
                  </TableCell>
                  <TableCell>
                    <b>Contact Phone</b>
                  </TableCell>
                  <TableCell>
                    <b>Delivery Days</b>
                  </TableCell>
                  <TableCell>
                    <b>Auto Receipt</b>
                  </TableCell>
                  <TableCell>
                    <b>Actions</b>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {paginatedLocations.map((location) => (
                  <TableRow
                    key={location.id}
                    sx={{
                      '&:hover': {
                        backgroundColor: 'rgba(0, 0, 0, 0.04)',
                        cursor: 'pointer'
                      }
                    }}
                    onClick={() => {
                      navigate(`/settings/location/${location.id}`, {
                        state: {
                          name: location.name,
                          alias: location.alias,
                          address: `${location.address} ${location.suburb} (${location.postcode})`
                        }
                      });
                    }}>
                    <TableCell>{location.name}</TableCell>
                    <TableCell>{location.alias}</TableCell>
                    <TableCell>{location.address}</TableCell>
                    <TableCell>
                      {location.suburb} ({location.postcode})
                    </TableCell>
                    <TableCell>{location.contactName}</TableCell>
                    <TableCell>{location.contactPhone}</TableCell>
                    <TableCell>{location.deliveryDays}</TableCell>
                    <TableCell>{location.isAutoReceipt ? 'On' : 'Off'}</TableCell>
                    <TableCell>
                      <Button onClick={(event) => handleEditClick(location, event)}>Edit</Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <Stack spacing={2} direction="row" justifyContent="center" sx={{ paddingTop: '1em', paddingBottom: '1em' }}>
              <Pagination count={totalNumberOfPages} page={currentPage} onChange={handlePageChange} color="primary" />
            </Stack>
          </PageSection>
        </LoadingSpinner>
      ) : (
        <PageSection>
          <Stack justifyContent="center">
            <OfflineCard />
          </Stack>
        </PageSection>
      )}
      <EditLocationForm
        open={openEditModal}
        editLocationType={LocationType.mainLocation}
        onClose={() => setOpenEditModal(false)}
        location={selectedLocation}
        onSubmit={submitEditForm}
      />
      <AddLocationForm
        open={openAddLocation}
        addLocationType={LocationType.mainLocation}
        onClose={() => setOpenAddLocation(false)}
        onSubmit={handleAddLocationSubmit}
      />
    </>
  );
}
