import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
  Divider
} from '@mui/material';
import StyledButton from '../buttons/StyledButton';

type ConfirmationDialogProps = {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  title: string;
  content: string;
};

export function ConfirmationDialog({ open, onClose, onConfirm, title, content }: ConfirmationDialogProps) {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>
        <Typography variant="h4" fontWeight="bold" sx={{ paddingBottom: '0.5em' }}>
          {title}
        </Typography>
        <Divider />
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Typography variant="body2">{content}</Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <StyledButton variant="outlined" onClick={onClose}>
          Close
        </StyledButton>
        <StyledButton variant="contained" onClick={onConfirm}>
          Confirm
        </StyledButton>
      </DialogActions>
    </Dialog>
  );
}
