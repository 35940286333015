import { Dialog, IconButton, Stack, Typography, TextField, FormControlLabel, Switch } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import StyledButton from '../../../../components/buttons/StyledButton';
import { SubmitHandler, useForm, Controller } from 'react-hook-form';
import { LocationProps, LocationType } from '../../../../datastore/locations';
import { useEffect } from 'react';

interface LocationAddFormProps {
  open: boolean;
  onSubmit: (data: LocationProps) => void;
  onClose: () => void;
  addLocationType: string;
}

export default function AddLocationForm({ open, onSubmit, onClose, addLocationType }: LocationAddFormProps) {
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors }
  } = useForm<LocationProps>();

  useEffect(() => {
    reset();
  }, [open, reset]);

  const onSubmitForm: SubmitHandler<LocationProps> = (data) => onSubmit(data);

  return (
    <Dialog open={open} maxWidth="xs" fullWidth>
      <form onSubmit={handleSubmit(onSubmitForm)}>
        <Stack spacing={3} sx={{ padding: '1em 2em' }}>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Typography variant="h4" fontWeight="bold">
              {addLocationType === LocationType.mainLocation ? 'Add Location' : 'Add Sub Location'}
            </Typography>
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Stack>
          <Controller
            name="name"
            control={control}
            render={({ field }) => (
              <TextField label="Name" fullWidth {...field} error={!!errors.name} required={true} />
            )}
          />
          <Controller
            name="alias"
            control={control}
            render={({ field }) => (
              <TextField label="Alias" fullWidth {...field} error={!!errors.alias} required={true} />
            )}
          />
          {addLocationType === LocationType.mainLocation && (
            <>
              <Controller
                name="address"
                control={control}
                render={({ field }) => (
                  <TextField label="Address" fullWidth {...field} error={!!errors.address} required={true} />
                )}
              />
              <Controller
                name="suburb"
                control={control}
                render={({ field }) => (
                  <TextField label="Suburb" fullWidth {...field} error={!!errors.suburb} required={true} />
                )}
              />
              <Controller
                name="state"
                control={control}
                rules={{ pattern: /^[A-Za-z ]+$/ }}
                render={({ field }) => (
                  <TextField
                    label="State"
                    fullWidth
                    {...field}
                    error={!!errors.state}
                    helperText={errors.state ? 'State cannot contain numbers' : ''}
                    required
                  />
                )}
              />
              <Controller
                name="postcode"
                control={control}
                rules={{ pattern: /^[0-9]+$/ }}
                render={({ field }) => (
                  <TextField
                    label="Postcode"
                    fullWidth
                    {...field}
                    error={!!errors.postcode}
                    helperText={errors.postcode ? 'Postcode must be numeric' : ''}
                    required
                  />
                )}
              />
              <Controller
                name="contactName"
                control={control}
                rules={{ pattern: /^[A-Za-z ]+$/ }}
                render={({ field }) => (
                  <TextField
                    label="Contact Name"
                    fullWidth
                    {...field}
                    error={!!errors.contactName}
                    helperText={errors.contactName ? 'Contact name cannot contain numbers' : ''}
                    required
                  />
                )}
              />
              <Controller
                name="contactPhone"
                control={control}
                render={({ field }) => (
                  <TextField label="Contact Phone" fullWidth {...field} error={!!errors.contactPhone} required={true} />
                )}
              />
              <Controller
                name="deliveryDays"
                control={control}
                rules={{ pattern: /^[0-9]+$/ }}
                render={({ field }) => (
                  <TextField
                    label="Deliver Days"
                    fullWidth
                    {...field}
                    error={!!errors.deliveryDays}
                    helperText={errors.deliveryDays ? 'Delivery Days must be numeric' : ''}
                    required
                  />
                )}
              />
              <Controller
                name="isAutoReceipt"
                control={control}
                render={({ field }) => (
                  <FormControlLabel control={<Switch checked={field.value} {...field} />} label="Auto Receipt" />
                )}
              />
            </>
          )}
          <Controller
            name="isDisabled"
            control={control}
            render={({ field }) => (
              <FormControlLabel control={<Switch checked={field.value} {...field} />} label="Disabled" />
            )}
          />

          <StyledButton variant="contained" type="submit">
            Save Changes
          </StyledButton>
        </Stack>
      </form>
    </Dialog>
  );
}
