import { Button, IconButton, Stack, SxProps } from '@mui/material';
import { StyledTextField } from '..';
import CloseIcon from '@mui/icons-material/Close';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useEffect, useState } from 'react';

interface SearchFieldProps {
  sx?: SxProps;
  placeholder?: string;
  value?: string;
  startIcon?: JSX.Element;
  includeEndIcon?: boolean;
  onTrigger: (value: string) => void;
}

export default function SearchField({
  placeholder,
  value,
  onTrigger,
  startIcon,
  includeEndIcon = false,
  sx
}: SearchFieldProps) {
  const [search, setSearch] = useState('');

  useEffect(() => {
    if (value === undefined || value === null) return;
    setSearch(value);
  }, [value]);

  function onKeyPress(key: string) {
    if (key === 'Enter') {
      onTrigger(search);
    }
  }

  return (
    <Stack direction="row">
      <StyledTextField
        placeholder={placeholder}
        startIcon={startIcon}
        endIcon={
          includeEndIcon ? (
            <IconButton
              onClick={() => {
                setSearch('');
                onTrigger('');
              }}
              sx={{ fontSize: '1em' }}>
              <CloseIcon />
            </IconButton>
          ) : (
            <></>
          )
        }
        value={search}
        onChange={setSearch}
        onKeyPress={onKeyPress}
        sx={{ borderRadius: '4px 0 0 4px', ...sx }}
      />
      <Button
        variant="contained"
        onClick={() => onTrigger(search)}
        sx={{
          fontSize: '1em',
          borderRadius: '0 2em 2em 0',
          width: '3em',
          backgroundColor: '#DBE4F7'
        }}>
        <ArrowForwardIcon color="primary" />
      </Button>
    </Stack>
  );
}
