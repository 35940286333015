import { Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { SSCCProps } from '../../../datastore/ssccs';
import { dateFormat, timeFormat } from '../../../common';

interface CurrentStatusDateProps {
  sscc: SSCCProps;
  makeStatusBold: boolean;
}

export function CurrentStatusDate({ sscc, makeStatusBold = false }: CurrentStatusDateProps) {
  const theme = useTheme();

  const currentStatusDate = sscc.delivered
    ? sscc.delivered.format(dateFormat)
    : sscc.approaching
      ? sscc.approaching.format(dateFormat)
      : sscc.inTransit
        ? sscc.inTransit.format(dateFormat)
        : sscc.manifested
          ? sscc.manifested.format(dateFormat)
          : sscc.staged
            ? sscc.staged.format(dateFormat)
            : sscc.open
              ? sscc.open.format(dateFormat)
              : '';
  const currentStatusTime = sscc.delivered
    ? sscc.delivered.format(timeFormat)
    : sscc.approaching
      ? sscc.approaching.format(timeFormat)
      : sscc.inTransit
        ? sscc.inTransit.format(timeFormat)
        : sscc.manifested
          ? sscc.manifested.format(timeFormat)
          : sscc.staged
            ? sscc.staged.format(timeFormat)
            : sscc.open
              ? sscc.open.format(timeFormat)
              : '';

  if (!currentStatusDate) return null;

  return (
    <Stack direction="column" spacing="0.4em">
      <Typography color={theme.palette.text.primary} fontWeight={makeStatusBold ? 'bold' : ''}>
        {sscc.status}
      </Typography>
      <Typography variant="body2" color={theme.palette.text.secondary}>
        {currentStatusDate}
      </Typography>
      <Typography variant="body2" color={theme.palette.text.secondary}>
        {currentStatusTime}
      </Typography>
    </Stack>
  );
}
