import { MouseEventHandler } from 'react';
import { ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { ReactNode } from 'react';

interface NavbarListItemProps {
  text: string;
  icon: ReactNode;
  handleSelect: MouseEventHandler<HTMLLIElement>;
}

export default function NavbarListItem({ text, icon, handleSelect }: NavbarListItemProps) {
  return (
    <ListItem
      sx={{
        '&:hover': {
          backgroundColor: 'rgba(0, 0, 0, 0.04)',
          cursor: 'pointer'
        },
        m: 0,
        p: 1.5,
        paddingRight: 6
      }}
      onClick={handleSelect}>
      <ListItemIcon>{icon}</ListItemIcon>
      <ListItemText primary={text} />
    </ListItem>
  );
}
