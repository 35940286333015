import dayjs from 'dayjs';
import { SSCCProps, ReceiptEventSiteProps, ReceiptEventPackageProps } from '../../datastore/ssccs';
import { TransportBookingLocationProps } from '../../datastore/transportBookingEvent';
import { SsccStatus } from '../status';

interface BuildSSCCParams {
  locationAlias: string;
  destinationLocationId: string;
  originLocationId: string;
  isLargeItem: boolean;
  height?: string;
  width?: string;
  depth?: string;
  weight?: string;
  stagingLocation?: string;
  nickname: string | false;
  ssccType: string;
  ssccStatus: string;
  isTransportBooking: boolean;
  transportBookingDestination: TransportBookingLocationProps | null;
}

interface BuildPackageParams {
  packageType: string | null;
  po: string | null;
  asn: string | null;
  companyName: string | null;
  jobId: string | null;
  assetNumber: string | null;
  supplierId: string | null;
  supplierName: string | null;
  priority: string | null;
  status: string | null;
  height?: string;
  width?: string;
  depth?: string;
  weight?: string;
  hasDangerousGoods: boolean;
  dateReceived?: string;
  docketNumber?: string;
  packageQuantity: number | null;
  qrCodeId: string;
  shipToAttention: string;
  items: PackageItem[];
}

interface PackageItem {
  orderLineId: number;
  partId: number;
  sourcePartId: string;
  description: string;
  purchaseType: string;
  quantityOrdered: number | null;
  quantityReceived: number | null;
  receivingWarehouseId: number | null;
  receivingWarehouseDefaultLocationId: number | null;
  inTransitWarehouseId: number | null;
  inTransitWarehouseLocationId: number | null;
}

interface BuildReceiptEventParams {
  name: string;
  site: string;
}

export function buildSSCC({
  locationAlias,
  destinationLocationId,
  originLocationId,
  height,
  width,
  depth,
  weight,
  ssccStatus,
  isLargeItem,
  ssccType,
  stagingLocation,
  nickname,
  isTransportBooking,
  transportBookingDestination
}: BuildSSCCParams): SSCCProps | null {
  if (!locationAlias || !originLocationId || !ssccStatus || !ssccType) {
    return null;
  }
  const id = crypto.randomUUID();
  const refNumber = `${dayjs().format('YYMMDDHHmmss')}${id.replace(/[^0-9]/g, '').substring(0, 3)}`;
  const refWord = Array.from({ length: 3 }, () => 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'[Math.floor(Math.random() * 26)]).join(
    ''
  );
  const newSSCC: SSCCProps = {
    id: refNumber,
    nickname: nickname ? nickname : `${locationAlias} - ${refWord}${refNumber.slice(-4)}`,
    destinationLocationId: destinationLocationId,
    originLocationId: originLocationId,
    lock: false,
    status: ssccStatus,
    ssccType: ssccType,
    isTransportBooking: isTransportBooking,
    transportBookingDestination: transportBookingDestination,
    isLargeItem: isLargeItem,
    estimatedDelivery: dayjs().add(4, 'day'),
    open: dayjs(),
    staged: ssccStatus === 'Staged' ? dayjs() : null,
    manifested: null,
    inTransit: null,
    approaching: null,
    delivered: null,
    height: height || '',
    width: width || '',
    depth: depth || '',
    weight: weight || '',
    stagingLocation: stagingLocation || '',
    receiptedEvents: []
  };

  return newSSCC;
}

export function buildReceiptEvent({ name, site }: BuildReceiptEventParams): ReceiptEventSiteProps {
  const newSite: ReceiptEventSiteProps = {
    name: name,
    site: site,

    packages: []
  };

  return newSite;
}

export function buildPackage({
  packageType,
  po,
  asn,
  jobId,
  assetNumber,
  supplierId,
  supplierName,
  priority,
  status,
  height,
  width,
  depth,
  weight,
  hasDangerousGoods,
  packageQuantity,
  dateReceived,
  docketNumber,
  items,
  qrCodeId,
  shipToAttention,
  companyName
}: BuildPackageParams): ReceiptEventPackageProps {
  const pkg: ReceiptEventPackageProps = {
    id: crypto.randomUUID(),
    packageType: packageType,
    po: po,
    asn: asn,
    jobId: jobId,
    assetNumber: assetNumber,
    supplierName: supplierName,
    supplierId: supplierId,
    priority: priority,
    status: status,
    statusDate: dayjs(),
    quantity: Number(packageQuantity),
    height: height || null,
    width: width || null,
    depth: depth || null,
    weight: weight || null,
    hasDangerousGoods: hasDangerousGoods,
    dateReceived: dateReceived || '',
    docketNumber: docketNumber || '',
    qrCodeId: qrCodeId,
    companyName: companyName || '',
    shipToAttention: shipToAttention,
    items: items
      .filter((item) => item.quantityReceived !== 0)
      .map((item) => ({
        orderLineId: item.orderLineId,
        partId: item.partId,
        sourcePartId: item.sourcePartId,
        description: item.description,
        receipt: dayjs(),
        purchaseType: item.purchaseType,
        quantityOrdered: item.quantityOrdered,
        quantityReceived: item.quantityReceived,
        receivingWarehouseId: item.receivingWarehouseId,
        receivingWarehouseDefaultLocationId: item.receivingWarehouseDefaultLocationId,
        inTransitWarehouseId: item.inTransitWarehouseId,
        inTransitWarehouseLocationId: item.inTransitWarehouseLocationId
      }))
  };
  return pkg;
}

export function updateAsnStatus(sscc: SSCCProps, status: SsccStatus | 'Available') {
  for (const event of sscc.receiptedEvents) {
    for (const pkg of event.packages) {
      pkg.status = status;
      pkg.statusDate = dayjs();
    }
  }
}

export function sumQuantity(pkgAcc: number, pkg: ReceiptEventPackageProps) {
  return pkgAcc + (pkg.quantity || 0);
}
