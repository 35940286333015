/* eslint-disable react-hooks/exhaustive-deps */
import { Dialog, Divider, IconButton, Stack, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import CloseIcon from '@mui/icons-material/Close';
import { NumberFieldFormInput, SelectFormInput, TextFieldFormInput } from '../formInputs';
import StyledButton from '../buttons/StyledButton';
import { useEffect, useState } from 'react';
import { useCurrentUser, useDatastore } from '../../hooks';
import { QMIssueProps } from '../../datastore/qmIssue';
import { QMReasonProps } from '../../datastore/qmReason';

interface RaiseQMFormProps {
  open: boolean;
  onSubmit: (data: RaiseQMModel) => void;
  onClose: () => void;
}

export interface RaiseQMModel {
  reason: QMReasonProps;
  issue: QMIssueProps;
  purchaseOrderNumber: string;
  numberOfLabels: number;
  locationName: string;
  progress: string;
  description: string;
  /* attachments: { file: File }[]; //@Phase3: Add attachments */
}

// const attachmentFileTypes = [ //@Phase3: Add attachments
//   'image/*',
//   'application/pdf',
//   'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
//   'application/msword',
//   '.csv',
//   'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
//   'application/vnd.ms-excel'
// ];

export default function RaiseQMForm({ open, onSubmit, onClose }: RaiseQMFormProps) {
  const store = useDatastore();
  const user = useCurrentUser();
  const [qmReasons, setQMReasons] = useState<QMReasonProps[]>([]);
  const [qmIssues, setQMIssues] = useState<QMIssueProps[]>([]);

  const defaultValues: RaiseQMModel = {
    reason: { id: '', reasonId: '', name: '' },
    issue: { id: '', issueId: '', name: '' },
    purchaseOrderNumber: '',
    numberOfLabels: 1,
    locationName: '',
    progress: '',
    description: ''
    /* attachments: [] //@Phase3: Add attachments */
  };

  const {
    handleSubmit,
    control,
    /* setError, //@Phase3: Add attachments
       clearErrors, */
    formState: { errors }
  } = useForm({ defaultValues });

  /* const { fields, append, remove } = useFieldArray({
     name: 'attachments',
     control
   });//@Phase3: Add attachments */

  useEffect(() => {
    async function getData() {
      const reasons = await store.qmReasons.getArray();
      const issues = await store.qmIssues.getArray();
      if (reasons) setQMReasons(reasons);
      if (issues) setQMIssues(issues);
    }
    getData();
  }, []);

  function onSubmitForm(data: RaiseQMModel) {
    data.reason = qmReasons.find((r) => r.reasonId === data.reason.reasonId) || { id: '', reasonId: '', name: '' };
    data.issue = qmIssues.find((i) => i.issueId === data.issue.issueId) || { id: '', issueId: '', name: '' };
    onSubmit(data);
  }

  /* async function onAttachmentUpload(files: FileList | null) { //@Phase3: Add attachments
  //   if (files) {
  //     for (let i = 0; i < files.length; i++) {
  //       const file = files.item(i);
  //       if (file) {
  //         if (file.size > 12582912) {
  //           //arbitrary number, can be changed
  //           setError('attachments', { message: 'File too big! Please make sure it is 12MB or less.' });
  //           break;
  //         } else {
  //           append({ file });
  //           clearErrors();
  //         }
  //       }
  //     }
  //   }
   }*/

  return (
    <Dialog open={open} maxWidth="lg" fullWidth>
      <form onSubmit={handleSubmit(onSubmitForm)}>
        <Stack spacing={3} sx={{ padding: '1em 2em' }}>
          <Stack spacing={1}>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <Typography variant="h4" fontWeight="bold">
                Raise a QM
              </Typography>
              <IconButton onClick={onClose}>
                <CloseIcon />
              </IconButton>
            </Stack>
            <Typography variant="body2">
              MRL Employees should use this form to raise issues with goods received or delivered.
            </Typography>
          </Stack>
          <Divider />
          <Stack direction="row" spacing={4} justifyContent="space-between">
            <Stack spacing={2} justifyContent="space-between" sx={{ width: '33%' }}>
              <Stack spacing={1}>
                <Typography>Reason for QM</Typography>
                <SelectFormInput
                  name="reason.reasonId"
                  required
                  control={control}
                  error={errors['reason']?.reasonId}
                  options={qmReasons.map((type) => ({ name: type.name, value: type.reasonId }))}
                />
              </Stack>
              <Stack spacing={1}>
                <Typography>Issue</Typography>
                <SelectFormInput
                  name="issue.issueId"
                  required
                  control={control}
                  error={errors['issue']?.issueId}
                  options={qmIssues.map((type) => ({ name: type.name, value: type.issueId }))}
                />
              </Stack>
              <Stack spacing={1}>
                <Typography>Purchase Order #</Typography>
                <TextFieldFormInput
                  name="purchaseOrderNumber"
                  control={control}
                  error={errors['purchaseOrderNumber']}
                />
              </Stack>
              <Stack spacing={1}>
                <Typography>Number of labels</Typography>
                <NumberFieldFormInput name="numberOfLabels" control={control} error={errors['numberOfLabels']} />
              </Stack>
            </Stack>
            <Stack spacing={2} justifyContent="space-between" sx={{ width: '33%' }}>
              <Stack spacing={1}>
                <Typography>Item/Consignment Location</Typography>
                <TextFieldFormInput
                  name="locationName"
                  required
                  control={control}
                  error={errors['locationName']}
                  multiline
                  rows={3}
                />
              </Stack>
              <Stack spacing={1}>
                <Typography>Action/Progress</Typography>
                <TextFieldFormInput
                  name="progress"
                  required
                  control={control}
                  error={errors['progress']}
                  multiline
                  rows={3}
                />
              </Stack>
            </Stack>
            <Stack spacing={2} justifyContent="space-between" sx={{ width: '33%' }}>
              <Stack spacing={1}>
                <Typography>Description</Typography>
                <TextFieldFormInput
                  name="description"
                  required
                  control={control}
                  error={errors['description']}
                  multiline
                  rows={10}
                />
              </Stack>
            </Stack>
          </Stack>
          <Divider />
          {/* <Stack direction="row" justifyContent="space-between" spacing={4}> //@Phase3: Add attachments
            <Stack spacing={1} sx={{ width: '33%' }}>
              <>
                {fields.map((field, i) => {
                  return (
                    <Stack key={field.id} direction="row" spacing={2} sx={{ alignItems: 'center' }}>
                      <Button variant="text" onClick={() => remove(i)}>
                        X
                      </Button>
                      <Typography>{field.file.name}</Typography>
                    </Stack>
                  );
                })}
              </>
            </Stack>
            <Stack spacing={1} sx={{ width: '33%' }}>
              <Typography>Attachments</Typography>
              <LightInfoButton variant="contained" component="label">
                Add Attachment
                <input
                  hidden
                  accept={attachmentFileTypes.join(', ')}
                  type="file"
                  multiple
                  onChange={(e) => onAttachmentUpload(e.target.files)}
                />
              </LightInfoButton>
            </Stack>
          </Stack> */}
          <StyledButton variant="contained" type="submit" disabled={user.isReadOnly}>
            Complete
          </StyledButton>
        </Stack>
      </form>
    </Dialog>
  );
}
