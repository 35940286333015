import { Dispatch, SetStateAction, createContext, useContext, useState } from 'react';
import { CircularProgress, Dialog, Stack } from '@mui/material';

interface LoadingProviderProps {
  children?: React.ReactNode;
}

const LoadingContext = createContext<Dispatch<SetStateAction<boolean>>>(() => false);

const useLoading = () => useContext(LoadingContext);

function LoadingProvider({ children }: LoadingProviderProps) {
  const [loading, setLoading] = useState(false);

  return (
    <LoadingContext.Provider value={setLoading}>
      {children}
      <Dialog open={loading} maxWidth="xs">
        <Stack spacing={8} sx={{ padding: '6em 8em' }}>
          <CircularProgress />
        </Stack>
      </Dialog>
    </LoadingContext.Provider>
  );
}

export { useLoading, LoadingProvider };
