import React, { createContext, useState, useContext, ReactNode } from 'react';

type DirtyContextType = {
  isDirty: boolean;
  setIsDirty: (isDirty: boolean) => void;
};

type DirtyProviderProps = {
  children: ReactNode;
};

const DirtyContext = createContext<DirtyContextType | undefined>(undefined);

export const useDirtyContext = () => {
  const context = useContext(DirtyContext);
  if (!context) {
    throw new Error('useDirtyContext must be used within a DirtyProvider');
  }
  return context;
};

export const DirtyProvider: React.FC<DirtyProviderProps> = ({ children }) => {
  const [isDirty, setIsDirty] = useState<boolean>(false);

  return <DirtyContext.Provider value={{ isDirty, setIsDirty }}>{children}</DirtyContext.Provider>;
};
