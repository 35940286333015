import React, { createContext, useContext, useEffect, useState, ReactNode } from 'react';

interface IsOnlineContextType {
  isOnline: boolean;
}

const IsOnlineContext = createContext<IsOnlineContextType | undefined>(undefined);

export const useIsOnline = () => {
  const context = useContext(IsOnlineContext);
  if (context === undefined) {
    throw new Error('useIsOnline must be used within an IsOnlineProvider');
  }
  return context;
};

interface IsOnlineProviderProps {
  children: ReactNode;
}

export const IsOnlineProvider: React.FC<IsOnlineProviderProps> = ({ children }) => {
  const [isOnline, setIsOnline] = useState<boolean>(true);

  useEffect(() => {
    function changeStatus() {
      setIsOnline(navigator.onLine);
    }
    window.addEventListener('online', changeStatus);
    window.addEventListener('offline', changeStatus);
    changeStatus();

    return () => {
      window.removeEventListener('online', changeStatus);
      window.removeEventListener('offline', changeStatus);
    };
  }, []);

  return <IsOnlineContext.Provider value={{ isOnline }}>{children}</IsOnlineContext.Provider>;
};
