import { DataStore } from 'aws-amplify';
import { PackageType } from '../models';

export interface PackageTypeProps {
  id: string;
  name: string;
}

export interface PackageTypeDataProps {
  create: (data: PackageTypeProps) => Promise<PackageTypeProps | undefined>;
  getArray: () => Promise<string[] | undefined>;
}

export const packageTypeData = () => {
  async function create(data: PackageTypeProps): Promise<PackageTypeProps | undefined> {
    const created = await DataStore.save(
      new PackageType({
        id: data.id,
        name: data.name
      })
    );

    if (created) {
      return convertToProps(created);
    }

    return undefined;
  }

  async function getArray(): Promise<string[] | undefined> {
    const results = await DataStore.query(PackageType);

    if (results) {
      return results.map((r) => r.name);
    }
  }

  function convertToProps(data: PackageType) {
    const packageTypeProps = {
      id: data.id,
      name: data.name
    };
    return packageTypeProps;
  }

  const returned: PackageTypeDataProps = {
    create,
    getArray
  };

  return returned;
};
