import { DataStore } from 'aws-amplify';
import { TransportBookingEvent, TransportBookingLocation } from '../models';

export type TransportBookingEventProps = {
  id: string;
  poNumber: number;
  serviceType: string;
} & ExtraTransportBookingFields;

export type ExtraTransportBookingFields = {
  additionalField1?: string;
  additionalField2?: string;
  additionalField3?: string;
  notesContent?: string;
  destinationLocation: TransportBookingLocationProps | null;
  originLocation: TransportBookingLocationProps | null;
};

export interface TransportBookingLocationProps {
  businessName: string;
  addressLine1: string;
  addressLine2: string;
  suburb: string;
  state: string;
  postCode: number;
  contactName: string;
  contactNumber: string;
  emailAddress: string;
}

export const unknownTbfField: string = 'Unknown';
export const unknownTbfAlias: string = 'Unkwn';

export interface TransportBookingEventDataProps {
  getByPo: (po: number) => Promise<TransportBookingEventProps | null>;
}

export const transportBookingEventData = (): TransportBookingEventDataProps => {
  async function getByPo(po: number): Promise<TransportBookingEventProps | null> {
    const transportBookingEvents = await DataStore.query(TransportBookingEvent, (t) => t.poNumber.eq(po));
    const transportBookingEvent = transportBookingEvents[0];
    if (!transportBookingEvent) return null;

    const converted = convertToProps(transportBookingEvent);
    if (!converted) {
      return null;
    }
    return converted;
  }

  function convertToProps(data: TransportBookingEvent): TransportBookingEventProps | null {
    if (!data.id || !data.poNumber || !data.destinationLocation || !data.originLocation || !data.serviceType) {
      return null;
    }

    const transportBookingEventProps: TransportBookingEventProps = {
      id: data.id,
      poNumber: data.poNumber,
      destinationLocation: convertToTransportBookingLocationProps(data.destinationLocation) || null,
      originLocation: convertToTransportBookingLocationProps(data.originLocation) || null,
      serviceType: data.serviceType,
      additionalField1: data.additionalField1 ?? '',
      additionalField2: data.additionalField2 ?? '',
      additionalField3: data.additionalField3 ?? '',
      notesContent: data.notesContent ?? ''
    };

    if (!transportBookingEventProps.destinationLocation || !transportBookingEventProps.originLocation) {
      return null;
    }

    return transportBookingEventProps;
  }

  return { getByPo };
};

export function convertToTransportBookingLocationProps(data: TransportBookingLocation | null) {
  if (!data) return null;
  if (
    !data.businessName ||
    !data.addressLine1 ||
    !data.suburb ||
    !data.state ||
    !data.postCode ||
    !data.contactName ||
    !data.contactNumber
  ) {
    return null;
  }
  const transportBookingLocationProps: TransportBookingLocationProps = {
    businessName: data.businessName,
    addressLine1: data.addressLine1,
    addressLine2: data.addressLine2 || unknownTbfField,
    suburb: data.suburb,
    state: data.state,
    postCode: data.postCode,
    contactName: data.contactName,
    contactNumber: data.contactNumber,
    emailAddress: data.emailAddress || unknownTbfField
  };
  return transportBookingLocationProps;
}

export function transportBookingLocationOneLine(transportBookingLocation: TransportBookingLocationProps | null) {
  if (!transportBookingLocation) return unknownTbfField;
  return `${transportBookingLocation.addressLine1},${transportBookingLocation.addressLine2}, ${transportBookingLocation.suburb}, ${transportBookingLocation.state}, ${transportBookingLocation.postCode}`;
}

export function transportBookingLocationAlias(transportBookingLocation: TransportBookingLocationProps | null) {
  if (!transportBookingLocation) return unknownTbfAlias;
  return `${transportBookingLocation.businessName.slice(0, 4).toUpperCase()}`;
}
