import { useState, useEffect } from 'react';
import { DataStore } from 'aws-amplify';
import { TripSheet } from '../models';
import { convertTripSheetToProps, TripSheetProps } from '../datastore/tripSheets';

export default function useObserveTripsheet(tripsheetId: string) {
  const [tripsheet, setTripsheet] = useState<TripSheetProps>();

  useEffect(() => {
    async function fetchInitialTripsheet() {
      try {
        const initialTripsheet = await DataStore.query(TripSheet, tripsheetId);
        if (!initialTripsheet) return;
        const convertedTripsheet = convertTripSheetToProps(initialTripsheet);
        setTripsheet(convertedTripsheet);
      } catch (error) {
        console.error('Error fetching initial Tripsheet:', error);
      }
    }

    fetchInitialTripsheet();

    const subscription = DataStore.observe(TripSheet, tripsheetId).subscribe({
      next: (update) => {
        console.log('TripSheet update:', update);
        const convertedTripsheet = convertTripSheetToProps(update.element);
        setTripsheet(convertedTripsheet);
      },
      error: (error) => console.error('Error observing TripSheet:', error)
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [tripsheetId]);

  return tripsheet;
}
