import { API, graphqlOperation } from 'aws-amplify';
import { GraphQLResult } from '@aws-amplify/api-graphql';
import { getPO } from '../queries/queries';
import { ReceiptHeader } from '../common/types';

type FetchPoResponse = {
  payload?: ReceiptHeader;
  error: string;
};

type GetPOResponse = {
  getPO: string;
};

export async function fetchPODetails(poid: string, asn: string): Promise<FetchPoResponse> {
  const genericResponseError = {
    payload: undefined,
    error: 'An error occurred while fetching PO details.'
  };

  const search = { poid: poid || null, asn: asn || null };

  try {
    const result = (await API.graphql(graphqlOperation(getPO, search))) as GraphQLResult<GetPOResponse>;
    if (result.data && result.data.getPO) {
      const poResult = JSON.parse(result.data.getPO);
      if (poResult.statusCode === 200) {
        const bodyResult = JSON.parse(poResult.body);
        return { payload: bodyResult, error: '' };
      }
    }
    return genericResponseError;
  } catch (e) {
    console.error('Error fetching PO details: ', e);
    return genericResponseError;
  }
}
