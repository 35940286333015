import { createContext, useContext, useEffect, useState } from 'react';
import { DataStore, Hub, syncExpression } from 'aws-amplify';
import { UserDetailsDataProps, userDetailsData } from '../datastore/userDetails';
import { SSCCsDataProps, ssccsData } from '../datastore/ssccs';
import { LocationDataProps, locationData } from '../datastore/locations';
import { Box, CircularProgress, Dialog, Stack, Typography } from '@mui/material';
import { PrinterDataProps, printersData } from '../datastore/printers';
import { PackageTypeDataProps, packageTypeData } from '../datastore/packageType';
import { QMReasonDataProps, qmReasonData } from '../datastore/qmReason';
import { QMIssueDataProps, qmIssueData } from '../datastore/qmIssue';
import { WorkflowStatusDataProps, workflowStatusData } from '../datastore/workflowStatus';
import { TripSheetsDataProps, tripSheetsData } from '../datastore/tripSheets';
import { RemoveItemSSCCHistoryDataProps, removeItemSSCCHistory } from '../datastore/removeItemSSCCHistory';
import { ReceiptEventPackageHistoryDataProps, receiptEventPackageHistory } from '../datastore/receiptEventHistory';
import { AdminActionHistoryDataProps, adminActionHistory } from '../datastore/adminActionHistory';
import { TransportBookingEventDataProps, transportBookingEventData } from '../datastore/transportBookingEvent';
import { AdminActionHistory, ReceiptEventPackageHistory, RemoveItemSSCCHistory, SSCC, TripSheet } from '../models';
import dayjs from 'dayjs';
import { SsccStatus } from '../common';

export interface DatastoreContextProps {
  isDatastoreReady: boolean;
  userDetails: UserDetailsDataProps;
  ssccs: SSCCsDataProps;
  tripSheets: TripSheetsDataProps;
  locations: LocationDataProps;
  printers: PrinterDataProps;
  packageTypes: PackageTypeDataProps;
  qmReasons: QMReasonDataProps;
  qmIssues: QMIssueDataProps;
  removeItemSSCCHistories: RemoveItemSSCCHistoryDataProps;
  workflowStatuses: WorkflowStatusDataProps;
  receiptEventPackageHistories: ReceiptEventPackageHistoryDataProps;
  adminActionHistories: AdminActionHistoryDataProps;
  transportBookingEvents: TransportBookingEventDataProps;
}

interface DatastoreProviderProps {
  children?: React.ReactNode;
}

const defaultDatastoreConfiguration = {
  maxRecordsToSync: 50000,
  fullSyncInterval: +(process.env.OVERRIDE_FULL_SYNC_MINUTES ?? 60 * 2),
  syncPageSize: 80
};

const DatastoreContext = createContext<DatastoreContextProps>({} as DatastoreContextProps);

const useDatastore = () => useContext(DatastoreContext);

const DatastoreProvider = ({ children }: DatastoreProviderProps) => {
  const [isDatastoreReady, setIsDatastoreReady] = useState(false);
  const [newRecordCounts, setNewRecordCounts] = useState<{
    [key: string]: { new: number; updated: number; deleted: number };
  }>({});

  const userDetails = userDetailsData();
  const ssccs = ssccsData();
  const tripSheets = tripSheetsData();
  const locations = locationData();
  const printers = printersData();
  const packageTypes = packageTypeData();
  const qmReasons = qmReasonData();
  const qmIssues = qmIssueData();
  const transportBookingEvents = transportBookingEventData();
  const workflowStatuses = workflowStatusData();
  const removeItemSSCCHistories = removeItemSSCCHistory();
  const receiptEventPackageHistories = receiptEventPackageHistory();
  const adminActionHistories = adminActionHistory();
  const days = +(process.env.SSCC_MANIFEST_FROM_LAST_X_DAYS ?? 60);
  const timePredicate = dayjs().subtract(days, 'day').toISOString();
  const futureDate = dayjs().add(5, 'year').toISOString();

  useEffect(() => {
    const listener = Hub.listen('datastore', async ({ payload: { event, data } }) => {
      console.info('datastore event', event, data);
      switch (event) {
        case 'ready':
          console.log(event);
          setIsDatastoreReady(true);
          break;
        case 'modelSynced':
          const {
            counts,
            model: { name: modelName }
          } = data;
          if (counts.new > 0 || counts.updated > 0 || counts.deleted > 0) {
            setNewRecordCounts(() => ({
              [modelName]: counts
            }));
          }
          break;
      }
    });

    const syncExpressions = [
      syncExpression(TripSheet, () => {
        return (ts) => ts?.createdAt.gt(timePredicate);
      }),
      syncExpression(SSCC, () => {
        return (sscc) =>
          sscc.or((sscc) => [
            sscc?.createdAt.ge(timePredicate),

            sscc.and((sscc) => [
              sscc?.createdAt.lt(timePredicate),
              sscc?.status.ne(SsccStatus.delivered),
              sscc?.status.ne(SsccStatus.cancelled)
            ])
          ]);
      }),
      // sync expressions compared to a future data are being used to stop syncing data that we don't need, datastore doesn't have a simple method for this.
      syncExpression(AdminActionHistory, () => {
        return (a) => a?.actionTime.gt(futureDate);
      }),
      syncExpression(ReceiptEventPackageHistory, () => {
        return (r) => r?.actionTime.gt(futureDate);
      }),
      syncExpression(RemoveItemSSCCHistory, () => {
        return (r) => r?.actionTime.gt(futureDate);
      })
    ];

    //Datastore documentation: https://docs.amplify.aws/lib/datastore/getting-started/q/platform/js/
    DataStore.configure({ ...defaultDatastoreConfiguration, syncExpressions });

    DataStore.start();
    return () => {
      listener();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const value = {
    isDatastoreReady: isDatastoreReady,
    userDetails,
    ssccs,
    tripSheets,
    locations,
    printers,
    packageTypes,
    qmIssues,
    qmReasons,
    workflowStatuses,
    removeItemSSCCHistories,
    receiptEventPackageHistories,
    adminActionHistories,
    transportBookingEvents
  };

  return isDatastoreReady ? (
    <DatastoreContext.Provider value={value}>{children}</DatastoreContext.Provider>
  ) : (
    <Dialog open={!isDatastoreReady} maxWidth="lg">
      <Stack spacing={8} sx={{ padding: '6em 6em', minWidth: '550px', alignItems: 'center' }}>
        <Typography>Syncing Datastore</Typography>
        <CircularProgress />
        <div>
          {Object.entries(newRecordCounts).map(([tableName, count]) => (
            <Box key={tableName}>
              <Typography style={{ fontSize: '0.6rem' }}>
                {tableName} : {count.new} added, {count.updated} updated
                {count.deleted > 0 ? ', {count.deleted} removed' : ''}
              </Typography>
            </Box>
          ))}
        </div>
      </Stack>
    </Dialog>
  );
};

export { useDatastore, DatastoreProvider };
