import { Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { ReceiptEventPackageProps } from '../../../datastore/ssccs';
import { dateFormat, timeFormat } from '../../../common';

interface PackageStatusDateProps {
  pkg: ReceiptEventPackageProps;
  defaultStatus: string;
}

export function PackageStatusDate({ pkg, defaultStatus }: PackageStatusDateProps) {
  const theme = useTheme();

  const currentStatusDate = pkg.statusDate?.format(dateFormat) || '';
  const currentStatusTime = pkg.statusDate?.format(timeFormat) || '';

  return (
    <Stack direction="column" spacing="0.4em">
      <Typography color={theme.palette.text.primary}>{pkg.status ?? defaultStatus}</Typography>
      <Typography variant="body2" color={theme.palette.text.secondary}>
        {currentStatusDate}
      </Typography>
      <Typography variant="body2" color={theme.palette.text.secondary}>
        {currentStatusTime}
      </Typography>
    </Stack>
  );
}
