import { createBrowserRouter } from 'react-router-dom';
import {
  ManageSsccs,
  ReceiptPurchaseOrders,
  ReceiveManifest,
  ReceiveManifestDetails,
  SSCCDetails,
  SearchReprint,
  Settings
} from './pages';
import { Layout } from './components';
import EditPrinters from './pages/admin/printers';

import ManageLocations from './pages/admin/locations/ManageLocations';
import ManageSubLocations from './pages/admin/locations/ManageSubLocations';

export const router = createBrowserRouter([
  {
    path: '/manage-ssccs',
    element: (
      <Layout>
        <ManageSsccs />
      </Layout>
    )
  },
  {
    path: '/manage-ssccs/:id',
    element: (
      <Layout>
        <SSCCDetails />
      </Layout>
    )
  },
  {
    path: '/receipt-purchase-orders',
    element: (
      <Layout>
        <ReceiptPurchaseOrders />
      </Layout>
    )
  },
  {
    path: '/receipt-purchase-orders/:id',
    element: (
      <Layout>
        <ReceiptPurchaseOrders />
      </Layout>
    )
  },
  {
    path: '/receive-manifest',
    element: (
      <Layout>
        <ReceiveManifest />
      </Layout>
    )
  },
  {
    path: '/receive-manifest/:id',
    element: (
      <Layout>
        <ReceiveManifestDetails />
      </Layout>
    )
  },
  {
    path: '/search-reprint',
    element: (
      <Layout>
        <SearchReprint />
      </Layout>
    )
  },
  {
    path: '/settings',
    element: (
      <Layout>
        <Settings />
      </Layout>
    )
  },
  {
    path: '/settings/locations',
    element: (
      <Layout>
        <ManageLocations />
      </Layout>
    )
  },
  {
    path: '/settings/location/:id',
    element: (
      <Layout>
        <ManageSubLocations />
      </Layout>
    )
  },
  {
    path: '/settings/printers',
    element: (
      <Layout>
        <EditPrinters />
      </Layout>
    )
  },
  {
    path: '/',
    element: (
      <Layout>
        <ReceiptPurchaseOrders />
      </Layout>
    )
  }
]);
