import { Stack, Tooltip } from '@mui/material';
import DarkButton from './DarkButton';

interface RaiseQMButtonProps {
  isOnline: boolean;
  isAnyPrinterDisabled: boolean;
  setOpenRaiseQM: (value: boolean) => void;
}

export default function RaiseQMButton({ isOnline, isAnyPrinterDisabled, setOpenRaiseQM }: RaiseQMButtonProps) {
  return (
    <Stack justifyContent="center">
      {isOnline ? (
        <Tooltip
          enterTouchDelay={0}
          title={
            isAnyPrinterDisabled
              ? 'A Printer in your settings is disabled, some functionality is restricted. Please select a new printer in Settings.'
              : ''
          }>
          <DarkButton variant="outlined" onClick={() => setOpenRaiseQM(!isAnyPrinterDisabled)} sx={{ color: 'white' }}>
            Raise a QM
          </DarkButton>
        </Tooltip>
      ) : (
        <Tooltip enterTouchDelay={0} title="You are offline. This feature is not available when offline.">
          <DarkButton variant="outlined">Raise a QM</DarkButton>
        </Tooltip>
      )}
    </Stack>
  );
}
