import { Card, Stack, Typography, Tooltip } from '@mui/material';
import WifiOffIcon from '@mui/icons-material/WifiOff';

export default function OfflineCard() {
  return (
    <Card sx={{ width: '50%', alignSelf: 'center', marginTop: '4em !important' }}>
      <Stack spacing={2} alignItems="center" justifyContent="center" sx={{ padding: '1em' }}>
        <Tooltip enterTouchDelay={0} title="You are offline. Any data created will sync the next time you are online.">
          <WifiOffIcon color="info" />
        </Tooltip>
        <Typography fontWeight="bold">Sorry, this functionality is not available offline</Typography>
        <Typography>Please move to a location to get back online.</Typography>
      </Stack>
    </Card>
  );
}
