import { API, graphqlOperation } from 'aws-amplify';
import { GraphQLResult } from '@aws-amplify/api-graphql';
import { postItem } from '../queries/queries';

export type CreateQMProps = {
  workflowStatusId: string;
  reasonId: string;
  issueId: string;
  issue: string;
  reason: string;
  locationName: string;
  description: string;
  purchaseOrderNumber: string;
  progress: string;
  // attachments?: any[]; //@Phase3: Add attachments
  printerIP: string;
  port: string;
  numberOfLabels: number;
  createdBy: string;
};

export type UpdateAsnProps = {
  asns: string[];
};

type PostQM = {
  payload?: string;
  error: string;
};

type CreateQMResponse = {
  postItem: string;
};

export async function postQM(body: string): Promise<PostQM> {
  const genericResponseError = {
    payload: undefined,
    error: 'An error occurred while creating the QM.'
  };
  try {
    const result = (await API.graphql(graphqlOperation(postItem, { body }))) as GraphQLResult<CreateQMResponse>;

    if (result.data && result.data.postItem) {
      const poResult = JSON.parse(result.data.postItem);
      if (poResult.statusCode === 200) {
        return { payload: poResult.body, error: '' };
      }
    }
    return genericResponseError;
  } catch (e) {
    console.error('Error posting QM: ', e);
    return genericResponseError;
  }
}
