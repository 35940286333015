import { Button, ButtonProps } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledButton = styled(Button)<ButtonProps>(({ theme }) => ({
  padding: '0.5em 3em',
  '&:disabled': {
    backgroundColor: theme.palette.text.secondary,
    color: theme.palette.primary.contrastText
  }
}));

export default StyledButton;
