/* eslint-disable react-hooks/exhaustive-deps */
import {
  Alert,
  Divider,
  Stack,
  Table,
  TableBody,
  TableCell,
  Typography,
  OutlinedInput,
  useMediaQuery,
  Link as MuiLink,
  Tooltip
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import {
  AssignToSSCCForm,
  DarkUpperSection,
  PageSection,
  PrintShippingLabelsForm,
  SearchField,
  SearchNotFoundCard,
  StyledButton,
  StyledSwitch,
  StyledTableHead,
  StyledTableRow
} from '../../components';
import { useEffect, useState } from 'react';
import QrCodeIcon from '@mui/icons-material/QrCode';
import { useAlert, useCurrentUser, useDatastore, useIsOnline } from '../../hooks';
import {
  buildPackage,
  buildSSCC,
  buildReceiptEvent,
  isReceiptStatus,
  OrderLineStatus,
  ReceiptPoStatus,
  receiptStatusRecord,
  SsccStatus
} from '../../common';
import { PrintShippingLabelsModel } from '../../components/modals/PrintShippingLabelsForm';
import MoveToStagingForm, { MoveToStagingModel } from '../../components/modals/MoveToStagingModal';
import { UserDetailsProps } from '../../datastore/userDetails';
import LoadingModal from '../../components/modals/LoadingModal';
import { ReceiptEventSiteProps, SSCCProps, SsccType } from '../../datastore/ssccs';
import { LocationProps, LocationType } from '../../datastore/locations';
import { fetchPODetails } from '../../api/poApi';
import { ReceiptHeader, ReceiptLine } from '../../common/types';
import { createJobInBustle } from '../../common/helperFunctions/bustle';
import { convertReceiptEventToCoupaReceiptEvent, createReceiptCoupa } from '../../common/helperFunctions/coupa';
import dayjs from 'dayjs';
import { PackagePrintProps, SsccPrintProps, SSCCPropsWithTbf } from '../../../../Common/Types/types';
import {
  getDestinationAlias,
  preparePackagesForPrinting,
  printPackages,
  printSscc
} from '../../common/helperFunctions/print';
import OfflineCard from '../../components/cards/OfflineCard';
import { useDisabledPrinterContext } from '../../hooks/useIsDisabledPrinter';
import DisabledPrintAlert from '../../components/alerts/DisabledPrintAlert';
import { yearMonthDateFormat } from '../../common/dateTimes';
import { ReceiptEventPackageHistoryProps } from '../../datastore/receiptEventHistory';
import { ConfirmationDialog } from '../../components/modals/ConfirmDialog';
import { galaxyNoteThreeDimensions } from '../../common/strings';
import {
  TransportBookingEventProps,
  transportBookingLocationAlias,
  transportBookingLocationOneLine,
  unknownTbfField
} from '../../datastore/transportBookingEvent';
import { updateAsnStatus } from '../../common/helperFunctions/sscc';
import { isLocationMatch } from '../../common/helperFunctions/locationConflicts';
import { getProcessAsLargeItemStorage, setProcessAsLargeItemStorage } from '../../common/helperFunctions/localStorage';
import { generateAlphanumericId } from '../../common/helperFunctions/generateId';
import { isValidReceiptStatus } from '../../common/status';

export default function ReceiptPurchaseOrders() {
  const theme = useTheme();
  const store = useDatastore();
  const user = useCurrentUser();
  const alert = useAlert();
  const { isOnline } = useIsOnline();
  const { isDisabled: isAnyPrinterDisabled } = useDisabledPrinterContext();
  const [processAsLargeItem, setProcessAsLargeItem] = useState(getProcessAsLargeItemStorage(user.cognitoUser.username));
  const isMobile = useMediaQuery(galaxyNoteThreeDimensions);
  const [isSubmitReady, setIsSubmitReady] = useState(false);
  const [searchPO, setSearchPO] = useState('');
  const [isTransportBooking, setIsTransportBooking] = useState(false);
  const [searchASN, setSearchASN] = useState('');
  const [smallItemSsccChoice, setSmallItemSsccChoice] = useState<SSCCProps>();
  const [directReceipt, setDirectReceipt] = useState(false);
  const [isReceiptLoading, setIsReceiptLoading] = useState(false);
  const [isSearchLoading, setIsSearchLoading] = useState(false);
  const [poData, setPoData] = useState<ReceiptHeader | null>(null);
  const [openPrintLabelsModal, setOpenPrintLabelsModal] = useState(false);
  const [printingData, setPrintingData] = useState<PrintShippingLabelsModel | undefined>();
  const [openAssignSSCCModal, setOpenAssignSSCCModal] = useState(false);
  const [openMoveToStagingModal, setOpenMoveToStagingModal] = useState(false);
  const [userDetails, setUserDetails] = useState<UserDetailsProps | null>(null);
  const [poLocation, setPoLocation] = useState<LocationProps | null>();
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const transportBookingName = 'Transport Booking';

  useEffect(() => {
    setPrintingData(undefined);
    if (isTransportBooking) return;
    setProcessAsLargeItemStorage(processAsLargeItem, user.cognitoUser.username);
  }, [directReceipt, processAsLargeItem]);

  useEffect(() => {
    if (!isTransportBooking) {
      setProcessAsLargeItem(getProcessAsLargeItemStorage(user.cognitoUser.username));
    }
  }, [isTransportBooking]);

  useEffect(() => {
    async function getData() {
      if (searchPO || searchASN) {
        setIsSearchLoading(true);
        const userDetails = await store.userDetails.get(user.cognitoUser.username);
        const poResponse = await fetchPODetails(searchPO, searchASN);
        if (poResponse.error || !poResponse.payload) {
          alert(poResponse.error, 'error');
          setIsSearchLoading(false);
          setPoData(null);
          return;
        }

        if (poResponse.payload?.lines?.length === 0) {
          alert(`There are no items for the given ${searchPO?.length ? 'PO' : 'ASN'}`, 'warning');
          setIsSearchLoading(false);
          setPoData(null);
          return;
        }

        if (poResponse.payload && userDetails && poResponse.payload.site) {
          const location = await store.locations.getNameOrUnknown(poResponse.payload?.site);
          const updatedLines = poResponse.payload.lines.map((line: ReceiptLine) => {
            const adjustedQuantityOrdered = Math.max((line.quantityOrdered ?? 0) - (line.quantityReceived ?? 0), 0);

            return {
              ...line,
              quantityOrdered: adjustedQuantityOrdered,
              quantityReceived: 0,
              partId: line.partId ?? 0,
              sourcePartId: line.sourcePartId ?? ''
            };
          });

          poResponse.payload.lines = updatedLines;

          setPoLocation(location);
          if (poResponse.payload.supplierName === transportBookingName) {
            setIsTransportBooking(true);
            setDirectReceipt(false);
            setProcessAsLargeItem(false);
          } else {
            setIsTransportBooking(false);
          }
          setPoData(poResponse.payload);
          setUserDetails(userDetails);
        } else {
          alert('Could not find Receipt Purchase Order', 'warning');
          setPoData(null);
        }
      } else {
        clearReceiptData();
      }
      setIsSearchLoading(false);
    }

    getData();
  }, [searchPO, searchASN]);

  useEffect(() => {
    if (isSubmitReady && smallItemSsccChoice) {
      onAssignSSCCSubmit();
      setIsSubmitReady(false);
    }
  }, [isSubmitReady, smallItemSsccChoice]);

  function clearReceiptData() {
    setPoData(null);
    setIsTransportBooking(false);
    setPoLocation(null);
    setPrintingData(undefined);
    setSearchASN('');
    setSearchPO('');
    setPrintingData(undefined);
    setOpenAssignSSCCModal(false);
    setSmallItemSsccChoice(undefined);
    setIsReceiptLoading(false);
    setOpenMoveToStagingModal(false);
  }

  function updatedReceiptPurchaseOrder() {
    if (!poData) return;
    const updatedReceiptPurchaseOrder = poData.lines.map((line) => {
      if (line.quantityReceived === line.quantityOrdered) {
        line.quantityReceived = 0;
        line.quantityOrdered = 0;
        line.status = OrderLineStatus.received;
        return line;
      } else if (
        line.quantityReceived &&
        line.quantityReceived > 0 &&
        line.quantityOrdered &&
        line.quantityOrdered > 0
      ) {
        line.quantityOrdered = (line.quantityOrdered ?? 0) - (line.quantityReceived ?? 0);
        line.status = OrderLineStatus.partial;
        return line;
      }
      return line;
    });
    poData.lines = updatedReceiptPurchaseOrder;
    setPoData(poData);
  }

  function getMappedStatus(status: string): string {
    if (isReceiptStatus(status)) {
      return receiptStatusRecord[status];
    } else {
      return 'Unknown';
    }
  }

  async function onPrintLabelsSubmit(data: PrintShippingLabelsModel) {
    const isSmallItemReceipting = !directReceipt && !processAsLargeItem && !isTransportBooking;
    setPrintingData(data);
    if (isSmallItemReceipting) {
      setOpenAssignSSCCModal(true);
    }

    if (processAsLargeItem || isTransportBooking) {
      setOpenMoveToStagingModal(true);
    }

    if (directReceipt) {
      handleDirectReceipting(data);
    }

    setOpenPrintLabelsModal(false);
  }

  async function handlePrinting(packages: PackagePrintProps[], ssccPrintProps?: SsccPrintProps[]) {
    try {
      const userPrinter = await store.printers.getPrinterForUser(user.cognitoUser.username);

      if (!userPrinter) {
        throw new Error('No printer set, please set a printer and try again');
      }

      const printPackageResponse = await printPackages(packages, userPrinter.printerIP, userPrinter.printerPort);
      if (printPackageResponse.error) {
        throw new Error(printPackageResponse.error);
      }

      if (ssccPrintProps) {
        const printSsccResponse = await printSscc(ssccPrintProps, userPrinter.printerIP, userPrinter.printerPort);
        if (printSsccResponse.error) {
          throw new Error(printSsccResponse.error);
        }
      }

      alert('Printing completed successfully', 'success');
    } catch (error) {
      alert(`An error occurred when printing: ${error}`, 'error');
      console.error('Printing error: ', error);
    } finally {
      setIsReceiptLoading(false);
    }
  }

  //Direct receipting process
  async function handleDirectReceipting(modalData: PrintShippingLabelsModel) {
    try {
      setIsReceiptLoading(true);
      const newSite = buildReceiptEvent({ name: '', site: '' });

      if (!poData) {
        alert("Couldn't retrieve data, please try again.", 'error');
        return;
      }

      const asn = `MRL${crypto.randomUUID().substring(0, 8)}`;
      const qrCodeId = generateAlphanumericId(17);
      modalData.packages.forEach((pkg) => {
        const newPackage = buildPackage({
          packageType: pkg.packageType,
          po: poData.poNumber,
          asn: asn,
          jobId: poData.jobId,
          assetNumber: poData.assetNumber,
          supplierId: poData.supplierId?.toString() ?? '',
          supplierName: poData.supplierName,
          priority: poData.priority,
          status: ReceiptPoStatus.available,
          packageQuantity: pkg.total,
          dateReceived: printingData?.dateReceived ?? undefined,
          docketNumber: printingData?.docketNumber ?? undefined,
          hasDangerousGoods: pkg.hasDangerousGoods,
          qrCodeId: qrCodeId,
          companyName: poData.company,
          shipToAttention: poData.shipToAttention,
          items: poData.lines.map((line) => ({
            orderLineId: line.id!,
            partId: line?.partId!,
            sourcePartId: line.sourcePartId ?? '',
            quantityOrdered: line.quantityOrdered ?? null,
            quantityReceived: line.quantityReceived ?? null,
            description: line.description ?? '',
            purchaseType: line.purchaseType ?? '',
            receivingWarehouseId: line.receivingWarehouseId ?? null,
            receivingWarehouseDefaultLocationId: line.receivingWarehouseDefaultLocationId ?? null,
            inTransitWarehouseId: line.inTransitWarehouseId ?? null,
            inTransitWarehouseLocationId: line.inTransitWarehouseLocationId ?? null
          }))
        });
        newSite.packages.push(newPackage);
      });

      const latestPackage = newSite.packages[newSite.packages.length - 1];

      if (!latestPackage) {
        alert('Error receipting items into package, please try again.', 'error');
        return;
      }

      const coupaReceiptEvent = convertReceiptEventToCoupaReceiptEvent(
        latestPackage,
        user.name,
        userDetails?.location?.name,
        asn,
        true
      );

      const receiptResponse = await createReceiptCoupa(coupaReceiptEvent);
      if (receiptResponse.error) {
        alert('Error receipting items to coupa, please try again.', 'error');
        console.error(receiptResponse.error);
        return;
      }

      const validPackages = newSite.packages.filter((pkg) => pkg.quantity && pkg.packageType && pkg.asn && pkg.po);
      if (validPackages.length !== newSite.packages.length) {
        alert('There are missing fields in the packages, cant print.', 'error');
        return;
      }

      if (!userDetails?.location || !poLocation) {
        const message = !userDetails?.location
          ? 'You must set a location in `settings` before you can print'
          : 'Issue getting SSCCs destination location, please try again.';
        alert(message, 'error');
        return;
      }
      let printLocation = poLocation;
      if (poLocation.type === LocationType.subLocation) {
        const parentLocation = await store.locations.getById(poLocation.parentLocationId ?? '');
        if (!parentLocation) {
          alert('Could not find SSCCs destination location for printing', 'warning');
          return;
        }
        printLocation = parentLocation;
        printLocation.alias = poLocation.alias;
      }
      const ssccId = 'Direct Receipt';
      const packages = preparePackagesForPrinting(validPackages, userDetails?.location, printLocation, ssccId);
      await handlePrinting(packages);
      await createReceiptEventHistory(asn, SsccType.direct, ssccId);
      alert('Receipted Items and Printed', 'success');
      updatedReceiptPurchaseOrder();
    } catch (error) {
      alert('An error occurred in the receipting process, please try again.', 'error');
      console.error('An error occured in the direct receipting process: ', error);
    } finally {
      clearReceiptData();
    }
  }

  //Small item receipting process
  async function onAssignSSCCSubmit() {
    try {
      setIsReceiptLoading(true);
      let site: ReceiptEventSiteProps;
      if (!smallItemSsccChoice) {
        alert('Could not find SSCC', 'warning');
        return;
      }

      if (!poData?.site) {
        alert('Could not find site for given PO', 'warning');
        return;
      }

      if (!poLocation) {
        alert('Could not find location for PO', 'warning');
        return;
      }

      if (!printingData) {
        alert('Could not retrieve printing data', 'warning');
        return;
      }
      let printPkgDestination: LocationProps;
      if (!poLocation) {
        alert('Could not find SSCCs destination location', 'warning');
        return;
      }
      site = buildReceiptEvent({
        name: poLocation.name,
        site: poLocation.alias
      });
      printPkgDestination = poLocation;
      const asn = `MRL${crypto.randomUUID().substring(0, 8)}`;
      const qrCodeId = generateAlphanumericId(17);
      printingData.packages.forEach((pkg) => {
        const newPackage = buildPackage({
          packageType: pkg.packageType,
          po: poData.poNumber,
          asn: asn,
          jobId: poData.jobId,
          assetNumber: poData.assetNumber,
          supplierId: poData.supplierId?.toString() ?? '',
          priority: poData.priority,
          supplierName: poData.supplierName,
          status: OrderLineStatus.created,
          packageQuantity: pkg.total,
          hasDangerousGoods: pkg.hasDangerousGoods,
          dateReceived: printingData?.dateReceived ?? undefined,
          docketNumber: printingData?.docketNumber ?? undefined,
          qrCodeId: qrCodeId,
          companyName: poData.company,
          shipToAttention: poData.shipToAttention,
          items: poData.lines.map((line) => ({
            orderLineId: line.id!,
            partId: line.partId!,
            sourcePartId: line.sourcePartId ?? '',
            quantityOrdered: line.quantityOrdered ?? null,
            quantityReceived: line.quantityReceived ?? null,
            description: line.description ?? '',
            purchaseType: line.purchaseType ?? '',
            receivingWarehouseId: line.receivingWarehouseId ?? null,
            receivingWarehouseDefaultLocationId: line.receivingWarehouseDefaultLocationId ?? null,
            inTransitWarehouseId: line.inTransitWarehouseId ?? null,
            inTransitWarehouseLocationId: line.inTransitWarehouseLocationId ?? null
          }))
        });
        site.packages.push(newPackage);
      });

      const latestPackage = site.packages[site.packages.length - 1];

      if (!latestPackage) {
        alert('Error retrieving latest package, please try again.', 'error');
        return;
      }

      const coupaReceiptEvent = convertReceiptEventToCoupaReceiptEvent(
        latestPackage,
        user.name,
        userDetails?.location?.name,
        asn
      );

      const receiptResponse = await createReceiptCoupa(coupaReceiptEvent);
      if (receiptResponse.error) {
        alert('Error receipting items to coupa, please try again.', 'error');
        console.error(receiptResponse.error);
        return;
      }

      smallItemSsccChoice.receiptedEvents.push(site);
      updateAsnStatus(smallItemSsccChoice, SsccStatus.open);
      await store.ssccs.update(smallItemSsccChoice);

      const validPackages = site.packages.filter((pkg) => pkg.quantity && pkg.packageType && pkg.asn && pkg.po);
      if (validPackages.length !== site.packages.length) {
        alert('There are missing fields in the packages, cant print.', 'error');
        return;
      }

      if (!userDetails?.location) {
        alert('You must set a location in `settings` before you can print', 'error');
        return;
      }
      if (printPkgDestination.type === LocationType.subLocation) {
        const parentLocation = await store.locations.getById(printPkgDestination.parentLocationId ?? '');
        if (!parentLocation) {
          alert('Could not find SSCCs destination location for printing', 'warning');
          return;
        }
        printPkgDestination = parentLocation;
        printPkgDestination.alias = poLocation.alias;
      }
      const packages = preparePackagesForPrinting(
        validPackages,
        userDetails?.location,
        printPkgDestination,
        smallItemSsccChoice.id
      );
      await handlePrinting(packages);
      await createReceiptEventHistory(asn, SsccType.standard, smallItemSsccChoice.id);
      updatedReceiptPurchaseOrder();

      alert('Receipted Items', 'success');
    } catch (error) {
      alert('An error occurred in the receipting process, please try again.', 'error');
      console.error('An error occured in the small item receipting process: ', error);
    } finally {
      clearReceiptData();
    }
  }

  function onAssignSSCCBack() {
    setOpenAssignSSCCModal(false);
    setOpenPrintLabelsModal(true);
  }

  async function handleDestinationMatch(ssccChoice: string | undefined) {
    if (!ssccChoice) {
      alert('Could not find SSCC, please try again.', 'warning');
      return;
    }

    const sscc = await store.ssccs.get(ssccChoice);
    if (!sscc || !sscc.destinationLocationId) {
      alert('Could not find SSCC or its destination location.', 'warning');
      return;
    }

    const ssccDestinationLocation = await store.locations.getById(sscc.destinationLocationId);
    if (!ssccDestinationLocation) {
      alert("Could not find SSCC's destination location.", 'warning');
      return;
    }

    sscc.destinationLocation = ssccDestinationLocation;
    setSmallItemSsccChoice(sscc);

    const isSiteMatch = isLocationMatch(poLocation ?? undefined, ssccDestinationLocation);
    setOpenConfirmDialog(!isSiteMatch);

    if (isSiteMatch) {
      setIsSubmitReady(true);
    }
  }

  async function getTransportBookingEvent() {
    if (!poData?.supplierName) {
      alert('Could not find supplier name for given PO.', 'warning');
      return;
    }

    let transportBookingEvent: TransportBookingEventProps | null = null;

    if (poData.supplierName === transportBookingName) {
      transportBookingEvent = await store.transportBookingEvents.getByPo(Number(poData.poId));
    } else {
      alert('Could not find transport booking for given PO.', 'warning');
    }

    return transportBookingEvent || null;
  }

  //Large item or Transport Booking receipting process
  async function onMoveToStagingSubmit(stagingData: MoveToStagingModel) {
    try {
      setOpenPrintLabelsModal(false);
      setOpenAssignSSCCModal(false);
      setIsReceiptLoading(true);

      if (!poData?.site) {
        alert('Could not find site for given PO.', 'warning');
        return;
      }

      if (!poLocation) {
        alert('Could not find destination location for PO.', 'warning');
        return;
      }

      if (!userDetails?.location) {
        alert('You must set a location in `settings` before you can create an SSCC.', 'warning');
        return;
      }

      if (!printingData) {
        alert('No printing or weight data was saved, please try again.', 'warning');
        return;
      }

      for (const pkg of printingData.packages) {
        if (!pkg.depth || !pkg.height || !pkg.width || !pkg.weight) {
          alert('All packages must have Dimensions and Weight', 'warning');
          return;
        }
      }
      let transportBookingEvent: TransportBookingEventProps | null | undefined = null;
      if (isTransportBooking) {
        transportBookingEvent = await getTransportBookingEvent();
      }
      const destinationAlias = await getDestinationAlias(
        isTransportBooking,
        poLocation,
        store.locations,
        transportBookingEvent?.destinationLocation ?? undefined
      );

      const newSSCC = buildSSCC({
        locationAlias: destinationAlias,
        destinationLocationId: poLocation.id,
        originLocationId: userDetails.location.id,
        stagingLocation: stagingData.location,
        nickname: false,
        isLargeItem: processAsLargeItem,
        isTransportBooking: isTransportBooking,
        transportBookingDestination: transportBookingEvent?.destinationLocation ?? null,
        ssccType: SsccType.large,
        ssccStatus: SsccStatus.staged
      });

      if (!newSSCC) {
        alert('An error has occured whilst creating the SSCC, try again.', 'warning');
        return;
      }

      if (isTransportBooking) {
        newSSCC.bustleServiceType = transportBookingEvent?.serviceType ?? undefined;
      }

      if ((processAsLargeItem || isTransportBooking) && newSSCC && printingData?.packages.length) {
        newSSCC.height = printingData.packages[0].height?.toString() ?? '';
        newSSCC.width = printingData.packages[0].width?.toString() ?? '';
        newSSCC.depth = printingData.packages[0].depth?.toString() ?? '';
        newSSCC.weight = printingData.packages[0].weight?.toString() ?? '';
      }

      const newSite = buildReceiptEvent({ name: poLocation.name, site: poLocation.alias });
      if (!newSSCC) {
        alert('An error has occured whilst creating the SSCC, try again.', 'warning');
        return;
      }

      newSSCC.receiptedEvents.push(newSite);
      const asn = `MRL${crypto.randomUUID().substring(0, 8)}`;
      printingData.packages.forEach((pkg) => {
        const newPackage = buildPackage({
          packageType: pkg.packageType,
          po: poData.poNumber,
          asn: asn,
          jobId: poData.jobId,
          shipToAttention: poData.shipToAttention,
          assetNumber: poData.assetNumber,
          supplierId: poData.supplierId?.toString() ?? '',
          supplierName: poData.supplierName,
          priority: poData.priority,
          status: OrderLineStatus.created,
          packageQuantity: pkg.total,
          hasDangerousGoods: pkg.hasDangerousGoods,
          dateReceived: printingData?.dateReceived ?? undefined,
          docketNumber: printingData?.docketNumber ?? undefined,
          height: pkg.height?.toString() ?? '',
          width: pkg.width?.toString() ?? '',
          depth: pkg.depth?.toString() ?? '',
          weight: pkg.weight?.toString() ?? '',
          companyName: poData.company,
          qrCodeId: generateAlphanumericId(17),
          items: poData.lines.map((line) => ({
            orderLineId: line.id!,
            partId: line.partId!,
            sourcePartId: line.sourcePartId ?? '',
            quantityOrdered: line.quantityOrdered ?? null,
            quantityReceived: line.quantityReceived ?? null,
            description: line.description ?? '',
            purchaseType: line.purchaseType ?? '',
            receivingWarehouseId: line.receivingWarehouseId ?? null,
            receivingWarehouseDefaultLocationId: line.receivingWarehouseDefaultLocationId ?? null,
            inTransitWarehouseId: line.inTransitWarehouseId ?? null,
            inTransitWarehouseLocationId: line.inTransitWarehouseLocationId ?? null
          }))
        });
        newSSCC.receiptedEvents[0].packages.push(newPackage);
      });

      const latestPackage = newSite.packages[newSite.packages.length - 1];

      if (!latestPackage) {
        alert('Error receipting items into package, please try again.', 'error');
        return;
      }

      const coupaReceiptEvent = convertReceiptEventToCoupaReceiptEvent(
        latestPackage,
        user.name,
        userDetails?.location?.name,
        asn
      );

      const receiptResponse = await createReceiptCoupa(coupaReceiptEvent);
      if (receiptResponse.error) {
        alert('Error receipting items to coupa, please try again.', 'error');
        console.error(receiptResponse.error);
        return;
      }

      updateAsnStatus(newSSCC, SsccStatus.staged);

      await store.ssccs.create(newSSCC);

      let bustleDestinationLocation = poLocation;
      let parentLocation: LocationProps | undefined = bustleDestinationLocation;
      if (poLocation.type === LocationType.subLocation) {
        parentLocation = await store.locations.getById(poLocation.parentLocationId ?? '');
        if (!parentLocation) {
          alert('Failed to update the SSCC, try again.', 'error');
          return null;
        }
        bustleDestinationLocation.address = parentLocation.address;
        bustleDestinationLocation.suburb = parentLocation.suburb;
        bustleDestinationLocation.state = parentLocation.state;
        bustleDestinationLocation.postcode = parentLocation.postcode;
      }
      if (isTransportBooking && transportBookingEvent) {
        const address =
          transportBookingEvent?.destinationLocation?.addressLine2 === unknownTbfField
            ? transportBookingEvent.destinationLocation.addressLine1
            : `${transportBookingEvent?.destinationLocation?.addressLine1} - ${transportBookingEvent?.destinationLocation?.addressLine2}`;
        bustleDestinationLocation.address = address;
        bustleDestinationLocation.name = transportBookingEvent?.destinationLocation?.businessName || unknownTbfField;
        bustleDestinationLocation.suburb = transportBookingEvent?.destinationLocation?.suburb;
        bustleDestinationLocation.state = transportBookingEvent?.destinationLocation?.state;
        bustleDestinationLocation.alias = transportBookingLocationAlias(
          transportBookingEvent?.destinationLocation || null
        );
        bustleDestinationLocation.postcode = transportBookingEvent?.destinationLocation?.postCode.toString();
      }

      const ssccWithExtraTbfFields: SSCCPropsWithTbf = {
        ...newSSCC,
        transportBookingEvent: {
          additionalField1: transportBookingEvent?.additionalField1 ?? '',
          additionalField2: transportBookingEvent?.additionalField2 ?? '',
          additionalField3: transportBookingEvent?.additionalField3 ?? '',
          notesContent: transportBookingEvent?.notesContent ?? '',
          destinationLocation: transportBookingEvent?.destinationLocation ?? null,
          originLocation: transportBookingEvent?.originLocation ?? null
        }
      };

      const response = await createJobInBustle(
        ssccWithExtraTbfFields,
        userDetails.location,
        bustleDestinationLocation,
        parentLocation?.deliveryDays
      );

      if (response.error) {
        throw new Error(response.error);
      }

      const currentDate = dayjs();
      const validPackages = newSite.packages.filter((pkg) => pkg.quantity && pkg.packageType && pkg.asn && pkg.po);
      if (validPackages.length !== newSite.packages.length) {
        alert('There are missing fields in the packages, cant print.', 'error');
        return;
      }

      const sourceAddress = userDetails.location.toOneLineAddress();

      let destinationAddress = poLocation.toOneLineAddress();

      let printPkgDestination = poLocation;
      if (printPkgDestination.type === LocationType.subLocation) {
        const parentLocation = await store.locations.getById(printPkgDestination.parentLocationId ?? '');
        if (!parentLocation) {
          alert('Could not find SSCCs destination location for printing', 'warning');
          return;
        }
        destinationAddress = parentLocation.toOneLineAddress();
      }

      const packages = preparePackagesForPrinting(
        validPackages,
        userDetails?.location,
        printPkgDestination,
        newSSCC.id,
        isTransportBooking ? transportBookingEvent?.destinationLocation : null
      );

      const ssccPrints: SsccPrintProps[] = validPackages.flatMap((pkg, i) => {
        return Array.from({ length: pkg.quantity || 0 }, (_, index) => ({
          createdAt: currentDate.format(yearMonthDateFormat),
          packageNumber: '0',
          totalPackages: '0',
          packageIndex: index.toString(),
          sourceAddress: sourceAddress,
          destinationAddress: isTransportBooking
            ? transportBookingLocationOneLine(transportBookingEvent?.destinationLocation || null)
            : destinationAddress,
          destinationAlias: destinationAlias,
          containerType: printingData.packages[i].packageType ?? '',
          length: printingData.packages[i].depth?.toString() ?? '',
          width: printingData.packages[i].width?.toString() ?? '',
          height: printingData.packages[i].height?.toString() ?? '',
          weight: printingData.packages[i].weight?.toString() ?? '',
          qrCodeId: pkg.qrCodeId,
          ssccId: newSSCC.id,
          nickname: newSSCC.nickname || '',
          asn: asn,
          hasDangerousGoods: printingData.packages[i].hasDangerousGoods ?? false
        }));
      });

      ssccPrints.forEach((ssccPackage, index) => {
        ssccPackage.packageNumber = (index + 1).toString();
        ssccPackage.totalPackages = ssccPrints.length.toString();
      });

      await handlePrinting(packages, ssccPrints);
      await createReceiptEventHistory(asn, isTransportBooking ? SsccType.transportBooking : SsccType.large, newSSCC.id);

      alert('Receipted items, created SSCC and printed', 'success');
      updatedReceiptPurchaseOrder();
    } catch (error) {
      alert('An error occurred in the receipting process, please try again.', 'error');
      console.error('An error occured in the large item receipting process: ', error);
    } finally {
      clearReceiptData();
    }
  }

  function onMoveToStagingBack() {
    setOpenPrintLabelsModal(true);
    setOpenMoveToStagingModal(false);
  }

  function onLineQuantityChange(lineId: number | null | undefined, quantity: string) {
    const clone = structuredClone(poData);
    if (clone) {
      const line = clone.lines.find((line) => line.id === lineId);

      if (line && line.quantityOrdered) {
        line.quantityReceived =
          Number(quantity) > line.quantityOrdered ? Number(line.quantityOrdered) : Number(quantity);
        setPoData(clone);
      }
    }
  }

  function onAutoFill() {
    const clone = structuredClone(poData);
    if (clone) {
      for (const line of clone.lines) {
        line.quantityReceived = line.quantityOrdered;
      }
      setPoData(clone);
    }
  }

  async function createReceiptEventHistory(internalAsn: string, receiptType: string, associatedSSCC: string) {
    try {
      const receiptEventPackageHistory: ReceiptEventPackageHistoryProps = {
        id: crypto.randomUUID(),
        asn: internalAsn,
        receiptType: receiptType,
        associatedSSCC: associatedSSCC,
        receiptedBy: user.email,
        actionTime: dayjs()
      };

      await store.receiptEventPackageHistories.create(receiptEventPackageHistory);
    } catch (error) {
      console.error('Error creating receipt event history: ', error);
    }
  }

  const renderSwitches = () => (
    <>
      <StyledSwitch
        value={processAsLargeItem}
        disabled={isTransportBooking}
        onChange={() => {
          setProcessAsLargeItem(!processAsLargeItem);
          setDirectReceipt(false);
        }}
        label="Process as Large Item"
      />
      <StyledSwitch
        value={directReceipt}
        disabled={isTransportBooking}
        onChange={() => {
          setProcessAsLargeItem(false);
          setDirectReceipt(!directReceipt);
        }}
        label="Direct Receipt"
      />
    </>
  );
  const hasSearched = searchPO || searchASN;
  return (
    <>
      <DarkUpperSection>
        <Stack spacing={2} sx={{ padding: '1em 0' }}>
          <Typography variant="h2" fontWeight="bold" color={theme.palette.primary.contrastText}>
            Receipt Purchase Orders
          </Typography>
          <Stack direction="row" spacing={3} alignItems="center">
            <SearchField
              placeholder="Purchase Order"
              startIcon={<QrCodeIcon />}
              includeEndIcon
              onTrigger={(value) => {
                setSearchASN('');
                setSearchPO(value);
                setIsTransportBooking(false);
              }}
              value={searchPO}
            />
            <SearchField
              placeholder="Docket Number"
              startIcon={<QrCodeIcon />}
              includeEndIcon
              onTrigger={(value) => {
                setSearchPO('');
                setSearchASN(value);
                setIsTransportBooking(false);
              }}
              value={searchASN}
            />
            {!isMobile && !isTransportBooking && <>{renderSwitches()}</>}
            <Stack direction="row" sx={{ flex: 1, justifyContent: 'end' }}>
              <StyledButton
                variant="contained"
                onClick={() => setOpenPrintLabelsModal(true)}
                disabled={
                  user.isReadOnly ||
                  isAnyPrinterDisabled ||
                  poData?.lines.every((line) => line.quantityReceived === 0) ||
                  !poData?.lines.length
                }
                sx={{
                  whiteSpace: isMobile ? 'nowrap' : null
                }}>
                Next: Shipping Labels
              </StyledButton>
            </Stack>
          </Stack>
          {isMobile && !isTransportBooking && (
            <Stack direction="row" spacing="3em">
              {renderSwitches()}
            </Stack>
          )}
        </Stack>
      </DarkUpperSection>
      {isOnline ? (
        <PageSection>
          {poData ? (
            <Stack spacing={2} sx={{ marginTop: '1em' }}>
              {isAnyPrinterDisabled ? (
                <DisabledPrintAlert />
              ) : directReceipt ? (
                <Alert severity="warning">
                  <b>Important: </b>Direct receipting is turned on. This feature should be used only when items are
                  directly shipped to the destination.
                </Alert>
              ) : (
                <></>
              )}
              <Stack direction="row" spacing={2} justifyContent="space-between">
                <Stack>
                  <Typography variant="body2" color={theme.palette.text.secondary}>
                    Site
                  </Typography>
                  <Typography fontWeight="bold">{poData?.site}</Typography>
                </Stack>
                <Stack>
                  <Typography variant="body2" color={theme.palette.text.secondary}>
                    Supplier
                  </Typography>
                  <Typography>{poData?.supplierName}</Typography>
                </Stack>
                <Stack>
                  <Typography variant="body2" color={theme.palette.text.secondary}>
                    Company
                  </Typography>
                  <Typography>{poData?.company}</Typography>
                </Stack>
                <Stack>
                  <Typography variant="body2" color={theme.palette.text.secondary}>
                    Ship to Attn.
                  </Typography>
                  <Typography>{poData?.shipToAttention}</Typography>
                </Stack>
                <Stack>
                  <Typography variant="body2" color={theme.palette.text.secondary}>
                    PO
                  </Typography>
                  <Typography>
                    {poData?.poNumber ? (
                      <Stack sx={{ fontWeight: 'bold' }}>
                        <Typography fontWeight="bold">{poData.poNumber}</Typography>
                        <MuiLink
                          component="a"
                          href={`${process.env.REACT_APP_COUPA_BASE_URL}${poData.poNumber}`}
                          target="_blank"
                          rel="noopener noreferrer">
                          View
                        </MuiLink>
                      </Stack>
                    ) : (
                      ''
                    )}
                  </Typography>
                </Stack>
                <Stack>
                  <Typography variant="body2" color={theme.palette.text.secondary}>
                    PO Status
                  </Typography>
                  <Typography>{getMappedStatus(poData?.poStatus ?? '')}</Typography>
                </Stack>
              </Stack>
              <Divider />
              <Stack direction="row" spacing={2} justifyContent="space-between">
                <Typography>
                  This consignment contains <b>{poData.lines.length} lines</b>
                </Typography>
                <StyledButton
                  variant="contained"
                  onClick={onAutoFill}
                  sx={{ padding: '0.5em 1em' }}
                  disabled={poData.lines.some((line) => !isValidReceiptStatus(line.status))}>
                  Autofill
                </StyledButton>
              </Stack>
              <Stack direction="row" spacing={2}>
                <Table>
                  <StyledTableHead>
                    <TableCell width="5%">#</TableCell>
                    <TableCell width="10%">Status</TableCell>
                    <TableCell width="17%">Part ID</TableCell>
                    <TableCell width="10%" title="Purchase Type">
                      Type
                    </TableCell>
                    <TableCell width="43%">Description</TableCell>
                    <TableCell width="15%">Quantity</TableCell>
                  </StyledTableHead>
                  <TableBody>
                    {poData.lines.map((line, index) => {
                      const isDisabled = !isValidReceiptStatus(line.status);
                      const fontColour = isDisabled ? theme.palette.text.secondary : theme.palette.text.primary;
                      return (
                        <StyledTableRow key={line.id}>
                          <TableCell sx={{ color: fontColour, fontWeight: isDisabled ? 'normal' : 'bold' }}>
                            {index + 1}
                          </TableCell>
                          <TableCell sx={{ color: fontColour }}>{getMappedStatus(line.status ?? '')}</TableCell>
                          <TableCell sx={{ color: fontColour }}>{line.sourcePartId}</TableCell>
                          <TableCell sx={{ color: fontColour }}>{line.purchaseType}</TableCell>
                          <TableCell sx={{ color: fontColour }}>{line.description}</TableCell>
                          <TableCell sx={{ color: fontColour }}>
                            <Stack direction="row" spacing={2} alignItems="center">
                              {isDisabled ? (
                                <Tooltip enterTouchDelay={0} title="Can't receipt line in an invalid status.">
                                  <OutlinedInput
                                    sx={{ flex: 1 }}
                                    inputProps={{
                                      style: {
                                        padding: '0.5em'
                                      },
                                      min: 0,
                                      max: line.quantityOrdered,
                                      step: 1
                                    }}
                                    type="number"
                                    value={line.quantityReceived}
                                    onChange={(event) => onLineQuantityChange(line.id, event.target.value)}
                                    disabled={isDisabled}
                                  />
                                </Tooltip>
                              ) : (
                                <OutlinedInput
                                  sx={{ flex: 1 }}
                                  inputProps={{
                                    style: {
                                      padding: '0.5em'
                                    },
                                    min: 0,
                                    max: line.quantityOrdered,
                                    step: 1
                                  }}
                                  type="number"
                                  value={line.quantityReceived}
                                  onChange={(event) => onLineQuantityChange(line.id, event.target.value)}
                                />
                              )}
                              <Typography sx={{ flex: 1 }}>of {line.quantityOrdered}</Typography>
                            </Stack>
                          </TableCell>
                        </StyledTableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </Stack>
            </Stack>
          ) : (
            <></>
          )}
          {!poData && hasSearched && !isSearchLoading ? (
            <Stack justifyContent="center">
              <SearchNotFoundCard />
            </Stack>
          ) : (
            <></>
          )}
        </PageSection>
      ) : (
        <PageSection>
          <Stack justifyContent="center">
            <OfflineCard />
          </Stack>
        </PageSection>
      )}

      {openPrintLabelsModal && (
        <PrintShippingLabelsForm
          open={openPrintLabelsModal}
          data={printingData}
          asn={searchASN ? searchASN : ''}
          isTransportBooking={isTransportBooking}
          isLargeItem={processAsLargeItem}
          onSubmit={onPrintLabelsSubmit}
          onClose={() => setOpenPrintLabelsModal(false)}
          isDirectReceipt={directReceipt}
        />
      )}
      {openAssignSSCCModal && (
        <AssignToSSCCForm
          open={openAssignSSCCModal}
          onSubmit={handleDestinationMatch}
          onBack={onAssignSSCCBack}
          onClose={() => setOpenAssignSSCCModal(false)}
          isLargeItem={processAsLargeItem}
          poLocation={poLocation || undefined}
        />
      )}

      {openMoveToStagingModal && (
        <MoveToStagingForm
          open={openMoveToStagingModal}
          onSubmit={onMoveToStagingSubmit}
          onBack={onMoveToStagingBack}
          onClose={() => setOpenMoveToStagingModal(false)}
        />
      )}
      <LoadingModal
        isLoading={isReceiptLoading || isSearchLoading}
        titleText={isReceiptLoading ? 'Receipting' : 'Searching'}
        bodyText={isReceiptLoading ? 'Please wait, receipting items' : 'Please wait, searching items'}
      />
      <ConfirmationDialog
        open={openConfirmDialog}
        onClose={() => {
          setOpenConfirmDialog(false);
          setSmallItemSsccChoice(undefined);
        }}
        onConfirm={() => {
          setOpenConfirmDialog(false);
          setIsSubmitReady(true);
        }}
        title="Location Conflict"
        content="The receipted items destination site does not match the SCCC destination. Are you sure wish to continue?"
      />
    </>
  );
}
