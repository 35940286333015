import { Button, alpha, ButtonProps } from '@mui/material';
import { styled } from '@mui/material/styles';

export const DarkButton = styled(Button)<ButtonProps>(({ theme }) => ({
  color: theme.palette.primary.contrastText,
  backgroundColor: alpha(theme.palette.primary.light, 0.2),
  borderWidth: '2px',
  '&:disabled': {
    color: theme.palette.common.white
  }
}));

export default DarkButton;
