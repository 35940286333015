/* eslint-disable react-hooks/exhaustive-deps */
import { Dialog, Divider, IconButton, Stack, Typography } from '@mui/material';
import { useForm, SubmitHandler } from 'react-hook-form';
import CloseIcon from '@mui/icons-material/Close';
import { NumberFieldFormInput, SelectFormInput, TextFieldFormInput } from '../formInputs';
import StyledButton from '../buttons/StyledButton';
import { LocationProps, LocationType } from '../../datastore/locations';
import { useCurrentUser, useDatastore } from '../../hooks';
import { useEffect } from 'react';
import ToggleFormInput from '../formInputs/toggle';
import LoadingSpinner from '../loadingSpinner/LoadingSpinner';

interface NewSSCCSelectFormInputOptionsProps {
  name: string;
  value: string;
  type: string;
}

interface NewSsccFormProps {
  open: boolean;
  onSubmit: (data: NewSsccModel) => void;
  onClose: () => void;
  sites: NewSSCCSelectFormInputOptionsProps[];
  prePopulateSite: boolean;
  originLocation?: LocationProps | undefined;
  isLoading?: boolean;
}

export interface NewSsccModel {
  nickname: string;
  site: string;
  height: string;
  width: string;
  depth: string;
  showDropSites: boolean;
  originLocation?: LocationProps | undefined;
}

export default function NewSsccForm({
  open,
  onSubmit,
  onClose,
  sites,
  isLoading = false,
  prePopulateSite = false,
  originLocation
}: NewSsccFormProps) {
  const defaultValues: NewSsccModel = {
    nickname: '',
    site: '',
    height: '',
    width: '',
    depth: '',
    showDropSites: false
  };
  const store = useDatastore();
  const user = useCurrentUser();
  const {
    handleSubmit,
    control,
    watch,
    setValue,
    formState: { errors }
  } = useForm({ defaultValues });

  useEffect(() => {
    if (prePopulateSite) {
      setMatchedLocation();
    }
  }, [originLocation]);

  const onSubmitForm: SubmitHandler<NewSsccModel> = (data) => onSubmit(data);

  async function setMatchedLocation() {
    if (originLocation) {
      let foundSite = null;
      if (originLocation.type === LocationType.mainLocation) {
        foundSite = sites.find((site) => site.name === originLocation.name);
      } else {
        const parentLocation = await store.locations.getById(originLocation.parentLocationId ?? '');
        foundSite = sites.find((site) => site.name === parentLocation?.name);
      }
      setValue('site', foundSite?.value ?? '');
    }
  }
  const showDropSitesValue = watch('showDropSites');
  let sortedSites = sites.sort((a, b) => a.name.localeCompare(b.name));
  if (!showDropSitesValue) {
    sortedSites = sortedSites.filter((site) => site.type === LocationType.mainLocation);
  }

  return (
    <Dialog open={open} maxWidth="xs" fullWidth>
      <form onSubmit={handleSubmit(onSubmitForm)}>
        <Stack spacing={3} sx={{ padding: '1em 2em' }}>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Typography variant="h4" fontWeight="bold">
              Create New SSCC
            </Typography>
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Stack>
          <Divider />
          <Stack spacing={1}>
            <Typography>SSCC Nickname</Typography>
            <TextFieldFormInput name="nickname" control={control} error={errors['nickname']} />
          </Stack>
          <Stack spacing={1}>
            <Typography>Destination Site</Typography>
            <SelectFormInput name="site" control={control} required options={sortedSites} error={errors['site']} />
          </Stack>
          <Divider />
          <Stack spacing={1}>
            <Typography>Dimensions</Typography>
            <Typography variant="body2">
              Please enter the item dimensions in <b>Centimetres</b>
            </Typography>
            <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center">
              <NumberFieldFormInput
                name="height"
                label="Height"
                control={control}
                sx={{ minWidth: 'auto' }}
                error={errors['height']}
                wholeNumbersOnly
              />
              <NumberFieldFormInput
                name="width"
                label="Width"
                control={control}
                sx={{ minWidth: 'auto' }}
                error={errors['width']}
                wholeNumbersOnly
              />
              <NumberFieldFormInput
                name="depth"
                label="Depth"
                control={control}
                sx={{ minWidth: 'auto' }}
                error={errors['depth']}
                wholeNumbersOnly
              />
            </Stack>
            <ToggleFormInput
              name="showDropSites"
              control={control}
              label="Show Drop Sites"
              error={errors['showDropSites']}
            />
          </Stack>
          <Stack
            sx={{
              justifyContent: isLoading ? 'center' : '',
              alignItems: isLoading ? 'center' : ''
            }}>
            <LoadingSpinner isLoading={isLoading}>
              <StyledButton variant="contained" type="submit" disabled={user.isReadOnly}>
                Complete + Print
              </StyledButton>
            </LoadingSpinner>
          </Stack>
        </Stack>
      </form>
    </Dialog>
  );
}
