import { useState, useEffect } from 'react';
import { useDatastore } from './useDatastore';

export function usePackageTypes() {
  const [packageTypes, setPackageTypes] = useState<string[]>();
  const store = useDatastore();

  useEffect(() => {
    async function fetchData() {
      const data = await store.packageTypes.getArray();
      if (data) {
        setPackageTypes(data.sort((a, b) => a.localeCompare(b)));
      }
    }

    fetchData();
  }, [store.packageTypes]);

  return packageTypes;
}
