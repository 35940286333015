import { DataStore } from 'aws-amplify';
import { Location } from '../models';

export enum LocationType {
  mainLocation = 'MainLocation',
  subLocation = 'SubLocation'
}

export enum UnknownLocation {
  Name = 'Unknown',
  Alias = 'UNKNWN',
  Address = 'Unknown Address',
  Suburb = 'Unknown Suburb',
  State = 'WA',
  Postcode = '6000',
  ContactName = 'Unknown',
  ContactPhone = '0'
}

const fallbackLocation = {
  type: LocationType.mainLocation,
  name: UnknownLocation.Name,
  alias: UnknownLocation.Alias,
  address: UnknownLocation.Address,
  suburb: UnknownLocation.Suburb,
  state: UnknownLocation.State,
  postcode: UnknownLocation.Postcode,
  contactName: UnknownLocation.ContactName,
  contactPhone: UnknownLocation.ContactPhone,
  isDisabled: false
};

export interface LocationProps {
  id: string;
  type: string;
  name: string;
  alias: string;
  isDisabled: boolean;
  isAutoReceipt?: boolean;
  deliveryDays?: number;
  address?: string;
  suburb?: string;
  state?: string;
  postcode?: string;
  contactName?: string;
  contactPhone?: string;
  parentLocationId?: string;
  subLocations?: LocationProps[];
  toOneLineAddress: () => string;
}

export interface LocationDataProps {
  getById: (id: string) => Promise<LocationProps | undefined>;
  getList: () => Promise<LocationProps[] | undefined>;
  create: (data: LocationProps) => Promise<LocationProps | undefined>;
  getNameOrUnknown: (name: string) => Promise<LocationProps | undefined>;
  update: (data: LocationProps) => Promise<LocationProps | undefined>;
}

export const locationData = () => {
  async function getById(id: string) {
    const location = await DataStore.query(Location, id);

    if (location) {
      return await convertLocationToProps(location);
    }
  }

  async function getNameOrUnknown(name: string) {
    const locations = await DataStore.query(Location);
    const filteredLocations = locations.filter((location) => location.name?.toLowerCase() === name.toLowerCase());
    if (!filteredLocations.length) {
      const unknownLocations = await DataStore.query(Location, (location) => location.name.eq(UnknownLocation.Name));
      if (!unknownLocations) {
        return undefined;
      }
      return await convertLocationToProps(unknownLocations[0]);
    }
    const location = filteredLocations[0];
    return await convertLocationToProps(location);
  }

  async function getList() {
    const locations = await DataStore.query(Location);

    if (locations) {
      return Promise.all(
        locations.map(async (r) => {
          return await convertLocationToProps(r);
        })
      );
    }

    return undefined;
  }

  async function update(data: LocationProps) {
    const original = await DataStore.query(Location, data.id);

    if (original) {
      const updated = await DataStore.save(
        Location.copyOf(original, (updated) => {
          updated.name = data.name;
          updated.alias = data.alias;
          updated.address = data.address;
          updated.suburb = data.suburb;
          updated.state = data.state;
          updated.postcode = data.postcode;
          updated.contactName = data.contactName;
          updated.contactPhone = data.contactPhone;
          updated.isDisabled = data.isDisabled;
          updated.parentLocationId = data.parentLocationId;
          updated.isAutoReceipt = data.isAutoReceipt;
          updated.deliveryDays = data.deliveryDays;
        })
      );

      if (updated) {
        return await convertLocationToProps(updated);
      }
    }
    return undefined;
  }

  async function create(data: LocationProps): Promise<LocationProps | undefined> {
    const created = await DataStore.save(
      new Location({
        id: data.id,
        type: data.type,
        name: data.name,
        alias: data.alias,
        address: data.address,
        suburb: data.suburb,
        state: data.state,
        postcode: data.postcode,
        contactName: data.contactName,
        contactPhone: data.contactPhone,
        isDisabled: data.isDisabled,
        parentLocationId: data.parentLocationId,
        isAutoReceipt: data.isAutoReceipt,
        deliveryDays: data.deliveryDays
      })
    );

    if (created) {
      return await convertLocationToProps(created);
    }
  }

  const returned: LocationDataProps = {
    getById,
    getList,
    create,
    getNameOrUnknown,
    update
  };

  return returned;
};

export async function convertLocationToProps(data: Location): Promise<LocationProps> {
  let subLocationsProps: LocationProps[] = [];

  if (data.subLocations) {
    const subLocations = await data.subLocations.toArray();

    for (const subLocation of subLocations) {
      const fetchedSubLocation = await DataStore.query(Location, subLocation.id);
      if (fetchedSubLocation) {
        subLocationsProps.push(await convertLocationToProps(fetchedSubLocation));
      }
    }
  }

  const locationProps: LocationProps = {
    id: data.id,
    type: data.type || fallbackLocation.type,
    name: data.name || fallbackLocation.name,
    alias: data.alias || fallbackLocation.alias,
    address: data.address || fallbackLocation.address,
    suburb: data.suburb || fallbackLocation.suburb,
    state: data.state || fallbackLocation.state,
    postcode: data.postcode || fallbackLocation.postcode,
    contactName: data.contactName || fallbackLocation.contactName,
    contactPhone: data.contactPhone || fallbackLocation.contactPhone,
    isDisabled: data.isDisabled || fallbackLocation.isDisabled,
    parentLocationId: data.parentLocationId || undefined,
    subLocations: subLocationsProps,
    isAutoReceipt: data.isAutoReceipt || undefined,
    deliveryDays: data.deliveryDays || undefined,
    toOneLineAddress: () => toOneLineAddress(locationProps)
  };

  return locationProps;
}

function toOneLineAddress(location: LocationProps): string {
  return [location.address, location.suburb, location.state, location.postcode].filter(Boolean).join(', ');
}
