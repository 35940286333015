import dayjs from 'dayjs';
import { ReceiptEventPackageItemProps, ReceiptEventPackageProps } from '../../../datastore/ssccs';
import { ManifestProps } from '../../../datastore/tripSheets';
import { ManifestPackageStatus } from '../../../common/status';
import { variousAsn } from '../../../common/constants';

export function mapToReceiptedItems(manifest: ManifestProps): Partial<ReceiptEventPackageProps>[] {
  const recs =
    manifest?.ssccs
      ?.flatMap((sscc) => sscc.packages)
      ?.filter(
        (pkg) =>
          pkg.receivedStatus !== ManifestPackageStatus.qm_pending && pkg.receivedStatus !== ManifestPackageStatus.qm
      )
      ?.filter((pkg) => pkg.asn !== variousAsn || (pkg.inventoryLines ?? []).length > 0)
      .map((pkg) => {
        const itms: ReceiptEventPackageItemProps[] = (pkg.inventoryLines ?? []).map((line) => {
          const itm: ReceiptEventPackageItemProps = {
            orderLineId: line.orderLineId,
            partId: line.partId,
            sourcePartId: line.sourcePartId ?? '',
            quantityReceived: line.quantityReceived,
            purchaseType: line.purchaseType,
            receipt: dayjs(),
            receivingWarehouseId: line.receivingWarehouseId ?? null,
            receivingWarehouseDefaultLocationId: line.receivingWarehouseDefaultLocationId ?? null,
            inTransitWarehouseId: line.inTransitWarehouseId ?? null,
            inTransitWarehouseLocationId: line.inTransitWarehouseLocationId ?? null
          };
          return itm;
        });
        return {
          id: pkg.id ?? '',
          asn: pkg.asn,
          quantity: pkg.quantity,
          items: itms
        };
      }) ?? [];

  // Deduplicate entries based on partId, asn, quantity, and inTransitWarehouseLocationId
  const uniqueItems = new Map();
  for (const rec of recs) {
    rec.items.forEach((item) => {
      const key = `${item.orderLineId}-${item.partId}-${rec.asn}-${rec.quantity}-${item.inTransitWarehouseLocationId}`;
      if (!uniqueItems.has(key)) {
        uniqueItems.set(key, rec);
      }
    });
  }

  return Array.from(uniqueItems.values());
}
