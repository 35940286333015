import { Button, ButtonProps } from '@mui/material';
import { styled } from '@mui/material/styles';

export const GreyButton = styled(Button)<ButtonProps>(({ theme }) => ({
  color: 'white',
  backgroundColor: theme.palette.grey[700],
  '&:hover': {
    backgroundColor: theme.palette.grey[800]
  }
}));

export default GreyButton;
