import { postReceipt } from '../../api/postReceipt';
import { ReceiptEventPackageProps } from '../../datastore/ssccs';
import { BaseEvent } from '../../../../Common/Types/types';
import { CreateQMProps, UpdateAsnProps, postQM } from '../../api/postQM';
import { PostAsns, postManifestAsns } from '../../api/postManifestAsns';
import { CoupaReceiptEvent } from '../types';

export async function createReceiptCoupa(receiptEvent: ReceiptEventPackageProps) {
  const event: BaseEvent<'ftl_po_receipt', ReceiptEventPackageProps> = {
    id: receiptEvent.id,
    source: 'ftl',
    'detail-type': 'ftl_po_receipt',
    detail: receiptEvent
  };

  const serializedEvent = JSON.stringify(event);
  const response = await postReceipt(serializedEvent);

  return response;
}

export async function createSiteReceiptCoupa(receiptEvent: Partial<ReceiptEventPackageProps>) {
  const event: BaseEvent<'ftl_site_receipt', Partial<ReceiptEventPackageProps>> = {
    id: receiptEvent.id,
    source: 'ftl',
    'detail-type': 'ftl_site_receipt',
    detail: receiptEvent
  };

  const serializedEvent = JSON.stringify(event);
  const response = await postReceipt(serializedEvent);

  return response;
}

export async function createQMCoupa(receiptEvent: CreateQMProps) {
  const event: BaseEvent<'ftl_qm_create', CreateQMProps> = {
    id: crypto.randomUUID(),
    source: 'ftl',
    'detail-type': 'ftl_qm_create',
    detail: receiptEvent
  };

  const serializedEvent = JSON.stringify(event);
  const response = await postQM(serializedEvent);

  return response;
}

export async function updateManifestAsnsCoupa(asnUpdateEvent: UpdateAsnProps): Promise<PostAsns> {
  const event: BaseEvent<'ftl_manifest_completed', UpdateAsnProps> = {
    id: crypto.randomUUID(),
    source: 'ftl',
    'detail-type': 'ftl_manifest_completed',
    detail: asnUpdateEvent
  };

  const serializedEvent = JSON.stringify(event);
  const response = await postManifestAsns(serializedEvent);

  return response;
}

export function convertReceiptEventToCoupaReceiptEvent(
  receiptEvent: ReceiptEventPackageProps,
  name: string,
  location: string | undefined,
  asn: string,
  isDirectReceipt?: boolean
) {
  const coupaReceiptEvent: CoupaReceiptEvent = {
    ...receiptEvent,
    usersName: name,
    usersLocation: location ?? 'Unknown Location',
    internalAsn: asn,
    isDirectReceipt: isDirectReceipt
  };
  return coupaReceiptEvent;
}
