import { Button, ButtonProps } from '@mui/material';
import { styled } from '@mui/material/styles';

export const LightInfoButton = styled(Button)<ButtonProps>(({ theme }) => ({
  backgroundColor: theme.palette.info.light,
  color: theme.palette.primary.main,
  fontWeight: 'bold',
  '&:hover': {
    backgroundColor: theme.palette.info.main,
    color: theme.palette.primary.contrastText
  }
}));

export default LightInfoButton;
