import { DataStore } from 'aws-amplify';
import { WorkflowStatus } from '../models';

export interface WorkflowStatusProps {
  id: string;
  workflowStatusId: string;
  name: string;
}

export interface WorkflowStatusDataProps {
  create: (data: WorkflowStatusProps) => Promise<WorkflowStatusProps | undefined>;
  getArray: () => Promise<WorkflowStatusProps[] | undefined>;
}

export const workflowStatusData = () => {
  async function create(data: WorkflowStatusProps): Promise<WorkflowStatusProps | undefined> {
    const created = await DataStore.save(
      new WorkflowStatus({
        id: data.id,
        workflowStatusId: data.workflowStatusId,
        name: data.name
      })
    );

    if (created) {
      return convertToProps(created);
    }

    return undefined;
  }

  async function getArray(): Promise<WorkflowStatusProps[] | undefined> {
    const results = await DataStore.query(WorkflowStatus);

    if (results) {
      return results.map((r) => convertToProps(r));
    }

    return undefined;
  }

  function convertToProps(data: WorkflowStatus) {
    const workflowStatusProps: WorkflowStatusProps = {
      id: data.id,
      workflowStatusId: data.workflowStatusId,
      name: data.name
    };
    return workflowStatusProps;
  }

  const returned: WorkflowStatusDataProps = {
    create,
    getArray
  };

  return returned;
};
