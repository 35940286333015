import { useState, useEffect } from 'react';
import { DataStore } from 'aws-amplify';
import { SSCC } from '../models';
import { SSCCProps, convertSsccToProps } from '../datastore/ssccs';

export default function useObserveSscc(ssccId: string) {
  const [sscc, setSscc] = useState<SSCCProps>();

  useEffect(() => {
    async function fetchInitialSSCC() {
      try {
        const initialSSCC = await DataStore.query(SSCC, ssccId);
        if (!initialSSCC) return;
        const convertedSscc = convertSsccToProps(initialSSCC);
        setSscc(convertedSscc);
      } catch (error) {
        console.error('Error fetching initial SSCC:', error);
      }
    }

    fetchInitialSSCC();

    const subscription = DataStore.observe(SSCC, ssccId).subscribe({
      next: (update) => {
        console.log('SSCC update:', update);
        const convertedSscc = convertSsccToProps(update.element);
        setSscc(convertedSscc);
      },
      error: (error) => console.error('Error observing SSCC:', error)
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [ssccId]);

  return sscc;
}
