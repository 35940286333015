import { Dialog, IconButton, Stack, Typography, TextField, FormControlLabel, Switch } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import StyledButton from '../../../../components/buttons/StyledButton';
import { SubmitHandler, useForm, Controller } from 'react-hook-form';
import { useEffect } from 'react';
import { PrinterProps } from '../../../../datastore/printers';

interface PrinterCreateFormProps {
  open: boolean;
  onSubmit: (data: PrinterProps) => void;
  onClose: () => void;
}

export default function CreatePrinterForm({ open, onSubmit, onClose }: PrinterCreateFormProps) {
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors }
  } = useForm<PrinterProps>({
    defaultValues: {
      name: '',
      printerIP: '',
      printerPort: '9100',
      isDisabled: false
    }
  });

  useEffect(() => {
    reset();
  }, [open, reset]);

  const onSubmitForm: SubmitHandler<PrinterProps> = (data) => onSubmit(data);

  return (
    <Dialog open={open} maxWidth="xs" fullWidth>
      <form onSubmit={handleSubmit(onSubmitForm)}>
        <Stack spacing={3} sx={{ padding: '1em 2em' }}>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Typography variant="h4" fontWeight="bold">
              Create Printer
            </Typography>
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Stack>
          <Controller
            name="name"
            control={control}
            render={({ field }) => <TextField label="Name" fullWidth {...field} error={!!errors.name} required />}
          />
          <Controller
            name="printerIP"
            control={control}
            render={({ field }) => <TextField label="IP" fullWidth {...field} error={!!errors.printerIP} required />}
          />
          <Controller
            name="printerPort"
            control={control}
            render={({ field }) => (
              <TextField label="Port" fullWidth {...field} error={!!errors.printerPort} required />
            )}
          />
          <Controller
            name="isDisabled"
            control={control}
            render={({ field }) => (
              <FormControlLabel control={<Switch checked={field.value} {...field} />} label="Disabled" />
            )}
          />

          <StyledButton variant="contained" type="submit">
            Create Printer
          </StyledButton>
        </Stack>
      </form>
    </Dialog>
  );
}
