import { useState, useEffect } from 'react';
import { DataStore } from 'aws-amplify';
import { TripSheet } from '../models';
import { TripSheetProps, convertTripSheetToProps } from '../datastore/tripSheets';

export default function useObserveTripSheets() {
  const [tripSheets, setTripSheets] = useState<TripSheetProps[]>([]);

  useEffect(() => {
    const retrieveData = async () => {
      const entities = await DataStore.query(TripSheet);
      console.debug('useObserveTripSheets:', entities.length, 'tripsheets fetched');
      const validTripSheets = entities
        .map((t) => convertTripSheetToProps(t))
        .filter((t): t is TripSheetProps => t !== undefined);

      setTripSheets(validTripSheets);
    };
    const subscription = DataStore.observe(TripSheet).subscribe(retrieveData);
    retrieveData();
    return () => {
      subscription.unsubscribe();
    };
  }, []);

  return tripSheets;
}
