// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';

const {
  SSCC,
  UserDetails,
  Location,
  Printer,
  PackageType,
  QMReason,
  WorkflowStatus,
  QMIssue,
  RemoveItemSSCCHistory,
  ReceiptEventPackageHistory,
  AdminActionHistory,
  TripSheet,
  TransportBookingEvent,
  ReceiptEventSite,
  ReceiptEventPackage,
  ReceiptEventPackageItem,
  ManifestCarrier,
  ManifestAddress,
  ManifestLeg,
  Manifest,
  ManifestSSCC,
  ManifestPackage,
  ManifestInvLineItem,
  TransportBookingLocation
} = initSchema(schema);

export {
  SSCC,
  UserDetails,
  Location,
  Printer,
  PackageType,
  QMReason,
  WorkflowStatus,
  QMIssue,
  RemoveItemSSCCHistory,
  ReceiptEventPackageHistory,
  AdminActionHistory,
  TripSheet,
  TransportBookingEvent,
  ReceiptEventSite,
  ReceiptEventPackage,
  ReceiptEventPackageItem,
  ManifestCarrier,
  ManifestAddress,
  ManifestLeg,
  Manifest,
  ManifestSSCC,
  ManifestPackage,
  ManifestInvLineItem,
  TransportBookingLocation
};
