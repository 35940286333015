import { API, graphqlOperation } from 'aws-amplify';
import { GraphQLResult } from '@aws-amplify/api-graphql';
import { printItem } from '../queries/queries';
import { ReceiptHeader } from '../common/types';

type PrintItemResponse = {
  printItem: string;
};

type PrintItemResult = {
  payload?: ReceiptHeader;
  error: string;
};

export async function postPrintItem(body: string): Promise<PrintItemResult> {
  const genericResponseError = {
    payload: undefined,
    error: 'An error occurred while printing items.'
  };

  try {
    const result = (await API.graphql(graphqlOperation(printItem, { body }))) as GraphQLResult<PrintItemResponse>;

    if (result.data && result.data.printItem) {
      const printResult = JSON.parse(result.data.printItem);
      if (printResult.statusCode === 200) {
        return { payload: printResult.body, error: '' };
      }
    }
    return genericResponseError;
  } catch (e) {
    console.error('Error posting print: ', e);
    return genericResponseError;
  }
}
