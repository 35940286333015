import { API, graphqlOperation } from 'aws-amplify';
import { GraphQLResult } from '@aws-amplify/api-graphql';
import { postItem } from '../queries/queries';
import { ReceiptHeader } from '../common/types';

type PostReceipt = {
  payload?: ReceiptHeader;
  error: string;
};

type CompleteReceiptResponse = {
  postItem: string;
};

export async function postReceipt(body: string): Promise<PostReceipt> {
  const genericResponseError = {
    payload: undefined,
    error: 'An error occurred while receipting items.'
  };
  try {
    const result = (await API.graphql(graphqlOperation(postItem, { body }))) as GraphQLResult<CompleteReceiptResponse>;

    if (result.data && result.data.postItem) {
      const poResult = JSON.parse(result.data.postItem);
      if (poResult.statusCode === 200) {
        return { payload: poResult.body, error: '' };
      }
    }
    return genericResponseError;
  } catch (e) {
    console.error('Error posting receipt: ', e);
    return genericResponseError;
  }
}
