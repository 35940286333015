import { LocationProps } from '../../datastore/locations';

export function isLocationMatch(
  poLocation: LocationProps | undefined,
  ssccDestinationLocation: LocationProps | undefined
) {
  // Direct match (either parent to parent, child to its parent, or child to child)
  if (poLocation?.id === ssccDestinationLocation?.id) {
    return true;
  }

  // Fetch the parent location if the PO location is a child
  if (poLocation?.parentLocationId) {
    // Check if PO's parent is the SSCC destination (child receipts into its parent)
    if (poLocation.parentLocationId === ssccDestinationLocation?.id) {
      return true;
    }
  }

  // Fetch the parent location if the SSCC destination location is a child
  if (ssccDestinationLocation?.parentLocationId) {
    // Check if SSCC's parent is the PO location (parent receipts into a child)
    if (ssccDestinationLocation.parentLocationId === poLocation?.id) {
      return false;
    }
  }

  // If reaching here then:
  // - A parent receipts into a random child (not its direct child)
  // - A child receipts into a random parent (not its own parent)
  return false;
}
