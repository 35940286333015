export const schema = {
  models: {
    SSCC: {
      name: 'SSCC',
      fields: {
        id: {
          name: 'id',
          isArray: false,
          type: 'ID',
          isRequired: true,
          attributes: []
        },
        nickname: {
          name: 'nickname',
          isArray: false,
          type: 'String',
          isRequired: false,
          attributes: []
        },
        originLocationId: {
          name: 'originLocationId',
          isArray: false,
          type: 'ID',
          isRequired: true,
          attributes: []
        },
        originLocation: {
          name: 'originLocation',
          isArray: false,
          type: {
            model: 'Location'
          },
          isRequired: false,
          attributes: [],
          association: {
            connectionType: 'BELONGS_TO',
            targetNames: ['originLocationId']
          }
        },
        destinationLocationId: {
          name: 'destinationLocationId',
          isArray: false,
          type: 'ID',
          isRequired: true,
          attributes: []
        },
        destinationLocation: {
          name: 'destinationLocation',
          isArray: false,
          type: {
            model: 'Location'
          },
          isRequired: false,
          attributes: [],
          association: {
            connectionType: 'BELONGS_TO',
            targetNames: ['destinationLocationId']
          }
        },
        lock: {
          name: 'lock',
          isArray: false,
          type: 'Boolean',
          isRequired: false,
          attributes: []
        },
        status: {
          name: 'status',
          isArray: false,
          type: 'String',
          isRequired: false,
          attributes: []
        },
        isLargeItem: {
          name: 'isLargeItem',
          isArray: false,
          type: 'Boolean',
          isRequired: false,
          attributes: []
        },
        isTransportBooking: {
          name: 'isTransportBooking',
          isArray: false,
          type: 'Boolean',
          isRequired: false,
          attributes: []
        },
        transportBookingDestination: {
          name: 'transportBookingDestination',
          isArray: false,
          type: {
            nonModel: 'TransportBookingLocation'
          },
          isRequired: false,
          attributes: []
        },
        bustleServiceType: {
          name: 'bustleServiceType',
          isArray: false,
          type: 'String',
          isRequired: false,
          attributes: []
        },
        ssccType: {
          name: 'ssccType',
          isArray: false,
          type: 'String',
          isRequired: false,
          attributes: []
        },
        height: {
          name: 'height',
          isArray: false,
          type: 'String',
          isRequired: false,
          attributes: []
        },
        width: {
          name: 'width',
          isArray: false,
          type: 'String',
          isRequired: false,
          attributes: []
        },
        depth: {
          name: 'depth',
          isArray: false,
          type: 'String',
          isRequired: false,
          attributes: []
        },
        weight: {
          name: 'weight',
          isArray: false,
          type: 'String',
          isRequired: false,
          attributes: []
        },
        estimatedDelivery: {
          name: 'estimatedDelivery',
          isArray: false,
          type: 'AWSDateTime',
          isRequired: false,
          attributes: []
        },
        open: {
          name: 'open',
          isArray: false,
          type: 'AWSDateTime',
          isRequired: false,
          attributes: []
        },
        staged: {
          name: 'staged',
          isArray: false,
          type: 'AWSDateTime',
          isRequired: false,
          attributes: []
        },
        manifested: {
          name: 'manifested',
          isArray: false,
          type: 'AWSDateTime',
          isRequired: false,
          attributes: []
        },
        inTransit: {
          name: 'inTransit',
          isArray: false,
          type: 'AWSDateTime',
          isRequired: false,
          attributes: []
        },
        approaching: {
          name: 'approaching',
          isArray: false,
          type: 'AWSDateTime',
          isRequired: false,
          attributes: []
        },
        delivered: {
          name: 'delivered',
          isArray: false,
          type: 'AWSDateTime',
          isRequired: false,
          attributes: []
        },
        createdAt: {
          name: 'createdAt',
          isArray: false,
          type: 'AWSDateTime',
          isRequired: false,
          attributes: []
        },
        updatedAt: {
          name: 'updatedAt',
          isArray: false,
          type: 'AWSDateTime',
          isRequired: false,
          attributes: []
        },
        stagingLocation: {
          name: 'stagingLocation',
          isArray: false,
          type: 'String',
          isRequired: false,
          attributes: []
        },
        receiptedEvents: {
          name: 'receiptedEvents',
          isArray: true,
          type: {
            nonModel: 'ReceiptEventSite'
          },
          isRequired: false,
          attributes: [],
          isArrayNullable: true
        },
        locationSsccsId: {
          name: 'locationSsccsId',
          isArray: false,
          type: 'ID',
          isRequired: false,
          attributes: []
        }
      },
      syncable: true,
      pluralName: 'SSCCS',
      attributes: [
        {
          type: 'model',
          properties: {}
        },
        {
          type: 'key',
          properties: {
            fields: ['id']
          }
        },
        {
          type: 'key',
          properties: {
            name: 'originLocationId',
            queryField: 'ssccsByOriginLocationId',
            fields: ['originLocationId']
          }
        },
        {
          type: 'key',
          properties: {
            name: 'destinationLocationId',
            queryField: 'ssccsByDestinationLocationId',
            fields: ['destinationLocationId']
          }
        },
        {
          type: 'auth',
          properties: {
            rules: [
              {
                allow: 'private',
                operations: ['create', 'update', 'delete', 'read']
              },
              {
                allow: 'public',
                provider: 'apiKey',
                operations: ['create', 'update', 'delete', 'read']
              }
            ]
          }
        }
      ]
    },
    UserDetails: {
      name: 'UserDetails',
      fields: {
        id: {
          name: 'id',
          isArray: false,
          type: 'ID',
          isRequired: true,
          attributes: []
        },
        cognitoID: {
          name: 'cognitoID',
          isArray: false,
          type: 'String',
          isRequired: true,
          attributes: []
        },
        printer: {
          name: 'printer',
          isArray: false,
          type: {
            model: 'Printer'
          },
          isRequired: false,
          attributes: [],
          association: {
            connectionType: 'HAS_ONE',
            associatedWith: ['id'],
            targetNames: ['userDetailsPrinterId']
          }
        },
        location: {
          name: 'location',
          isArray: false,
          type: {
            model: 'Location'
          },
          isRequired: false,
          attributes: [],
          association: {
            connectionType: 'HAS_ONE',
            associatedWith: ['id'],
            targetNames: ['userDetailsLocationId']
          }
        },
        createdAt: {
          name: 'createdAt',
          isArray: false,
          type: 'AWSDateTime',
          isRequired: false,
          attributes: [],
          isReadOnly: true
        },
        updatedAt: {
          name: 'updatedAt',
          isArray: false,
          type: 'AWSDateTime',
          isRequired: false,
          attributes: [],
          isReadOnly: true
        },
        userDetailsPrinterId: {
          name: 'userDetailsPrinterId',
          isArray: false,
          type: 'ID',
          isRequired: false,
          attributes: []
        },
        userDetailsLocationId: {
          name: 'userDetailsLocationId',
          isArray: false,
          type: 'ID',
          isRequired: false,
          attributes: []
        }
      },
      syncable: true,
      pluralName: 'UserDetails',
      attributes: [
        {
          type: 'model',
          properties: {}
        },
        {
          type: 'key',
          properties: {
            fields: ['id']
          }
        },
        {
          type: 'auth',
          properties: {
            rules: [
              {
                provider: 'userPools',
                ownerField: 'owner',
                allow: 'owner',
                identityClaim: 'cognito:username',
                operations: ['create', 'update', 'delete', 'read']
              }
            ]
          }
        }
      ]
    },
    Location: {
      name: 'Location',
      fields: {
        id: {
          name: 'id',
          isArray: false,
          type: 'ID',
          isRequired: true,
          attributes: []
        },
        userDetails: {
          name: 'userDetails',
          isArray: false,
          type: {
            model: 'UserDetails'
          },
          isRequired: false,
          attributes: [],
          association: {
            connectionType: 'BELONGS_TO',
            targetNames: ['locationUserDetailsId']
          }
        },
        type: {
          name: 'type',
          isArray: false,
          type: 'String',
          isRequired: false,
          attributes: []
        },
        name: {
          name: 'name',
          isArray: false,
          type: 'String',
          isRequired: false,
          attributes: []
        },
        alias: {
          name: 'alias',
          isArray: false,
          type: 'String',
          isRequired: false,
          attributes: []
        },
        address: {
          name: 'address',
          isArray: false,
          type: 'String',
          isRequired: false,
          attributes: []
        },
        suburb: {
          name: 'suburb',
          isArray: false,
          type: 'String',
          isRequired: false,
          attributes: []
        },
        state: {
          name: 'state',
          isArray: false,
          type: 'String',
          isRequired: false,
          attributes: []
        },
        postcode: {
          name: 'postcode',
          isArray: false,
          type: 'String',
          isRequired: false,
          attributes: []
        },
        contactName: {
          name: 'contactName',
          isArray: false,
          type: 'String',
          isRequired: false,
          attributes: []
        },
        contactPhone: {
          name: 'contactPhone',
          isArray: false,
          type: 'String',
          isRequired: false,
          attributes: []
        },
        isDisabled: {
          name: 'isDisabled',
          isArray: false,
          type: 'Boolean',
          isRequired: false,
          attributes: []
        },
        isAutoReceipt: {
          name: 'isAutoReceipt',
          isArray: false,
          type: 'Boolean',
          isRequired: false,
          attributes: []
        },
        deliveryDays: {
          name: 'deliveryDays',
          isArray: false,
          type: 'Int',
          isRequired: false,
          attributes: []
        },
        originSsccs: {
          name: 'originSsccs',
          isArray: true,
          type: {
            model: 'SSCC'
          },
          isRequired: false,
          attributes: [],
          isArrayNullable: true,
          association: {
            connectionType: 'HAS_MANY',
            associatedWith: ['originLocation']
          }
        },
        destinationSsccs: {
          name: 'destinationSsccs',
          isArray: true,
          type: {
            model: 'SSCC'
          },
          isRequired: false,
          attributes: [],
          isArrayNullable: true,
          association: {
            connectionType: 'HAS_MANY',
            associatedWith: ['destinationLocation']
          }
        },
        ssccs: {
          name: 'ssccs',
          isArray: true,
          type: {
            model: 'SSCC'
          },
          isRequired: false,
          attributes: [],
          isArrayNullable: true,
          association: {
            connectionType: 'HAS_MANY',
            associatedWith: ['locationSsccsId']
          }
        },
        subLocations: {
          name: 'subLocations',
          isArray: true,
          type: {
            model: 'Location'
          },
          isRequired: false,
          attributes: [],
          isArrayNullable: true,
          association: {
            connectionType: 'HAS_MANY',
            associatedWith: ['parentLocationId']
          }
        },
        parentLocationId: {
          name: 'parentLocationId',
          isArray: false,
          type: 'ID',
          isRequired: false,
          attributes: []
        },
        createdAt: {
          name: 'createdAt',
          isArray: false,
          type: 'AWSDateTime',
          isRequired: false,
          attributes: [],
          isReadOnly: true
        },
        updatedAt: {
          name: 'updatedAt',
          isArray: false,
          type: 'AWSDateTime',
          isRequired: false,
          attributes: [],
          isReadOnly: true
        },
        locationUserDetailsId: {
          name: 'locationUserDetailsId',
          isArray: false,
          type: 'ID',
          isRequired: false,
          attributes: []
        }
      },
      syncable: true,
      pluralName: 'Locations',
      attributes: [
        {
          type: 'model',
          properties: {}
        },
        {
          type: 'key',
          properties: {
            fields: ['id']
          }
        },
        {
          type: 'key',
          properties: {
            name: 'parentLocationId',
            fields: ['parentLocationId']
          }
        },
        {
          type: 'auth',
          properties: {
            rules: [
              {
                allow: 'private',
                operations: ['create', 'update', 'delete', 'read']
              },
              {
                allow: 'public',
                provider: 'apiKey',
                operations: ['create', 'update', 'delete', 'read']
              }
            ]
          }
        }
      ]
    },
    Printer: {
      name: 'Printer',
      fields: {
        id: {
          name: 'id',
          isArray: false,
          type: 'ID',
          isRequired: true,
          attributes: []
        },
        name: {
          name: 'name',
          isArray: false,
          type: 'String',
          isRequired: false,
          attributes: []
        },
        printerIP: {
          name: 'printerIP',
          isArray: false,
          type: 'String',
          isRequired: false,
          attributes: []
        },
        printerPort: {
          name: 'printerPort',
          isArray: false,
          type: 'String',
          isRequired: false,
          attributes: []
        },
        isDisabled: {
          name: 'isDisabled',
          isArray: false,
          type: 'Boolean',
          isRequired: false,
          attributes: []
        },
        createdAt: {
          name: 'createdAt',
          isArray: false,
          type: 'AWSDateTime',
          isRequired: false,
          attributes: [],
          isReadOnly: true
        },
        updatedAt: {
          name: 'updatedAt',
          isArray: false,
          type: 'AWSDateTime',
          isRequired: false,
          attributes: [],
          isReadOnly: true
        }
      },
      syncable: true,
      pluralName: 'Printers',
      attributes: [
        {
          type: 'model',
          properties: {}
        },
        {
          type: 'key',
          properties: {
            fields: ['id']
          }
        },
        {
          type: 'auth',
          properties: {
            rules: [
              {
                allow: 'private',
                operations: ['create', 'update', 'delete', 'read']
              }
            ]
          }
        }
      ]
    },
    PackageType: {
      name: 'PackageType',
      fields: {
        id: {
          name: 'id',
          isArray: false,
          type: 'ID',
          isRequired: true,
          attributes: []
        },
        name: {
          name: 'name',
          isArray: false,
          type: 'String',
          isRequired: true,
          attributes: []
        },
        createdAt: {
          name: 'createdAt',
          isArray: false,
          type: 'AWSDateTime',
          isRequired: false,
          attributes: [],
          isReadOnly: true
        },
        updatedAt: {
          name: 'updatedAt',
          isArray: false,
          type: 'AWSDateTime',
          isRequired: false,
          attributes: [],
          isReadOnly: true
        }
      },
      syncable: true,
      pluralName: 'PackageTypes',
      attributes: [
        {
          type: 'model',
          properties: {}
        },
        {
          type: 'key',
          properties: {
            fields: ['id']
          }
        },
        {
          type: 'auth',
          properties: {
            rules: [
              {
                allow: 'private',
                operations: ['create', 'update', 'delete', 'read']
              }
            ]
          }
        }
      ]
    },
    QMReason: {
      name: 'QMReason',
      fields: {
        id: {
          name: 'id',
          isArray: false,
          type: 'ID',
          isRequired: true,
          attributes: []
        },
        reasonId: {
          name: 'reasonId',
          isArray: false,
          type: 'String',
          isRequired: true,
          attributes: []
        },
        name: {
          name: 'name',
          isArray: false,
          type: 'String',
          isRequired: true,
          attributes: []
        },
        createdAt: {
          name: 'createdAt',
          isArray: false,
          type: 'AWSDateTime',
          isRequired: false,
          attributes: [],
          isReadOnly: true
        },
        updatedAt: {
          name: 'updatedAt',
          isArray: false,
          type: 'AWSDateTime',
          isRequired: false,
          attributes: [],
          isReadOnly: true
        }
      },
      syncable: true,
      pluralName: 'QMReasons',
      attributes: [
        {
          type: 'model',
          properties: {}
        },
        {
          type: 'key',
          properties: {
            fields: ['id']
          }
        },
        {
          type: 'auth',
          properties: {
            rules: [
              {
                allow: 'private',
                operations: ['create', 'update', 'delete', 'read']
              }
            ]
          }
        }
      ]
    },
    WorkflowStatus: {
      name: 'WorkflowStatus',
      fields: {
        id: {
          name: 'id',
          isArray: false,
          type: 'ID',
          isRequired: true,
          attributes: []
        },
        workflowStatusId: {
          name: 'workflowStatusId',
          isArray: false,
          type: 'String',
          isRequired: true,
          attributes: []
        },
        name: {
          name: 'name',
          isArray: false,
          type: 'String',
          isRequired: true,
          attributes: []
        },
        createdAt: {
          name: 'createdAt',
          isArray: false,
          type: 'AWSDateTime',
          isRequired: false,
          attributes: [],
          isReadOnly: true
        },
        updatedAt: {
          name: 'updatedAt',
          isArray: false,
          type: 'AWSDateTime',
          isRequired: false,
          attributes: [],
          isReadOnly: true
        }
      },
      syncable: true,
      pluralName: 'WorkflowStatuses',
      attributes: [
        {
          type: 'model',
          properties: {}
        },
        {
          type: 'key',
          properties: {
            fields: ['id']
          }
        },
        {
          type: 'auth',
          properties: {
            rules: [
              {
                allow: 'private',
                operations: ['create', 'update', 'delete', 'read']
              }
            ]
          }
        }
      ]
    },
    QMIssue: {
      name: 'QMIssue',
      fields: {
        id: {
          name: 'id',
          isArray: false,
          type: 'ID',
          isRequired: true,
          attributes: []
        },
        issueId: {
          name: 'issueId',
          isArray: false,
          type: 'String',
          isRequired: true,
          attributes: []
        },
        name: {
          name: 'name',
          isArray: false,
          type: 'String',
          isRequired: true,
          attributes: []
        },
        createdAt: {
          name: 'createdAt',
          isArray: false,
          type: 'AWSDateTime',
          isRequired: false,
          attributes: [],
          isReadOnly: true
        },
        updatedAt: {
          name: 'updatedAt',
          isArray: false,
          type: 'AWSDateTime',
          isRequired: false,
          attributes: [],
          isReadOnly: true
        }
      },
      syncable: true,
      pluralName: 'QMIssues',
      attributes: [
        {
          type: 'model',
          properties: {}
        },
        {
          type: 'key',
          properties: {
            fields: ['id']
          }
        },
        {
          type: 'auth',
          properties: {
            rules: [
              {
                allow: 'private',
                operations: ['create', 'update', 'delete', 'read']
              }
            ]
          }
        }
      ]
    },
    RemoveItemSSCCHistory: {
      name: 'RemoveItemSSCCHistory',
      fields: {
        id: {
          name: 'id',
          isArray: false,
          type: 'ID',
          isRequired: true,
          attributes: []
        },
        userEmail: {
          name: 'userEmail',
          isArray: false,
          type: 'String',
          isRequired: false,
          attributes: []
        },
        reason: {
          name: 'reason',
          isArray: false,
          type: 'String',
          isRequired: false,
          attributes: []
        },
        originSSCC: {
          name: 'originSSCC',
          isArray: false,
          type: 'String',
          isRequired: false,
          attributes: []
        },
        destinationSSCC: {
          name: 'destinationSSCC',
          isArray: false,
          type: 'String',
          isRequired: false,
          attributes: []
        },
        asn: {
          name: 'asn',
          isArray: false,
          type: 'String',
          isRequired: false,
          attributes: []
        },
        actionTime: {
          name: 'actionTime',
          isArray: false,
          type: 'AWSDateTime',
          isRequired: false,
          attributes: []
        },
        createdAt: {
          name: 'createdAt',
          isArray: false,
          type: 'AWSDateTime',
          isRequired: false,
          attributes: [],
          isReadOnly: true
        },
        updatedAt: {
          name: 'updatedAt',
          isArray: false,
          type: 'AWSDateTime',
          isRequired: false,
          attributes: [],
          isReadOnly: true
        }
      },
      syncable: true,
      pluralName: 'RemoveItemSSCCHistories',
      attributes: [
        {
          type: 'model',
          properties: {}
        },
        {
          type: 'key',
          properties: {
            fields: ['id']
          }
        },
        {
          type: 'auth',
          properties: {
            rules: [
              {
                allow: 'private',
                operations: ['create', 'update', 'delete', 'read']
              }
            ]
          }
        }
      ]
    },
    ReceiptEventPackageHistory: {
      name: 'ReceiptEventPackageHistory',
      fields: {
        id: {
          name: 'id',
          isArray: false,
          type: 'ID',
          isRequired: true,
          attributes: []
        },
        asn: {
          name: 'asn',
          isArray: false,
          type: 'String',
          isRequired: false,
          attributes: []
        },
        receiptType: {
          name: 'receiptType',
          isArray: false,
          type: 'String',
          isRequired: false,
          attributes: []
        },
        associatedSSCC: {
          name: 'associatedSSCC',
          isArray: false,
          type: 'String',
          isRequired: false,
          attributes: []
        },
        receiptedBy: {
          name: 'receiptedBy',
          isArray: false,
          type: 'String',
          isRequired: false,
          attributes: []
        },
        actionTime: {
          name: 'actionTime',
          isArray: false,
          type: 'AWSDateTime',
          isRequired: false,
          attributes: []
        },
        createdAt: {
          name: 'createdAt',
          isArray: false,
          type: 'AWSDateTime',
          isRequired: false,
          attributes: [],
          isReadOnly: true
        },
        updatedAt: {
          name: 'updatedAt',
          isArray: false,
          type: 'AWSDateTime',
          isRequired: false,
          attributes: [],
          isReadOnly: true
        }
      },
      syncable: true,
      pluralName: 'ReceiptEventPackageHistories',
      attributes: [
        {
          type: 'model',
          properties: {}
        },
        {
          type: 'key',
          properties: {
            fields: ['id']
          }
        },
        {
          type: 'auth',
          properties: {
            rules: [
              {
                allow: 'private',
                operations: ['create', 'update', 'delete', 'read']
              }
            ]
          }
        }
      ]
    },
    AdminActionHistory: {
      name: 'AdminActionHistory',
      fields: {
        id: {
          name: 'id',
          isArray: false,
          type: 'ID',
          isRequired: true,
          attributes: []
        },
        actionType: {
          name: 'actionType',
          isArray: false,
          type: 'String',
          isRequired: true,
          attributes: []
        },
        beforeAction: {
          name: 'beforeAction',
          isArray: false,
          type: 'String',
          isRequired: false,
          attributes: []
        },
        afterAction: {
          name: 'afterAction',
          isArray: false,
          type: 'String',
          isRequired: true,
          attributes: []
        },
        actionBy: {
          name: 'actionBy',
          isArray: false,
          type: 'String',
          isRequired: true,
          attributes: []
        },
        actionTime: {
          name: 'actionTime',
          isArray: false,
          type: 'AWSDateTime',
          isRequired: true,
          attributes: []
        },
        createdAt: {
          name: 'createdAt',
          isArray: false,
          type: 'AWSDateTime',
          isRequired: false,
          attributes: [],
          isReadOnly: true
        },
        updatedAt: {
          name: 'updatedAt',
          isArray: false,
          type: 'AWSDateTime',
          isRequired: false,
          attributes: [],
          isReadOnly: true
        }
      },
      syncable: true,
      pluralName: 'AdminActionHistories',
      attributes: [
        {
          type: 'model',
          properties: {}
        },
        {
          type: 'key',
          properties: {
            fields: ['id']
          }
        },
        {
          type: 'auth',
          properties: {
            rules: [
              {
                allow: 'private',
                operations: ['create', 'update', 'delete', 'read']
              }
            ]
          }
        }
      ]
    },
    TripSheet: {
      name: 'TripSheet',
      fields: {
        id: {
          name: 'id',
          isArray: false,
          type: 'ID',
          isRequired: true,
          attributes: []
        },
        carrier: {
          name: 'carrier',
          isArray: false,
          type: {
            nonModel: 'ManifestCarrier'
          },
          isRequired: false,
          attributes: []
        },
        reference: {
          name: 'reference',
          isArray: false,
          type: 'String',
          isRequired: false,
          attributes: []
        },
        createdAt: {
          name: 'createdAt',
          isArray: false,
          type: 'AWSDateTime',
          isRequired: false,
          attributes: []
        },
        updatedAt: {
          name: 'updatedAt',
          isArray: false,
          type: 'AWSDateTime',
          isRequired: false,
          attributes: []
        },
        legs: {
          name: 'legs',
          isArray: true,
          type: {
            nonModel: 'ManifestLeg'
          },
          isRequired: false,
          attributes: [],
          isArrayNullable: true
        }
      },
      syncable: true,
      pluralName: 'TripSheets',
      attributes: [
        {
          type: 'model',
          properties: {}
        },
        {
          type: 'key',
          properties: {
            fields: ['id']
          }
        },
        {
          type: 'auth',
          properties: {
            rules: [
              {
                allow: 'private',
                operations: ['create', 'update', 'delete', 'read']
              },
              {
                allow: 'public',
                provider: 'apiKey',
                operations: ['create', 'update', 'delete', 'read']
              }
            ]
          }
        }
      ]
    },
    TransportBookingEvent: {
      name: 'TransportBookingEvent',
      fields: {
        id: {
          name: 'id',
          isArray: false,
          type: 'ID',
          isRequired: true,
          attributes: []
        },
        poNumber: {
          name: 'poNumber',
          isArray: false,
          type: 'Int',
          isRequired: false,
          attributes: []
        },
        destinationLocation: {
          name: 'destinationLocation',
          isArray: false,
          type: {
            nonModel: 'TransportBookingLocation'
          },
          isRequired: false,
          attributes: []
        },
        originLocation: {
          name: 'originLocation',
          isArray: false,
          type: {
            nonModel: 'TransportBookingLocation'
          },
          isRequired: false,
          attributes: []
        },
        additionalField1: {
          name: 'additionalField1',
          isArray: false,
          type: 'String',
          isRequired: false,
          attributes: []
        },
        additionalField2: {
          name: 'additionalField2',
          isArray: false,
          type: 'String',
          isRequired: false,
          attributes: []
        },
        additionalField3: {
          name: 'additionalField3',
          isArray: false,
          type: 'String',
          isRequired: false,
          attributes: []
        },
        notesContent: {
          name: 'notesContent',
          isArray: false,
          type: 'String',
          isRequired: false,
          attributes: []
        },
        serviceType: {
          name: 'serviceType',
          isArray: false,
          type: 'String',
          isRequired: false,
          attributes: []
        },
        createdAt: {
          name: 'createdAt',
          isArray: false,
          type: 'AWSDateTime',
          isRequired: false,
          attributes: [],
          isReadOnly: true
        },
        updatedAt: {
          name: 'updatedAt',
          isArray: false,
          type: 'AWSDateTime',
          isRequired: false,
          attributes: [],
          isReadOnly: true
        }
      },
      syncable: true,
      pluralName: 'TransportBookingEvents',
      attributes: [
        {
          type: 'model',
          properties: {}
        },
        {
          type: 'key',
          properties: {
            fields: ['id']
          }
        },
        {
          type: 'auth',
          properties: {
            rules: [
              {
                allow: 'private',
                operations: ['create', 'update', 'delete', 'read']
              },
              {
                allow: 'public',
                provider: 'apiKey',
                operations: ['create', 'update', 'delete', 'read']
              }
            ]
          }
        }
      ]
    }
  },
  enums: {},
  nonModels: {
    ReceiptEventSite: {
      name: 'ReceiptEventSite',
      fields: {
        name: {
          name: 'name',
          isArray: false,
          type: 'String',
          isRequired: false,
          attributes: []
        },
        site: {
          name: 'site',
          isArray: false,
          type: 'String',
          isRequired: false,
          attributes: []
        },
        packages: {
          name: 'packages',
          isArray: true,
          type: {
            nonModel: 'ReceiptEventPackage'
          },
          isRequired: false,
          attributes: [],
          isArrayNullable: true
        }
      }
    },
    ReceiptEventPackage: {
      name: 'ReceiptEventPackage',
      fields: {
        id: {
          name: 'id',
          isArray: false,
          type: 'ID',
          isRequired: true,
          attributes: []
        },
        status: {
          name: 'status',
          isArray: false,
          type: 'String',
          isRequired: false,
          attributes: []
        },
        statusDate: {
          name: 'statusDate',
          isArray: false,
          type: 'AWSDateTime',
          isRequired: false,
          attributes: []
        },
        packageType: {
          name: 'packageType',
          isArray: false,
          type: 'String',
          isRequired: false,
          attributes: []
        },
        quantity: {
          name: 'quantity',
          isArray: false,
          type: 'Int',
          isRequired: false,
          attributes: []
        },
        po: {
          name: 'po',
          isArray: false,
          type: 'String',
          isRequired: false,
          attributes: []
        },
        shipToAttention: {
          name: 'shipToAttention',
          isArray: false,
          type: 'String',
          isRequired: false,
          attributes: []
        },
        companyName: {
          name: 'companyName',
          isArray: false,
          type: 'String',
          isRequired: false,
          attributes: []
        },
        asn: {
          name: 'asn',
          isArray: false,
          type: 'String',
          isRequired: false,
          attributes: []
        },
        jobId: {
          name: 'jobId',
          isArray: false,
          type: 'String',
          isRequired: false,
          attributes: []
        },
        assetNumber: {
          name: 'assetNumber',
          isArray: false,
          type: 'String',
          isRequired: false,
          attributes: []
        },
        supplierName: {
          name: 'supplierName',
          isArray: false,
          type: 'String',
          isRequired: false,
          attributes: []
        },
        supplierId: {
          name: 'supplierId',
          isArray: false,
          type: 'String',
          isRequired: false,
          attributes: []
        },
        priority: {
          name: 'priority',
          isArray: false,
          type: 'String',
          isRequired: false,
          attributes: []
        },
        height: {
          name: 'height',
          isArray: false,
          type: 'String',
          isRequired: false,
          attributes: []
        },
        width: {
          name: 'width',
          isArray: false,
          type: 'String',
          isRequired: false,
          attributes: []
        },
        depth: {
          name: 'depth',
          isArray: false,
          type: 'String',
          isRequired: false,
          attributes: []
        },
        weight: {
          name: 'weight',
          isArray: false,
          type: 'String',
          isRequired: false,
          attributes: []
        },
        purchaseType: {
          name: 'purchaseType',
          isArray: false,
          type: 'String',
          isRequired: false,
          attributes: []
        },
        hasDangerousGoods: {
          name: 'hasDangerousGoods',
          isArray: false,
          type: 'Boolean',
          isRequired: false,
          attributes: []
        },
        dateReceived: {
          name: 'dateReceived',
          isArray: false,
          type: 'AWSDateTime',
          isRequired: false,
          attributes: []
        },
        docketNumber: {
          name: 'docketNumber',
          isArray: false,
          type: 'String',
          isRequired: false,
          attributes: []
        },
        qrCodeId: {
          name: 'qrCodeId',
          isArray: false,
          type: 'String',
          isRequired: false,
          attributes: []
        },
        items: {
          name: 'items',
          isArray: true,
          type: {
            nonModel: 'ReceiptEventPackageItem'
          },
          isRequired: false,
          attributes: [],
          isArrayNullable: true
        }
      }
    },
    ReceiptEventPackageItem: {
      name: 'ReceiptEventPackageItem',
      fields: {
        orderLineId: {
          name: 'orderLineId',
          isArray: false,
          type: 'Int',
          isRequired: true,
          attributes: []
        },
        partId: {
          name: 'partId',
          isArray: false,
          type: 'Int',
          isRequired: true,
          attributes: []
        },
        sourcePartId: {
          name: 'sourcePartId',
          isArray: false,
          type: 'String',
          isRequired: false,
          attributes: []
        },
        purchaseType: {
          name: 'purchaseType',
          isArray: false,
          type: 'String',
          isRequired: false,
          attributes: []
        },
        supplierName: {
          name: 'supplierName',
          isArray: false,
          type: 'String',
          isRequired: false,
          attributes: []
        },
        description: {
          name: 'description',
          isArray: false,
          type: 'String',
          isRequired: false,
          attributes: []
        },
        quantityOrdered: {
          name: 'quantityOrdered',
          isArray: false,
          type: 'Int',
          isRequired: false,
          attributes: []
        },
        quantityReceived: {
          name: 'quantityReceived',
          isArray: false,
          type: 'Float',
          isRequired: false,
          attributes: []
        },
        receipt: {
          name: 'receipt',
          isArray: false,
          type: 'AWSDateTime',
          isRequired: false,
          attributes: []
        },
        receivingWarehouseId: {
          name: 'receivingWarehouseId',
          isArray: false,
          type: 'Int',
          isRequired: false,
          attributes: []
        },
        receivingWarehouseDefaultLocationId: {
          name: 'receivingWarehouseDefaultLocationId',
          isArray: false,
          type: 'Int',
          isRequired: false,
          attributes: []
        },
        inTransitWarehouseId: {
          name: 'inTransitWarehouseId',
          isArray: false,
          type: 'Int',
          isRequired: false,
          attributes: []
        },
        inTransitWarehouseLocationId: {
          name: 'inTransitWarehouseLocationId',
          isArray: false,
          type: 'Int',
          isRequired: false,
          attributes: []
        }
      }
    },
    ManifestCarrier: {
      name: 'ManifestCarrier',
      fields: {
        organisation: {
          name: 'organisation',
          isArray: false,
          type: 'String',
          isRequired: false,
          attributes: []
        },
        businessName: {
          name: 'businessName',
          isArray: false,
          type: 'String',
          isRequired: false,
          attributes: []
        },
        name: {
          name: 'name',
          isArray: false,
          type: 'String',
          isRequired: false,
          attributes: []
        },
        number: {
          name: 'number',
          isArray: false,
          type: 'String',
          isRequired: false,
          attributes: []
        },
        contractor: {
          name: 'contractor',
          isArray: false,
          type: 'Boolean',
          isRequired: false,
          attributes: []
        },
        driver: {
          name: 'driver',
          isArray: false,
          type: 'String',
          isRequired: false,
          attributes: []
        },
        secondaryDriver: {
          name: 'secondaryDriver',
          isArray: false,
          type: 'String',
          isRequired: false,
          attributes: []
        }
      }
    },
    ManifestAddress: {
      name: 'ManifestAddress',
      fields: {
        businessName: {
          name: 'businessName',
          isArray: false,
          type: 'String',
          isRequired: false,
          attributes: []
        },
        addressLine1: {
          name: 'addressLine1',
          isArray: false,
          type: 'String',
          isRequired: false,
          attributes: []
        },
        addressLine2: {
          name: 'addressLine2',
          isArray: false,
          type: 'String',
          isRequired: false,
          attributes: []
        },
        suburb: {
          name: 'suburb',
          isArray: false,
          type: 'String',
          isRequired: false,
          attributes: []
        },
        postCode: {
          name: 'postCode',
          isArray: false,
          type: 'String',
          isRequired: false,
          attributes: []
        },
        state: {
          name: 'state',
          isArray: false,
          type: 'String',
          isRequired: false,
          attributes: []
        }
      }
    },
    ManifestLeg: {
      name: 'ManifestLeg',
      fields: {
        id: {
          name: 'id',
          isArray: false,
          type: 'ID',
          isRequired: true,
          attributes: []
        },
        status: {
          name: 'status',
          isArray: false,
          type: 'String',
          isRequired: false,
          attributes: []
        },
        origin: {
          name: 'origin',
          isArray: false,
          type: {
            nonModel: 'ManifestAddress'
          },
          isRequired: false,
          attributes: []
        },
        destination: {
          name: 'destination',
          isArray: false,
          type: {
            nonModel: 'ManifestAddress'
          },
          isRequired: false,
          attributes: []
        },
        departureDate: {
          name: 'departureDate',
          isArray: false,
          type: 'AWSDateTime',
          isRequired: false,
          attributes: []
        },
        arrivalDate: {
          name: 'arrivalDate',
          isArray: false,
          type: 'AWSDateTime',
          isRequired: false,
          attributes: []
        },
        loadingDemurrage: {
          name: 'loadingDemurrage',
          isArray: false,
          type: 'Int',
          isRequired: false,
          attributes: []
        },
        unloadingDemurrage: {
          name: 'unloadingDemurrage',
          isArray: false,
          type: 'Int',
          isRequired: false,
          attributes: []
        },
        manifests: {
          name: 'manifests',
          isArray: true,
          type: {
            nonModel: 'Manifest'
          },
          isRequired: false,
          attributes: [],
          isArrayNullable: true
        }
      }
    },
    Manifest: {
      name: 'Manifest',
      fields: {
        id: {
          name: 'id',
          isArray: false,
          type: 'ID',
          isRequired: true,
          attributes: []
        },
        tripId: {
          name: 'tripId',
          isArray: false,
          type: 'String',
          isRequired: false,
          attributes: []
        },
        trailerId: {
          name: 'trailerId',
          isArray: false,
          type: 'String',
          isRequired: false,
          attributes: []
        },
        status: {
          name: 'status',
          isArray: false,
          type: 'String',
          isRequired: false,
          attributes: []
        },
        ssccs: {
          name: 'ssccs',
          isArray: true,
          type: {
            nonModel: 'ManifestSSCC'
          },
          isRequired: false,
          attributes: [],
          isArrayNullable: true
        }
      }
    },
    ManifestSSCC: {
      name: 'ManifestSSCC',
      fields: {
        ssccId: {
          name: 'ssccId',
          isArray: false,
          type: 'String',
          isRequired: false,
          attributes: []
        },
        status: {
          name: 'status',
          isArray: false,
          type: 'String',
          isRequired: false,
          attributes: []
        },
        packages: {
          name: 'packages',
          isArray: true,
          type: {
            nonModel: 'ManifestPackage'
          },
          isRequired: false,
          attributes: [],
          isArrayNullable: true
        },
        totalWeight: {
          name: 'totalWeight',
          isArray: false,
          type: 'Float',
          isRequired: false,
          attributes: []
        }
      }
    },
    ManifestPackage: {
      name: 'ManifestPackage',
      fields: {
        id: {
          name: 'id',
          isArray: false,
          type: 'ID',
          isRequired: true,
          attributes: []
        },
        maxQuantity: {
          name: 'maxQuantity',
          isArray: false,
          type: 'Int',
          isRequired: false,
          attributes: []
        },
        quantity: {
          name: 'quantity',
          isArray: false,
          type: 'Int',
          isRequired: false,
          attributes: []
        },
        receivedStatus: {
          name: 'receivedStatus',
          isArray: false,
          type: 'String',
          isRequired: false,
          attributes: []
        },
        asn: {
          name: 'asn',
          isArray: false,
          type: 'String',
          isRequired: false,
          attributes: []
        },
        asnExists: {
          name: 'asnExists',
          isArray: false,
          type: 'Boolean',
          isRequired: false,
          attributes: []
        },
        poNumber: {
          name: 'poNumber',
          isArray: false,
          type: 'String',
          isRequired: false,
          attributes: []
        },
        packageType: {
          name: 'packageType',
          isArray: false,
          type: 'String',
          isRequired: false,
          attributes: []
        },
        height: {
          name: 'height',
          isArray: false,
          type: 'String',
          isRequired: false,
          attributes: []
        },
        width: {
          name: 'width',
          isArray: false,
          type: 'String',
          isRequired: false,
          attributes: []
        },
        depth: {
          name: 'depth',
          isArray: false,
          type: 'String',
          isRequired: false,
          attributes: []
        },
        inventoryLines: {
          name: 'inventoryLines',
          isArray: true,
          type: {
            nonModel: 'ManifestInvLineItem'
          },
          isRequired: false,
          attributes: [],
          isArrayNullable: true
        }
      }
    },
    ManifestInvLineItem: {
      name: 'ManifestInvLineItem',
      fields: {
        orderLineId: {
          name: 'orderLineId',
          isArray: false,
          type: 'Int',
          isRequired: true,
          attributes: []
        },
        partId: {
          name: 'partId',
          isArray: false,
          type: 'Int',
          isRequired: true,
          attributes: []
        },
        sourcePartId: {
          name: 'sourcePartId',
          isArray: false,
          type: 'String',
          isRequired: false,
          attributes: []
        },
        purchaseType: {
          name: 'purchaseType',
          isArray: false,
          type: 'String',
          isRequired: false,
          attributes: []
        },
        description: {
          name: 'description',
          isArray: false,
          type: 'String',
          isRequired: false,
          attributes: []
        },
        quantityReceived: {
          name: 'quantityReceived',
          isArray: false,
          type: 'Float',
          isRequired: true,
          attributes: []
        },
        receivingWarehouseId: {
          name: 'receivingWarehouseId',
          isArray: false,
          type: 'Int',
          isRequired: false,
          attributes: []
        },
        receivingWarehouseDefaultLocationId: {
          name: 'receivingWarehouseDefaultLocationId',
          isArray: false,
          type: 'Int',
          isRequired: false,
          attributes: []
        },
        inTransitWarehouseId: {
          name: 'inTransitWarehouseId',
          isArray: false,
          type: 'Int',
          isRequired: false,
          attributes: []
        },
        inTransitWarehouseLocationId: {
          name: 'inTransitWarehouseLocationId',
          isArray: false,
          type: 'Int',
          isRequired: false,
          attributes: []
        }
      }
    },
    TransportBookingLocation: {
      name: 'TransportBookingLocation',
      fields: {
        businessName: {
          name: 'businessName',
          isArray: false,
          type: 'String',
          isRequired: false,
          attributes: []
        },
        addressLine1: {
          name: 'addressLine1',
          isArray: false,
          type: 'String',
          isRequired: false,
          attributes: []
        },
        addressLine2: {
          name: 'addressLine2',
          isArray: false,
          type: 'String',
          isRequired: false,
          attributes: []
        },
        suburb: {
          name: 'suburb',
          isArray: false,
          type: 'String',
          isRequired: false,
          attributes: []
        },
        state: {
          name: 'state',
          isArray: false,
          type: 'String',
          isRequired: false,
          attributes: []
        },
        postCode: {
          name: 'postCode',
          isArray: false,
          type: 'Int',
          isRequired: false,
          attributes: []
        },
        contactName: {
          name: 'contactName',
          isArray: false,
          type: 'String',
          isRequired: false,
          attributes: []
        },
        contactNumber: {
          name: 'contactNumber',
          isArray: false,
          type: 'String',
          isRequired: false,
          attributes: []
        },
        emailAddress: {
          name: 'emailAddress',
          isArray: false,
          type: 'String',
          isRequired: false,
          attributes: []
        }
      }
    }
  },
  codegenVersion: '3.4.4',
  version: '9c39ac12e6ec76dc2165172edeea816f'
};
