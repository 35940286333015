import { postSscc } from '../../api/postSscc';
import { LocationProps } from '../../datastore/locations';
import { SSCCProps } from '../../datastore/ssccs';
import { BaseEvent } from '../../../../Common/Types/types';
import dayjs from 'dayjs';

export async function createJobInBustle(
  ssccPayload: SSCCProps,
  originLocation: LocationProps,
  destinationLocation: LocationProps,
  deliveryDays?: number
) {
  ssccPayload.destinationLocation = destinationLocation;
  ssccPayload.originLocation = originLocation;
  ssccPayload.estimatedDelivery = dayjs().add(deliveryDays ?? 4, 'day');

  const event: BaseEvent<'ftl_sscc_create', SSCCProps> = {
    id: ssccPayload.id,
    source: 'ftl',
    'detail-type': 'ftl_sscc_create',
    detail: ssccPayload
  };

  const serializedEvent = JSON.stringify(event);
  const response = await postSscc(serializedEvent);

  return response;
}
