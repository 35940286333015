import { DataStore } from 'aws-amplify';
import { Location, Printer, UserDetails } from '../models';
import { LocationProps, convertLocationToProps } from './locations';
import { PrinterProps, convertPrinterToProps } from './printers';

export interface UserDetailsProps {
  id: string;
  location?: LocationProps | null;
  printer?: PrinterProps | null;
}

export interface UserDetailsDataProps {
  get: (id: string) => Promise<UserDetailsProps>;
  update: (data: UserDetailsProps) => Promise<UserDetailsProps>;
  getOriginalUserDetails: (id: string) => Promise<UserDetails | undefined>;
}

export const userDetailsData = () => {
  async function get(id: string) {
    const details = await DataStore.query(UserDetails, (u) => u.cognitoID.eq(id));
    if (details.length) {
      const userDetails = details[0];
      if (userDetails) {
        return await convertToProps(userDetails);
      }
    } else {
      const created = await DataStore.save(
        new UserDetails({
          cognitoID: id,
          location: null,
          printer: null
        })
      );

      if (created) {
        return await convertToProps(created);
      }
    }
    return {} as UserDetailsProps;
  }

  async function update(data: UserDetailsProps) {
    const original = await getOriginalUserDetails(data.id);
    const locationToUpdate = data.location ? await DataStore.query(Location, data.location.id) : null;
    const printerToUpdate = data.printer ? await DataStore.query(Printer, data.printer.id) : null;
    if (original) {
      const updated = await DataStore.save(
        UserDetails.copyOf(original, (updated) => {
          updated.location = locationToUpdate;
          updated.printer = printerToUpdate;
        })
      );

      if (updated) {
        return await convertToProps(updated);
      }
    }
    return {} as UserDetailsProps;
  }

  async function getOriginalUserDetails(id: string) {
    return await DataStore.query(UserDetails, id);
  }

  async function convertToProps(data: UserDetails) {
    const location = await data.location;
    const printer = await data.printer;

    const results: UserDetailsProps = {
      id: data.id,
      location: location ? await convertLocationToProps(location) : null,
      printer: printer ? convertPrinterToProps(printer) : null
    };

    return results;
  }

  const returned: UserDetailsDataProps = {
    get,
    update,
    getOriginalUserDetails
  };

  return returned;
};
