import { DataStore } from 'aws-amplify';
import { Printer, UserDetails } from '../models';

export interface PrinterProps {
  id: string;
  name: string;
  printerIP: string;
  printerPort: string;
  isDisabled: boolean;
}

const unknownPrinter = {
  name: 'Unknown',
  printerIP: '192.168.1.0',
  printerPort: '9100'
};

export interface PrinterDataProps {
  create: (data: PrinterProps) => Promise<PrinterProps | undefined>;
  getPrinterForUser: (userId: string) => Promise<PrinterProps | undefined>;
  update: (data: PrinterProps) => Promise<PrinterProps | undefined>;
  getAll: () => Promise<PrinterProps[]>;
  getById: (id: string) => Promise<PrinterProps | undefined>;
}

export const printersData = () => {
  async function create(data: PrinterProps): Promise<PrinterProps | undefined> {
    const created = await DataStore.save(
      new Printer({
        id: data.id,
        name: data.name,
        printerIP: data.printerIP,
        printerPort: data.printerPort,
        isDisabled: data.isDisabled
      })
    );

    if (created) {
      return convertPrinterToProps(created);
    }

    return undefined;
  }

  async function getPrinterForUser(userId: string): Promise<PrinterProps | undefined> {
    const details = await DataStore.query(UserDetails, (u) => u.cognitoID.eq(userId));
    const userDetails = details[0];
    const printer = await userDetails?.printer;
    if (printer) {
      return convertPrinterToProps(printer);
    }
    return undefined;
  }

  async function getAll(): Promise<PrinterProps[]> {
    const results = await DataStore.query(Printer);
    return results.map(convertPrinterToProps);
  }

  async function getById(id: string): Promise<PrinterProps | undefined> {
    const result = await DataStore.query(Printer, id);
    if (result) {
      return convertPrinterToProps(result);
    }
    return undefined;
  }

  async function update(data: PrinterProps) {
    const original = await DataStore.query(Printer, data.id);

    if (original) {
      const updated = await DataStore.save(
        Printer.copyOf(original, (updated) => {
          updated.name = data.name;
          updated.printerIP = data.printerIP;
          updated.printerPort = data.printerPort;
          updated.isDisabled = data.isDisabled;
        })
      );

      if (updated) {
        return convertPrinterToProps(updated);
      }
    }
    return undefined;
  }

  const returned: PrinterDataProps = {
    create,
    getPrinterForUser,
    update,
    getAll,
    getById
  };

  return returned;
};

export function convertPrinterToProps(data: Printer) {
  const printerProps: PrinterProps = {
    id: data.id,
    name: data.name || unknownPrinter.name,
    printerIP: data.printerIP || unknownPrinter.printerIP,
    printerPort: data.printerPort || unknownPrinter.printerPort,
    isDisabled: data.isDisabled || false
  };
  return printerProps;
}
