import React, { createContext, useState, useContext, useEffect, ReactNode, useCallback } from 'react';
import { useCurrentUser } from './useCurrentUser';
import { useDatastore } from './useDatastore';

type DisabledPrinterContextType = {
  isDisabled: boolean;
  refreshPrinters: () => Promise<void>;
};

type DisabledPrinterProviderProps = {
  children: ReactNode;
};

const DisabledPrinterContext = createContext<DisabledPrinterContextType>({
  isDisabled: false,
  refreshPrinters: async () => {}
});

export const useDisabledPrinterContext = () => {
  const context = useContext(DisabledPrinterContext);
  if (!context) {
    throw new Error('useDisabledPrinterContext must be used within a DisabledPrinterProvider');
  }

  useEffect(() => {
    context.refreshPrinters();
  }, [context.refreshPrinters, context]);

  return context;
};

export const DisabledPrinterProvider: React.FC<DisabledPrinterProviderProps> = ({ children }) => {
  const user = useCurrentUser();
  const store = useDatastore();
  const [isDisabled, setIsDisabled] = useState<boolean>(false);

  const fetchPrintersAndUpdateState = useCallback(async () => {
    try {
      const userPrinter = await store.printers.getPrinterForUser(user.cognitoUser.username);
      setIsDisabled(userPrinter?.isDisabled ?? true);
    } catch (error) {
      console.error('Error fetching printers:', error);
    }
  }, [store.printers, user.cognitoUser.username]);

  useEffect(() => {
    fetchPrintersAndUpdateState();
  }, [fetchPrintersAndUpdateState]);

  return (
    <DisabledPrinterContext.Provider value={{ isDisabled, refreshPrinters: fetchPrintersAndUpdateState }}>
      {children}
    </DisabledPrinterContext.Provider>
  );
};
