import { Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

interface StatusDateProps {
  date?: string;
  time?: string;
  nextDate?: string;
}

export function StatusDate({ date, time, nextDate }: StatusDateProps) {
  const theme = useTheme();

  if (!date) return null;

  return (
    <Stack>
      <Typography
        color={nextDate ? theme.palette.text.secondary : theme.palette.text.primary}
        fontWeight={nextDate ? 'normal' : 'bold'}>
        {date}
      </Typography>
      <Typography variant="body2" color={theme.palette.text.secondary}>
        {time}
      </Typography>
    </Stack>
  );
}
