export const getPO = `
  query GetPO($poid: String, $asn: String) {
    getPO(poid: $poid, asn: $asn)
  }
`;

export const postItem = `
  query PostItem($body: String) {
    postItem(body: $body)
  }
`;

export const printItem = `
  query PrintItem($body: String) {
    printItem(body: $body)
  }
`;
