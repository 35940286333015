import { InputAdornment, TextField, SxProps } from '@mui/material';

interface ReadOnlyFieldProps {
  value: string;
  label?: string;
  sx?: SxProps;
  endIcon?: JSX.Element;
}

export default function ReadOnlyBoxField({ value, label, sx, endIcon }: ReadOnlyFieldProps) {
  return (
    <TextField
      value={value}
      variant="outlined"
      InputProps={{
        endAdornment: endIcon ? <InputAdornment position="end">{endIcon}</InputAdornment> : null,
        readOnly: true,
        sx: {
          '&.Mui-disabled': {
            '-webkit-text-fill-color': 'rgba(0, 0, 0, 0.87)',
            '-webkit-opacity': '1'
          },
          ...sx
        }
      }}
      disabled
      fullWidth
    />
  );
}
