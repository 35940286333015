/* eslint-disable react-hooks/exhaustive-deps */
import { MenuItem, Pagination, Stack, Table, TableBody, TableCell, Typography, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import {
  DarkUpperSection,
  LoadingSpinner,
  PageSection,
  SearchField,
  SearchNotFoundCard,
  StyledSelect,
  StyledSwitch,
  StyledTableHead,
  StyledTableRow
} from '../../components';
import SearchIcon from '@mui/icons-material/Search';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useObserveTripSheets from '../../hooks/useSubscribeTripsheet';
import { ManifestStatus } from '../../datastore/tripSheets';
import { useCurrentUser, useDatastore } from '../../hooks';
import { galaxyNoteThreeDimensions } from '../../common/strings';
import { formatToPascalCase } from '../../common/string';
import { FormattedManifestLegStatus, ManifestLegStatus } from '../../common/status';

export default function ReceiveManifest() {
  const theme = useTheme();
  const navigate = useNavigate();
  const tripSheets = useObserveTripSheets();
  const user = useCurrentUser();
  const store = useDatastore();
  const [isLoading, setIsLoading] = useState(true);
  const [search, setSearch] = useState('');
  const [searchStatus, setSearchStatus] = useState('');
  const [hideCompleted, setHideCompleted] = useState(true);
  const isMobile = useMediaQuery(galaxyNoteThreeDimensions);
  const [hideAllLocations, setHideAllLocations] = useState(true);
  const [usersLocation, setUsersLocation] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  let filteredTripSheets = tripSheets;

  useEffect(() => {
    async function getUserData() {
      const userDetails = await store.userDetails.get(user.cognitoUser.username);
      const usersLocation = userDetails?.location;
      setUsersLocation(usersLocation?.name ?? '');
      setIsLoading(false);
    }
    getUserData();
  }, []);

  useEffect(() => {
    setCurrentPage(1);
  }, [search, searchStatus, hideCompleted, hideAllLocations]);

  function filterTripSheets() {
    let filtered = [...tripSheets];

    if (hideCompleted) {
      filtered = filtered
        .map((trip) => {
          const filteredLegs = trip.legs
            .map((leg) => {
              const filteredManifests = leg.manifests.filter(
                (manifest) => manifest.status !== ManifestStatus.completed
              );
              return { ...leg, manifests: filteredManifests };
            })
            .filter((leg) => leg.manifests.length > 0);
          return { ...trip, legs: filteredLegs };
        })
        .filter((trip) => trip.legs.length > 0);
    }

    if (search) {
      const lowerCaseSearch = search.toLowerCase();
      filtered = filtered.filter((trip) => {
        return trip.legs.some((leg) =>
          leg.manifests.some((manifest) => manifest.tripId.toLowerCase().includes(lowerCaseSearch))
        );
      });
    }

    if (hideAllLocations) {
      filtered = filtered.filter((trip) => {
        return trip.legs.some((leg) => leg.destination.businessName === usersLocation);
      });
    }
    filteredTripSheets = filtered;
  }

  const disabledRowStyle = {
    color: 'grey',
    height: '5em',
    backgroundColor: '#f5f5f5',
    cursor: 'not-allowed',
    '&:hover': {
      backgroundColor: '#f5f5f5'
    }
  };

  const manifestStatuses = [
    { key: '', value: 'No Filter' },
    ...Object.entries(FormattedManifestLegStatus).map(([key, value]) => ({ key, value }))
  ];

  function renderSelectAndSwitch() {
    return (
      <>
        <StyledSelect
          label={searchStatus === '' ? 'Filter Status' : ''}
          sx={{ width: '25%' }}
          shrink={false}
          value={searchStatus}
          onChange={setSearchStatus}>
          {manifestStatuses.map((status) => (
            <MenuItem key={status.value} value={status.key}>
              {status.value}
            </MenuItem>
          ))}
        </StyledSelect>
        <StyledSwitch value={hideCompleted} onChange={setHideCompleted} label="Hide Completed" />
        <StyledSwitch value={hideAllLocations} onChange={setHideAllLocations} label="Hide All Locations" />
      </>
    );
  }

  function handlePageChange(event: React.ChangeEvent<unknown>, value: number) {
    setCurrentPage(value);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  filterTripSheets();

  const pageSize = 50;
  const totalNumberOfPages = Math.ceil(filteredTripSheets.length / pageSize);
  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = Math.min(startIndex + pageSize, filteredTripSheets.length);
  const paginatedTripsheets = filteredTripSheets.slice(startIndex, endIndex);

  return (
    <>
      <DarkUpperSection>
        <Stack spacing={2} sx={{ padding: '1em 0' }}>
          <Typography variant="h2" fontWeight="bold" color={theme.palette.primary.contrastText}>
            Manifests
          </Typography>
          <Stack direction="row" spacing={2}>
            <SearchField
              placeholder="Search by Trip #"
              startIcon={<SearchIcon />}
              onTrigger={(value) => setSearch(value)}
              sx={{ width: '26em' }}
            />

            {!isMobile && renderSelectAndSwitch()}
          </Stack>
          {isMobile && (
            <Stack direction="row" spacing="2em">
              {renderSelectAndSwitch()}
            </Stack>
          )}
        </Stack>
      </DarkUpperSection>
      <PageSection>
        <Stack spacing={2} sx={{ marginTop: '1em' }}>
          <LoadingSpinner isLoading={isLoading}>
            {paginatedTripsheets.length === 0 ? (
              <SearchNotFoundCard />
            ) : (
              <Table>
                <StyledTableHead>
                  <TableCell width="12%">Trip #</TableCell>
                  <TableCell width="13%">Manifest #</TableCell>
                  <TableCell width="10%">Trailer #</TableCell>
                  <TableCell width="15%">Status</TableCell>
                  <TableCell width="12%">{isMobile ? '# Cons ' : '# Consignments'}</TableCell>
                  <TableCell width="19%">From</TableCell>
                  <TableCell width="19%">To</TableCell>
                </StyledTableHead>
                <TableBody>
                  {paginatedTripsheets.map((trip) => {
                    return trip.legs.map((leg) => {
                      if (searchStatus && leg.status.replace('_', '').toLowerCase() !== searchStatus) return null;
                      return leg.manifests
                        .filter((m) => m.ssccs.length > 0)
                        .map((manifest) => {
                          const readOnly =
                            manifest.ssccs.length === 0 || leg.status === ManifestLegStatus.awaitingDeparture;

                          return (
                            <StyledTableRow
                              key={manifest.id}
                              sx={readOnly ? disabledRowStyle : { height: '5em', cursor: 'pointer' }}
                              onClick={() => {
                                if (!readOnly) {
                                  navigate(`/receive-manifest/${manifest.id}`, {
                                    state: {
                                      tripId: trip.id,
                                      legStatus: leg.status,
                                      legDestination: leg.destination.businessName
                                    }
                                  });
                                }
                              }}>
                              <TableCell>{manifest.tripId}</TableCell>
                              <TableCell>{manifest.id}</TableCell>
                              <TableCell>{manifest.trailerId}</TableCell>
                              <TableCell>
                                {manifest.status === ManifestStatus.completed
                                  ? ManifestStatus.completed
                                  : formatToPascalCase(leg.status.replace('_', ' '))}
                              </TableCell>
                              <TableCell>{manifest.ssccs.length}</TableCell>
                              <TableCell>{leg.origin.businessName}</TableCell>
                              <TableCell>{leg.destination.businessName}</TableCell>
                            </StyledTableRow>
                          );
                        });
                    });
                  })}
                </TableBody>
              </Table>
            )}
          </LoadingSpinner>
        </Stack>
        <Stack spacing={2} direction="row" justifyContent="center" sx={{ paddingTop: '1em', paddingBottom: '1em' }}>
          <Pagination count={totalNumberOfPages} page={currentPage} onChange={handlePageChange} color="primary" />
        </Stack>
      </PageSection>
    </>
  );
}
