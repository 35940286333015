import { Button, Divider, Paper, Stack, Typography } from '@mui/material';
import { PageSection } from '../../components';
import { ReactComponent as Logo } from '../../assets/images/mrllogo.svg';

interface LoginProps {
  login: () => void;
}

export default function Login({ login }: LoginProps) {
  return (
    <PageSection>
      <Paper elevation={4}>
        <Stack spacing={3} sx={{ padding: '4em 1em 2em 1em', marginTop: '1em', alignItems: 'center' }}>
          <Logo width="50%" height="50%" />
          <Stack>
            <Typography variant="h4" fontWeight="bold">
              Welcome to Freight Transport and Logistics
            </Typography>
          </Stack>
          <Divider />
          <Button variant="contained" onClick={login} sx={{ padding: '1em', width: '50%' }}>
            Login
          </Button>
        </Stack>
      </Paper>
    </PageSection>
  );
}
