import { Dialog, IconButton, Stack, Typography, TextField, FormControlLabel, Switch } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { SubmitHandler, useForm, Controller } from 'react-hook-form';
import { useEffect } from 'react';
import { StyledButton } from '../../../../components';
import { PrinterProps } from '../../../../datastore/printers';

interface PrinterEditFormProps {
  open: boolean;
  printer: PrinterProps | null;
  onSubmit: (data: PrinterProps) => void;
  onClose: () => void;
}

export default function EditPrinterForm({ open, printer, onSubmit, onClose }: PrinterEditFormProps) {
  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors }
  } = useForm<PrinterProps>();

  const onSubmitForm: SubmitHandler<PrinterProps> = (data) => onSubmit(data);

  useEffect(() => {
    if (printer) {
      let printerKey: keyof PrinterProps;
      for (printerKey in printer) {
        setValue(printerKey, printer[printerKey]);
      }
    }
  }, [printer, setValue]);

  return (
    <Dialog open={open} maxWidth="xs" fullWidth>
      <form onSubmit={handleSubmit(onSubmitForm)}>
        <Stack spacing={3} sx={{ padding: '1em 2em' }}>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Typography variant="h4" fontWeight="bold">
              Edit Printer
            </Typography>
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Stack>
          <Controller
            name="name"
            control={control}
            render={({ field }) => <TextField label="Name" fullWidth {...field} error={!!errors.name} required />}
          />
          <Controller
            name="printerIP"
            control={control}
            render={({ field }) => <TextField label="IP" fullWidth {...field} error={!!errors.printerIP} required />}
          />
          <Controller
            name="printerPort"
            control={control}
            render={({ field }) => (
              <TextField label="Port" fullWidth {...field} error={!!errors.printerPort} required />
            )}
          />
          <Controller
            name="isDisabled"
            control={control}
            render={({ field }) => (
              <FormControlLabel control={<Switch checked={field.value} {...field} />} label="Disabled" />
            )}
          />
          <StyledButton variant="contained" type="submit">
            Save Changes
          </StyledButton>
        </Stack>
      </form>
    </Dialog>
  );
}
