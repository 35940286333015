import { createTheme } from '@mui/material/styles';

export const themeOptions = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#114EC7',
      light: '#5C94FF',
      contrastText: '#fff'
    },
    secondary: {
      main: '#000',
      light: '#CF352F',
      contrastText: '#A6A6A6'
    },
    info: {
      main: '#5C94FF',
      light: '#DEEAFF'
    },
    error: {
      main: '#CF352F',
      light: '#FAEBEA'
    },
    background: {
      default: '#ffffff'
    },
    text: {
      primary: '#000',
      secondary: '#595959'
    }
  },
  typography: {
    fontFamily: 'Avenir, "Century Gothic", sans-serif',
    button: {
      textTransform: 'none'
    },
    h1: {
      fontSize: '2em'
    },
    h2: {
      fontSize: '1.8em'
    },
    h3: {
      fontSize: '1.5em'
    },
    h4: {
      fontSize: '1.2em'
    },
    h5: {
      fontSize: '1em'
    }
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 48,
          padding: '0.25em 1em'
        }
      }
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          borderRadius: 4,
          padding: '0.6em 1em'
        }
      }
    }
  }
});
