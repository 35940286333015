import { Button, ButtonProps } from '@mui/material';
import { styled } from '@mui/material/styles';

export const LightErrorButton = styled(Button)<ButtonProps>(({ theme }) => ({
  backgroundColor: theme.palette.error.light,
  color: theme.palette.error.main,
  '&:hover': {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.primary.contrastText
  }
}));

export default LightErrorButton;
