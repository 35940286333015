import { DataStore } from 'aws-amplify';
import { ReceiptEventPackage, ReceiptEventPackageItem, SSCC } from '../models';
import dayjs from 'dayjs';
import { SsccStatus } from '../common';
import { LocationProps } from './locations';
import { TransportBookingLocationProps, convertToTransportBookingLocationProps } from './transportBookingEvent';

export enum SsccType {
  standard = 'Standard',
  large = 'Large',
  direct = 'Direct',
  transportBooking = 'Transport Booking'
}

export interface SSCCProps {
  id: string;
  nickname?: string | null;
  destinationLocationId: string;
  originLocationId: string;
  lock: boolean;
  status?: string | null;
  detailType?: string;
  isLargeItem: boolean;
  bustleServiceType?: string;
  isTransportBooking: boolean;
  transportBookingDestination: TransportBookingLocationProps | null;
  ssccType: string | null;
  height: string | null;
  width: string | null;
  depth: string | null;
  weight: string | null;
  open: dayjs.Dayjs | null;
  staged: dayjs.Dayjs | null;
  manifested: dayjs.Dayjs | null;
  inTransit: dayjs.Dayjs | null;
  approaching: dayjs.Dayjs | null;
  delivered: dayjs.Dayjs | null;
  estimatedDelivery: dayjs.Dayjs | null;
  stagingLocation: string | null;
  destinationLocation?: LocationProps;
  originLocation?: LocationProps;
  receiptedEvents: ReceiptEventSiteProps[];
}

export interface ReceiptEventSiteProps {
  name?: string | null;
  site?: string | null;
  packages: ReceiptEventPackageProps[];
}

export interface ReceiptEventPackageProps {
  id: string;
  status: string | null;
  statusDate: dayjs.Dayjs | null;
  packageType?: string | null;
  jobId: string | null;
  assetNumber: string | null;
  supplierName?: string | null;
  supplierId?: string | null;
  priority?: string | null;
  quantity?: number | null;
  companyName?: string;
  po?: string | null;
  asn?: string | null;
  height: string | null;
  width: string | null;
  depth: string | null;
  weight: string | null;
  hasDangerousGoods: boolean;
  dateReceived: string;
  docketNumber: string;
  qrCodeId: string;
  shipToAttention: string;
  items: ReceiptEventPackageItemProps[];
}

export interface ReceiptEventPackageItemProps {
  orderLineId: number;
  partId: number;
  sourcePartId: string;
  purchaseType?: string | null;
  description?: string | null;
  receipt: dayjs.Dayjs | null;
  quantityReceived?: number | null;
  inTransitWarehouseId?: number | null;
  inTransitWarehouseLocationId?: number | null;
  receivingWarehouseId?: number | null;
  receivingWarehouseDefaultLocationId?: number | null;
}

export interface SSCCsDataProps {
  get: (id: string) => Promise<SSCCProps | undefined>;
  getList: (
    search: string,
    siteName?: string,
    status?: string,
    hideDelivered?: boolean
  ) => Promise<SSCCProps[] | undefined>;
  getListForReprint: (search: string, dateLimit?: dayjs.Dayjs | null) => Promise<SSCCProps[] | undefined>;
  create: (data: SSCCProps) => Promise<SSCCProps | undefined>;
  update: (data: SSCCProps) => Promise<SSCCProps | undefined>;
  getPackageByASN: (asn: string) => Promise<ReceiptEventPackage | undefined>;
}

export const ssccsData = () => {
  async function get(id: string) {
    const results = await DataStore.query(SSCC, (sscc) => sscc.id.eq(id));
    const notCancelledSsccs = results.filter((sscc) => sscc.status !== SsccStatus.cancelled);
    if (notCancelledSsccs && results.length) {
      return convertSsccToProps(notCancelledSsccs[0]);
    }
  }

  async function getList(search: string, siteName?: string, status?: string, hideDelivered?: boolean) {
    let results = await DataStore.query(SSCC, null);

    if (search.length) {
      const lowerSearch = search.toLowerCase();
      results = results.filter(
        (sscc) =>
          sscc.id.toString().includes(lowerSearch) ||
          sscc.receiptedEvents?.some(
            (s) => s?.name?.toLowerCase().includes(lowerSearch) || s?.site?.toLowerCase().includes(lowerSearch)
          )
      );
    }

    if (siteName?.length) {
      results = results.filter((sscc) => sscc.receiptedEvents?.some((s) => s?.name === siteName));
    }

    if (status?.length) {
      results = results.filter((sscc) => sscc.status?.toLowerCase() === status);
    }

    if (hideDelivered) {
      results = results.filter((sscc) => sscc.status !== SsccStatus.delivered);
    }

    if (results) {
      return results.map((r) => convertSsccToProps(r)).filter((sscc) => sscc.status !== SsccStatus.cancelled);
    }
  }

  async function getListForReprint(search: string, dateLimit?: dayjs.Dayjs | null): Promise<SSCCProps[]> {
    const today = dayjs().add(1, 'day').toISOString();
    const endDateString = dateLimit?.toISOString() ?? '';

    const results: SSCC[] = await DataStore.query(SSCC, (c) => c.open.between(endDateString, today));

    let convertedResults: SSCCProps[] = results.flatMap((r) => convertSsccToProps(r));

    if (search.length) {
      const lowerSearch = search.toLowerCase();

      convertedResults = convertedResults
        .map((sscc: SSCCProps) => {
          const matchesIdOrNickname =
            sscc.id?.toString().includes(lowerSearch) || sscc.nickname?.toLowerCase().includes(lowerSearch);

          if (matchesIdOrNickname) {
            return sscc;
          }

          sscc.receiptedEvents = sscc.receiptedEvents
            .map((site) => {
              site.packages = site.packages.filter(
                (packageItem) =>
                  packageItem.po?.toLowerCase().includes(lowerSearch) ||
                  packageItem.asn?.toLowerCase().includes(lowerSearch)
              );
              return site;
            })
            .filter((site) => site.packages.length > 0);

          return sscc;
        })
        .filter((sscc: SSCCProps) => {
          return (
            sscc.id?.toString().includes(lowerSearch) ||
            sscc.nickname?.toLowerCase().includes(lowerSearch) ||
            sscc.receiptedEvents.length > 0
          );
        });
    }

    if (dateLimit) {
      convertedResults = convertedResults.filter((site: SSCCProps) => site.open?.isAfter(dateLimit));
    }

    return convertedResults.filter((sscc) => sscc.status !== SsccStatus.cancelled);
  }

  async function create(data: SSCCProps) {
    const created = await DataStore.save(
      new SSCC({
        id: data.id,
        nickname: data.nickname,
        lock: data.lock,
        originLocationId: data.originLocationId,
        destinationLocationId: data.destinationLocationId,
        ssccType: data.ssccType,
        isLargeItem: data.isLargeItem,
        isTransportBooking: data.isTransportBooking,
        transportBookingDestination: data.transportBookingDestination,
        bustleServiceType: data.bustleServiceType,
        status: data.status,
        open: data.open?.toISOString(),
        staged: data.staged?.toISOString(),
        manifested: data.manifested?.toISOString(),
        inTransit: data.inTransit?.toISOString(),
        approaching: data.approaching?.toISOString(),
        delivered: data.delivered?.toISOString(),
        width: data.width,
        height: data.height,
        depth: data.depth,
        weight: data.weight,
        stagingLocation: data.stagingLocation,
        receiptedEvents: data.receiptedEvents?.map((site) => {
          return {
            ...site,
            packages: site.packages?.map((pkg) => {
              return {
                ...pkg,
                dateReceived: dayjs(pkg.dateReceived)?.toISOString(),
                docketNumber: pkg?.docketNumber,
                jobId: pkg?.jobId,
                companyName: pkg?.companyName,
                assetNumber: pkg?.assetNumber,
                statusDate: pkg.statusDate?.toISOString(),
                qrCodeId: pkg.qrCodeId,
                shipToAttention: pkg.shipToAttention,
                items: pkg.items?.map((item) => {
                  return {
                    orderLineId: item.orderLineId,
                    partId: item.partId,
                    sourcePartId: item.sourcePartId,
                    description: item.description,
                    purchaseType: item.purchaseType,
                    receipt: item.receipt?.toISOString(),
                    quantityReceived: item.quantityReceived,
                    receivingWarehouseId: item.receivingWarehouseId ?? null,
                    receivingWarehouseDefaultLocationId: item.receivingWarehouseDefaultLocationId ?? null,
                    inTransitWarehouseId: item.inTransitWarehouseId ?? null,
                    inTransitWarehouseLocationId: item.inTransitWarehouseLocationId ?? null
                  };
                })
              };
            })
          };
        })
      })
    );

    if (created) {
      return convertSsccToProps(created);
    }
  }

  async function update(data: SSCCProps) {
    const original = await DataStore.query(SSCC, data.id);

    if (original) {
      const updated = await DataStore.save(
        SSCC.copyOf(original, (updated) => {
          updated.lock = data.lock;
          updated.status = data.status;
          updated.destinationLocationId = data.destinationLocationId;
          updated.isLargeItem = data.isLargeItem;
          updated.originLocationId = data.originLocationId;
          updated.ssccType = data.ssccType;
          updated.isTransportBooking = data.isTransportBooking;
          updated.transportBookingDestination = data.transportBookingDestination;
          updated.bustleServiceType = data.bustleServiceType;
          updated.estimatedDelivery = data.estimatedDelivery ? dayjs(data.estimatedDelivery).toISOString() : null;
          updated.open = data.open ? data.open.toISOString() : null;
          updated.staged = data.staged ? data.staged.toISOString() : null;
          updated.manifested = data.manifested ? data.manifested.toISOString() : null;
          updated.inTransit = data.inTransit ? data.inTransit.toISOString() : null;
          updated.approaching = data.approaching ? data.approaching.toISOString() : null;
          updated.delivered = data.delivered ? data.delivered.toISOString() : null;
          updated.width = data.width;
          updated.height = data.height;
          updated.depth = data.depth;
          updated.weight = data.weight;
          updated.stagingLocation = data.stagingLocation;
          updated.nickname = data.nickname;
          updated.receiptedEvents = data.receiptedEvents?.map((site) => {
            return {
              ...site,
              packages: site.packages?.map((pkg) => {
                return {
                  ...pkg,
                  dateReceived: pkg.dateReceived ? dayjs(pkg.dateReceived)?.toISOString() : null,
                  docketNumber: pkg?.docketNumber,
                  jobId: pkg?.jobId,
                  assetNumber: pkg?.assetNumber,
                  companyName: pkg?.companyName,
                  statusDate: pkg.statusDate?.toISOString(),
                  qrCodeId: pkg.qrCodeId,
                  shipToAttention: pkg.shipToAttention,
                  items: pkg.items?.map((item) => {
                    const itm: ReceiptEventPackageItem = {
                      orderLineId: item.orderLineId,
                      partId: item.partId,
                      sourcePartId: item.sourcePartId,
                      description: item.description,
                      purchaseType: item.purchaseType,
                      receipt: item.receipt?.toISOString(),
                      quantityReceived: item.quantityReceived,
                      receivingWarehouseId: item.receivingWarehouseId ?? null,
                      receivingWarehouseDefaultLocationId: item.receivingWarehouseDefaultLocationId ?? null,
                      inTransitWarehouseId: item.inTransitWarehouseId ?? null,
                      inTransitWarehouseLocationId: item.inTransitWarehouseLocationId ?? null
                    };
                    return itm;
                  })
                };
              })
            };
          });
        })
      );

      if (updated) {
        return convertSsccToProps(updated);
      }
    }
  }

  async function getPackageByASN(asn: string): Promise<ReceiptEventPackage | undefined> {
    const allSSCCs = await DataStore.query(SSCC);

    for (const sscc of allSSCCs) {
      for (const eventSite of sscc.receiptedEvents || []) {
        for (const pkg of eventSite?.packages || []) {
          if (pkg?.asn && pkg.asn === asn) {
            return pkg;
          }
        }
      }
    }

    return undefined;
  }

  const returned: SSCCsDataProps = {
    get,
    getList,
    getListForReprint,
    create,
    update,
    getPackageByASN
  };

  return returned;
};

export function convertSsccToProps(data: SSCC) {
  const results: SSCCProps = {
    id: data.id,
    nickname: data.nickname,
    isLargeItem: data.isLargeItem ?? false,
    isTransportBooking: data.isTransportBooking ?? false,
    transportBookingDestination:
      convertToTransportBookingLocationProps(data.transportBookingDestination || null) || null,
    destinationLocationId: data.destinationLocationId,
    bustleServiceType: data.bustleServiceType || undefined,
    originLocationId: data.originLocationId,
    lock: data.lock ?? false,
    status: data.status,
    ssccType: data.ssccType || null,
    height: data.height || null,
    width: data.width || null,
    depth: data.depth || null,
    weight: data.weight || null,
    estimatedDelivery: data.estimatedDelivery ? dayjs(data.estimatedDelivery) : null,
    open: data.open ? dayjs(data.open) : null,
    staged: data.staged ? dayjs(data.staged) : null,
    manifested: data.manifested ? dayjs(data.manifested) : null,
    inTransit: data.inTransit ? dayjs(data.inTransit) : null,
    approaching: data.approaching ? dayjs(data.approaching) : null,
    delivered: data.delivered ? dayjs(data.delivered) : null,
    stagingLocation: data.stagingLocation || null,
    receiptedEvents:
      data.receiptedEvents?.map((site) => {
        return {
          name: site?.name,
          site: site?.site,
          packages:
            site?.packages?.map((pkg) => {
              const parsedPackage: ReceiptEventPackageProps = {
                id: pkg?.id || crypto.randomUUID(),
                dateReceived: pkg?.dateReceived ? dayjs(pkg.dateReceived).toISOString() : '',
                docketNumber: pkg?.docketNumber || '',
                status: pkg?.status || null,
                statusDate: pkg?.statusDate ? dayjs(pkg.statusDate) : null,
                packageType: pkg?.packageType,
                quantity: pkg?.quantity,
                po: pkg?.po,
                asn: pkg?.asn,
                jobId: pkg?.jobId || null,
                assetNumber: pkg?.assetNumber || null,
                companyName: pkg?.companyName || '',
                supplierName: pkg?.supplierName,
                supplierId: pkg?.supplierId || null,
                priority: pkg?.priority,
                height: pkg?.height || null,
                width: pkg?.width || null,
                depth: pkg?.depth || null,
                weight: pkg?.weight || null,
                hasDangerousGoods: pkg?.hasDangerousGoods ?? false,
                qrCodeId: pkg?.qrCodeId || '',
                shipToAttention: pkg?.shipToAttention || '',
                items:
                  pkg?.items
                    ?.filter((line): line is ReceiptEventPackageItem => line != null)
                    ?.map((item) => {
                      const parsedItem: ReceiptEventPackageItemProps = {
                        orderLineId: item.orderLineId,
                        partId: item.partId,
                        sourcePartId: item.sourcePartId ?? '',
                        description: item?.description,
                        purchaseType: item?.purchaseType,
                        quantityReceived: item?.quantityReceived,
                        receipt: item?.receipt ? dayjs(item.receipt) : null,
                        receivingWarehouseId: item?.receivingWarehouseId ?? null,
                        receivingWarehouseDefaultLocationId: item?.receivingWarehouseDefaultLocationId ?? null,
                        inTransitWarehouseId: item?.inTransitWarehouseId ?? null,
                        inTransitWarehouseLocationId: item?.inTransitWarehouseLocationId ?? null
                      };
                      return parsedItem;
                    }) ?? []
              };
              return parsedPackage;
            }) ?? []
        };
      }) ?? []
  };

  return results;
}
