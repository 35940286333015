import { Checkbox, Stack } from '@mui/material';

interface ReadOnlyCheckboxProps {
  checked: boolean;
}

export default function ReadOnlyCheckbox({ checked }: ReadOnlyCheckboxProps) {
  return (
    <Stack>
      <Checkbox
        checked={checked}
        readOnly
        onClick={(e) => e.preventDefault()}
        disableRipple
        sx={{
          pointerEvents: 'none',
          '& .MuiSvgIcon-root': {
            color: checked ? 'action.active' : 'text.disabled'
          },
          '&:hover': {
            backgroundColor: 'transparent'
          }
        }}
      />
    </Stack>
  );
}
