import { DataStore } from 'aws-amplify';
import { QMIssue } from '../models';

export interface QMIssueProps {
  id: string;
  issueId: string;
  name: string;
}

export interface QMIssueDataProps {
  create: (data: QMIssueProps) => Promise<QMIssueProps | undefined>;
  getArray: () => Promise<QMIssueProps[] | undefined>;
}

export const qmIssueData = () => {
  async function create(data: QMIssueProps): Promise<QMIssueProps | undefined> {
    const created = await DataStore.save(
      new QMIssue({
        id: data.id,
        issueId: data.issueId,
        name: data.name
      })
    );

    if (created) {
      return convertToProps(created);
    }

    return undefined;
  }

  async function getArray(): Promise<QMIssueProps[] | undefined> {
    const results = await DataStore.query(QMIssue);

    if (results) {
      return results.map((r) => convertToProps(r));
    }

    return undefined;
  }

  function convertToProps(data: QMIssue) {
    const qmIssueProps: QMIssueProps = {
      id: data.id,
      name: data.name,
      issueId: data.issueId
    };
    return qmIssueProps;
  }

  const returned: QMIssueDataProps = {
    create,
    getArray
  };

  return returned;
};
