import { DirtyProvider } from '../../hooks/useDirtyProvider';
import { DisabledPrinterProvider } from '../../hooks/useIsDisabledPrinter';
import Header from '../header';

type LayoutProps = {
  children: string | JSX.Element | JSX.Element[];
};

export default function Layout({ children }: LayoutProps) {
  return (
    <>
      <DirtyProvider>
        <DisabledPrinterProvider>
          <Header />
          {children}
        </DisabledPrinterProvider>
      </DirtyProvider>
    </>
  );
}
