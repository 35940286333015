import { DataStore } from 'aws-amplify';
import { RemoveItemSSCCHistory } from '../models';
import dayjs from 'dayjs';

export interface RemoveItemSSCCHistoryProps {
  id: string;
  userEmail: string;
  reason: string;
  originSSCC: string;
  destinationSSCC: string;
  asn: string;
  actionTime: dayjs.Dayjs;
}

export interface RemoveItemSSCCHistoryDataProps {
  create: (data: RemoveItemSSCCHistoryProps) => Promise<RemoveItemSSCCHistoryProps | undefined>;
}

export const removeItemSSCCHistory = () => {
  async function create(data: RemoveItemSSCCHistoryProps): Promise<RemoveItemSSCCHistoryProps | undefined> {
    const created = await DataStore.save(
      new RemoveItemSSCCHistory({
        id: data.id,
        userEmail: data.userEmail,
        reason: data.reason,
        originSSCC: data.originSSCC,
        destinationSSCC: data.destinationSSCC,
        asn: data.asn,
        actionTime: data.actionTime.toISOString()
      })
    );

    if (created) {
      return convertToProps(created);
    }
    return undefined;
  }

  function convertToProps(data: RemoveItemSSCCHistory) {
    const props: RemoveItemSSCCHistoryProps = {
      id: data.id,
      userEmail: data.userEmail || '',
      reason: data.reason || '',
      originSSCC: data.originSSCC || '',
      destinationSSCC: data.destinationSSCC || '',
      asn: data.asn || '',
      actionTime: dayjs(data.actionTime)
    };
    return props;
  }
  const returned: RemoveItemSSCCHistoryDataProps = {
    create
  };

  return returned;
};
