/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Card,
  IconButton,
  Link as MuiLink,
  Stack,
  Table,
  TableBody,
  TableCell,
  Typography,
  useMediaQuery
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import {
  DarkButton,
  DarkUpperSection,
  LightErrorButton,
  LoadingSpinner,
  PageSection,
  RemoveSSCCItemForm,
  StyledButton,
  StyledTableHead,
  StyledTableRow
} from '../../../components';
import { useEffect, useState } from 'react';
import { useAlert, useCurrentUser, useDatastore } from '../../../hooks';
import { ReceiptEventPackageProps, ReceiptEventSiteProps, SSCCProps } from '../../../datastore/ssccs';
import { useNavigate, useParams } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { dateFormat, datetimeFormat, SsccStatus, timeFormat } from '../../../common';
import type { Dimensions } from '../../../common';
import { RemoveItemReasons, RemoveSSCCItemModel } from '../../../components/modals/RemoveSSCCItemForm';
import WeightDimensionsForm from '../../../components/modals/WeightDimensionsForm';
import MoveToStagingForm, { MoveToStagingModel } from '../../../components/modals/MoveToStagingModal';
import dayjs, { Dayjs } from 'dayjs';
import { createJobInBustle } from '../../../common/helperFunctions/bustle';
import { SsccPrintProps } from '../../../../../Common/Types/types';
import { getDestinationAlias, printSscc } from '../../../common/helperFunctions/print';
import LoadingModal from '../../../components/modals/LoadingModal';
import { LocationProps, LocationType } from '../../../datastore/locations';
import { useDisabledPrinterContext } from '../../../hooks/useIsDisabledPrinter';
import DisabledPrintAlert from '../../../components/alerts/DisabledPrintAlert';
import ReassignToSSCCForm from '../../../components/modals/ReassignToSSCCForm';
import { RemoveItemSSCCHistoryProps } from '../../../datastore/removeItemSSCCHistory';
import { yearMonthDateFormat } from '../../../common/dateTimes';
import { ConfirmationDialog } from '../../../components/modals/ConfirmDialog';
import { galaxyNoteThreeDimensions } from '../../../common/strings';
import {
  transportBookingLocationAlias,
  transportBookingLocationOneLine,
  TransportBookingLocationProps
} from '../../../datastore/transportBookingEvent';
import { updateAsnStatus } from '../../../common/helperFunctions/sscc';
import { PackageStatusDate } from '../components/PackageStatusDate';
import { isLocationMatch } from '../../../common/helperFunctions/locationConflicts';
import { WarningConfirmDialog } from '../../../components/modals/WarningConfirmModal';
import WarningButton from '../../../components/buttons/WarningButton';
import EditSsccForm from '../components/EditSsccModal';
import useObserveSscc from '../../../hooks/useSubscribeSscc';

interface PrintParams {
  data: SSCCProps;
  originLocation: LocationProps | null | undefined;
  destinationLocation: LocationProps | null | undefined;
}

export default function SSCCDetails() {
  const { id } = useParams();
  const theme = useTheme();
  const store = useDatastore();
  const alert = useAlert();
  const navigate = useNavigate();
  const user = useCurrentUser();
  const isMobile = useMediaQuery(galaxyNoteThreeDimensions);
  const { isDisabled: isAnyPrinterDisabled } = useDisabledPrinterContext();
  const sscc = useObserveSscc(id || '');
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState<SSCCProps>();
  const [removeItem, setRemoveItem] = useState<ReceiptEventPackageProps | null>(null);
  const [removeLocationName, setRemoveLocationName] = useState<string>('');
  const [openWeightDimensionsModal, setOpenWeightDimensionsModal] = useState(false);
  const [weightDimensionsData, setWeightDimensionsData] = useState<Dimensions>();
  const [openMoveToStagingModal, setOpenMoveToStagingModal] = useState(false);
  const [isCompleteLoading, setIsCompleteLoading] = useState(false);
  const [isPrintLoading, setIsPrintLoading] = useState(false);
  const [openReassignModal, setOpenReassignModal] = useState(false);
  const [ssccChoice, setSsccChoice] = useState<SSCCProps | undefined>();
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [isRemoveReady, setIsRemoveReady] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [history, setHistory] = useState<RemoveItemSSCCHistoryProps & { isReady: boolean }>({
    id: crypto.randomUUID(),
    userEmail: user.email,
    reason: '',
    asn: '',
    originSSCC: '',
    destinationSSCC: '',
    actionTime: dayjs(),
    isReady: false
  });

  useEffect(() => {
    async function getData() {
      if (id) {
        if (!sscc) return;
        if (sscc) {
          setData(sscc);
          setWeightDimensionsData({
            depth: sscc.depth || '',
            height: sscc.height || '',
            width: sscc.width || '',
            weight: sscc.weight || ''
          });
          setIsLoading(false);
        }
      }
    }

    getData();
  }, [sscc]);

  useEffect(() => {
    if (isRemoveReady && ssccChoice && history.asn && history.reason) {
      removeAndUpdateSite();
      setIsRemoveReady(false);
    }
  }, [isRemoveReady, ssccChoice, history]);

  useEffect(() => {
    if (history.isReady) {
      store.removeItemSSCCHistories.create(history);
      setHistory({
        id: crypto.randomUUID(),
        userEmail: user.email,
        reason: '',
        asn: '',
        originSSCC: '',
        destinationSSCC: '',
        actionTime: dayjs(),
        isReady: false
      });
    }
  }, [history]);

  function getTotal() {
    if (!data) return '0 Items';
    const total = data.receiptedEvents?.reduce((acc, site) => acc + (site?.packages?.length || 0), 0);

    return total === 1 ? `${total} Item` : `${total} Items`;
  }

  function getStatusDate() {
    if (data) {
      switch (data.status) {
        case SsccStatus.open:
          return data.open?.format(datetimeFormat);
        case SsccStatus.staged:
          return data.staged?.format(datetimeFormat);
        case SsccStatus.manifested:
          return data.manifested?.format(datetimeFormat);
        case SsccStatus.inTransit:
          return data.inTransit?.format(datetimeFormat);
        case SsccStatus.approaching:
          return data.approaching?.format(datetimeFormat);
        case SsccStatus.delivered:
          return data.delivered?.format(datetimeFormat);
      }
    }
  }

  async function handlePrinting(
    ssccPrintProps: SsccPrintProps[],
    printType: 'ftl_sscc_print' | 'ftl_ssccOpen_print',
    isLargeOrTbfPrint: boolean
  ) {
    try {
      if (ssccPrintProps) {
        const userPrinter = await store.printers.getPrinterForUser(user.cognitoUser.username);
        if (!userPrinter) {
          throw new Error('No printer set, please set a printer and try again');
        }

        const totalQuantity = isLargeOrTbfPrint
          ? ssccPrintProps.length
          : ssccPrintProps.reduce((sum, pkg) => sum + Number(pkg.totalPackages || 0), 0);
        ssccPrintProps.forEach((ssccPackage) => {
          ssccPackage.totalPackages = totalQuantity.toString();
        });
        const printSsccResponse = await printSscc(
          ssccPrintProps,
          userPrinter.printerIP,
          userPrinter.printerPort,
          printType
        );
        if (printSsccResponse.error) {
          throw new Error(printSsccResponse.error);
        }
      }

      alert('Printing completed successfully', 'success');
    } catch (error) {
      alert(`An error occurred when printing: ${error}`, 'error');
      console.error('Printing error: ', error);
    } finally {
      setIsCompleteLoading(false);
    }
  }

  async function onLockSSCC() {
    if (data) {
      data.lock = !data.lock;
      const results = await store.ssccs.update(data);
      if (results) {
        setData(results);
      }
    }
  }

  async function onPrintLabel() {
    try {
      setIsPrintLoading(true);
      if (!data || !data.originLocationId || !data.destinationLocationId) {
        alert('Missing SSCC is weight or dimension fields, try again.', 'warning');
        return;
      }
      const originLocation = await store.locations.getById(data.originLocationId);
      const destinationLocation = await store.locations.getById(data.destinationLocationId);

      await prepareAndPrintSSCC({
        data,
        originLocation,
        destinationLocation
      });
    } catch (error) {
      console.log('Error in onPrintLabel: ', error);
    } finally {
      setIsPrintLoading(false);
    }
  }

  async function onReasonSubmit(item: RemoveSSCCItemModel) {
    if (!data) return;
    const receiptEvent = data.receiptedEvents
      .map((site) => ({
        ...site,
        foundPackage: site.packages.find((pkg) => pkg.id === removeItem?.id)
      }))
      .find((site) => site.foundPackage !== undefined);

    setHistory({
      ...history,
      reason: item.removalReason,
      asn: receiptEvent?.foundPackage?.asn || 'Unknown',
      originSSCC: data.id
    });

    if (item.removalReason === RemoveItemReasons.AssignedToWrongSSCC) {
      setOpenReassignModal(true);
    }
    if (item.removalReason === RemoveItemReasons.RequiredPriorityShipping) {
      await removeAndUpdateSite(
        RemoveItemReasons.RequiredPriorityShipping,
        receiptEvent?.foundPackage?.asn || 'Unknown',
        data.id
      );
    }
    if (item.removalReason === RemoveItemReasons.ItemDoesntFitInCage) {
      setOpenReassignModal(true);
    }
  }

  async function handleDestinationMatch(ssccChoice: string | undefined) {
    if (!ssccChoice) {
      alert('Could not find SSCC, please try again.', 'warning');
      return;
    }

    const sscc = await store.ssccs.get(ssccChoice);
    const originLocation = await store.locations.getNameOrUnknown(removeLocationName);
    if (!sscc || !sscc.destinationLocationId) {
      alert('Could not find SSCC or its destination location.', 'warning');
      return;
    }

    const ssccDestinationLocation = await store.locations.getById(sscc.destinationLocationId);
    if (!ssccDestinationLocation) {
      alert("Could not find SSCC's destination location.", 'warning');
      return;
    }

    sscc.destinationLocation = ssccDestinationLocation;
    setSsccChoice(sscc);

    const isSiteMatch = isLocationMatch(originLocation ?? undefined, ssccDestinationLocation);
    setOpenConfirmDialog(!isSiteMatch);

    if (isSiteMatch) {
      setIsRemoveReady(true);
    }
  }

  async function removeAndUpdateSite(
    reason: string = 'Unknown',
    asn: string = 'Unknown',
    originSSCCId: string = 'Unknown'
  ) {
    try {
      if (!data) return;
      if (ssccChoice) {
        let receiptEvent: ReceiptEventSiteProps = { name: '', site: '', packages: [] };
        const sscc = await store.ssccs.get(ssccChoice?.id);
        if (!sscc) {
          alert('Could not find SSCC, please try again.', 'warning');
          return;
        }
        const currentReceiptEvent = data.receiptedEvents.find((site) =>
          site.packages.find((pkg) => pkg.id === removeItem?.id)
        );
        if (!currentReceiptEvent?.name || !currentReceiptEvent?.site) {
          alert('Error removing item from SSCC, please try again.', 'error');
          return;
        }

        receiptEvent.name = currentReceiptEvent.name;
        receiptEvent.site = currentReceiptEvent.site;
        receiptEvent.packages = currentReceiptEvent.packages;

        if (!removeItem) {
          alert('Error removing item from SSCC, please try again.', 'error');
          return;
        }

        sscc.receiptedEvents.push(receiptEvent);
        await store.ssccs.update(sscc);
      }

      const updatedReceiptedEvents = data.receiptedEvents.filter(
        (r) => !r.packages.some((pkg) => pkg.id === removeItem?.id)
      );

      const updatedData = { ...data, receiptedEvents: updatedReceiptedEvents };

      const updatedSSCC = await store.ssccs.update(updatedData);
      if (!updatedSSCC) {
        alert('Could not remove the selected item, please try again.', 'error');
        return;
      }
      setHistory({
        ...history,
        destinationSSCC: ssccChoice?.id ?? 'N/A',
        reason: history.reason ? history.reason : reason,
        asn: history.asn ? history.asn : asn,
        originSSCC: history.originSSCC ? history.originSSCC : originSSCCId,
        isReady: true
      });
      setData(updatedSSCC);
      alert('Item has been removed', 'success');
    } catch (error) {
      console.error(error);
      alert('Could not remove the selected item, please try again.', 'error');
    } finally {
      setRemoveItem(null);
      setOpenReassignModal(false);
      setSsccChoice(undefined);
      setIsRemoveReady(false);
    }
  }

  async function onWeightDimensionsSubmit(weightDimensionsData: Dimensions) {
    setWeightDimensionsData(weightDimensionsData);
    setOpenWeightDimensionsModal(false);
    setOpenMoveToStagingModal(true);
  }

  async function prepareAndPrintSSCC({ data, originLocation, destinationLocation }: PrintParams): Promise<void> {
    const currentDate = dayjs();

    if (!originLocation || !destinationLocation) {
      alert('Issue getting location for SSCC, try again.', 'warning');
      return;
    }

    const sourceAddress = originLocation.toOneLineAddress();
    const printType = data.status === SsccStatus.open ? 'ftl_ssccOpen_print' : 'ftl_sscc_print';
    let destinationAddress = await getDestinationAddress(
      destinationLocation,
      data.isTransportBooking,
      data.transportBookingDestination ?? undefined
    );
    const destinationAlias = await getDestinationAlias(
      data.isTransportBooking,
      destinationLocation,
      store.locations,
      data.transportBookingDestination ?? undefined
    );

    if (!data.isLargeItem && !data.isTransportBooking) {
      await printSmallItem(data, currentDate, sourceAddress, destinationAddress, destinationAlias, printType);
      return;
    }

    await printLargeOrTransportItem(data, currentDate, sourceAddress, destinationAddress, destinationAlias, printType);
  }

  async function getDestinationAddress(
    destinationLocation: LocationProps,
    isTransportBooking: boolean,
    transportBookingDestination?: TransportBookingLocationProps
  ): Promise<string> {
    if (isTransportBooking && transportBookingDestination) {
      return transportBookingLocationOneLine(transportBookingDestination);
    }

    if (destinationLocation.type === LocationType.subLocation) {
      const parentLocation = await store.locations.getById(destinationLocation.parentLocationId ?? '');
      return parentLocation?.toOneLineAddress() ?? '';
    }

    return destinationLocation.toOneLineAddress();
  }

  async function printSmallItem(
    data: SSCCProps,
    currentDate: Dayjs,
    sourceAddress: string,
    destinationAddress: string,
    destinationAlias: string,
    printType: 'ftl_sscc_print' | 'ftl_ssccOpen_print'
  ): Promise<void> {
    const ssccPrint: SsccPrintProps = {
      createdAt: currentDate.format(yearMonthDateFormat),
      packageNumber: '1',
      totalPackages: '1',
      asn: 'Various',
      sourceAddress,
      destinationAddress,
      destinationAlias,
      containerType: 'Cage',
      length: data.depth || '',
      width: data.width || '',
      height: data.height || '',
      weight: data.weight || '',
      ssccId: data.id,
      nickname: data.nickname || '',
      packageIndex: '0',
      qrCodeId: getQrCodeId(data.receiptedEvents),
      hasDangerousGoods: hasDangerousGoods(data.receiptedEvents)
    };

    await handlePrinting([ssccPrint], printType, false);
  }

  async function printLargeOrTransportItem(
    data: SSCCProps,
    currentDate: Dayjs,
    sourceAddress: string,
    destinationAddress: string,
    destinationAlias: string,
    printType: 'ftl_sscc_print' | 'ftl_ssccOpen_print'
  ): Promise<void> {
    const ssccPrints = data.receiptedEvents.flatMap((site) =>
      site.packages.flatMap((pkg) =>
        Array.from({ length: pkg.quantity || 0 }, (_, index) => ({
          createdAt: currentDate.format(yearMonthDateFormat),
          packageNumber: '0',
          packageIndex: index.toString(),
          totalPackages: site.packages.reduce((acc, p) => acc + Number(p.quantity), 0).toString(),
          asn: pkg.asn ?? 'Unknown',
          sourceAddress,
          destinationAddress,
          destinationAlias,
          containerType: pkg.packageType || '',
          length: pkg.depth || '',
          width: pkg.width || '',
          height: pkg.height || '',
          weight: pkg.weight || '',
          ssccId: data.id,
          nickname: data.nickname || '',
          qrCodeId: pkg.qrCodeId,
          hasDangerousGoods: pkg.hasDangerousGoods || false
        }))
      )
    );

    ssccPrints.forEach((pkg, i) => {
      pkg.packageNumber = (i + 1).toString();
    });

    await handlePrinting(ssccPrints, printType, true);
  }

  function getQrCodeId(receiptedEvents: ReceiptEventSiteProps[]): string {
    return (
      receiptedEvents
        .flatMap((site) => site.packages.map((pkg) => pkg.qrCodeId))
        .find((qrCodeId) => qrCodeId !== undefined && qrCodeId !== null) || 'Unknown'
    );
  }

  function hasDangerousGoods(receiptedEvents: ReceiptEventSiteProps[]): boolean {
    return receiptedEvents.some((site) => site.packages?.some((pkg) => pkg.hasDangerousGoods));
  }

  async function onMoveToStagingSubmit(staging: MoveToStagingModel) {
    setIsCompleteLoading(true);
    if (!data || !staging) return;
    if (
      weightDimensionsData?.depth === '' ||
      weightDimensionsData?.width === '' ||
      weightDimensionsData?.height === '' ||
      weightDimensionsData?.weight === ''
    ) {
      alert('Dimensions and Weight are not set, please set', 'warning');
      onMoveToStagingBack();
      setIsCompleteLoading(false);
      return;
    }
    try {
      const updatedSSCC: SSCCProps = {
        ...data,
        height: weightDimensionsData?.height || '',
        width: weightDimensionsData?.width || '',
        depth: weightDimensionsData?.depth || '',
        weight: weightDimensionsData?.weight || '',
        stagingLocation: staging.location,
        status: SsccStatus.staged,
        staged: dayjs()
      };

      const originLocation = await store.locations.getById(updatedSSCC.originLocationId);
      const destinationLocation = await store.locations.getById(updatedSSCC.destinationLocationId);

      if (!originLocation || !destinationLocation) {
        alert('Issue getting location for SSCC, try again.', 'warning');
        return;
      }

      updateAsnStatus(updatedSSCC, SsccStatus.staged);
      setData(updatedSSCC);

      //To avoid multiple users completing at the same time
      const currentSSCC = await store.ssccs.get(updatedSSCC.id);
      if (currentSSCC?.staged) {
        alert('SSCC has already been submitted', 'error');
        setData(currentSSCC);
        setOpenMoveToStagingModal(false);
        setIsCompleteLoading(false);
        return;
      }
      //To avoid multiple a user completing an SSCC that is being unlocked and updated
      if (!currentSSCC?.lock) {
        alert('SSCC is no longer locked, must lock before completing', 'warning');
        setData(currentSSCC);
        setOpenMoveToStagingModal(false);
        setIsCompleteLoading(false);
        return;
      }
      await store.ssccs.update(updatedSSCC);

      if (!updatedSSCC) {
        alert('Failed to update the SSCC, try again.', 'error');
        return null;
      }
      let bustleDestinationLocation = destinationLocation;
      let parentLocation: LocationProps | undefined = bustleDestinationLocation;
      if (destinationLocation.type === LocationType.subLocation) {
        parentLocation = await store.locations.getById(destinationLocation.parentLocationId ?? '');
        if (!parentLocation) {
          alert('Failed to update the SSCC, try again.', 'error');
          return null;
        }
        bustleDestinationLocation.address = parentLocation.address;
        bustleDestinationLocation.suburb = parentLocation.suburb;
        bustleDestinationLocation.state = parentLocation.state;
        bustleDestinationLocation.postcode = parentLocation.postcode;
      }

      const response = await createJobInBustle(
        updatedSSCC,
        originLocation,
        bustleDestinationLocation,
        parentLocation?.deliveryDays
      );
      if (response.error) {
        throw new Error(response.error);
      }

      await prepareAndPrintSSCC({ data: updatedSSCC, originLocation, destinationLocation });
      alert('Completed SSCC', 'success');
      setOpenMoveToStagingModal(false);
      setIsCompleteLoading(false);
    } catch (error) {
      alert('An error occured when completing the SSCC', 'error');
      console.error(error);
      setOpenMoveToStagingModal(false);
      setIsCompleteLoading(false);
      return null;
    }
  }

  async function handleDeleteSscc() {
    if (!data) return;
    try {
      data.status = SsccStatus.cancelled;
      await store.ssccs.update(data);
      navigate('/manage-ssccs');
      alert('SSCC has been deleted', 'success');
    } catch (error) {
      alert('An error occured when deleting the SSCC', 'error');
      console.error(error);
    }
  }

  async function handleEditSscc(updatedSscc: SSCCProps) {
    if (!data) return;
    try {
      data.stagingLocation = updatedSscc.stagingLocation;
      data.nickname = updatedSscc.nickname;
      await store.ssccs.update(data);
    } catch (error) {
      alert('An error occured when updating the SSCC', 'error');
      console.error(error);
    }
  }

  async function onMoveToStagingBack() {
    setOpenWeightDimensionsModal(true);
    setOpenMoveToStagingModal(false);
  }

  if (!data) return null;
  const canDelete =
    (data.receiptedEvents?.length === 0 || data.receiptedEvents?.every((site) => site.packages?.length === 0)) &&
    data.status === SsccStatus.open;
  const canEdit = data.status === SsccStatus.open || data.status === SsccStatus.staged;
  return (
    <>
      <DarkUpperSection>
        <Stack spacing={2} sx={{ padding: '1em 0' }}>
          <Stack
            direction="row"
            spacing={2}
            alignItems="center"
            sx={{ width: '100%', justifyContent: 'space-between' }}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
              <IconButton onClick={() => navigate('/manage-ssccs', { state: { returnNavigation: true } })}>
                <ArrowBackIcon fontSize="large" sx={{ color: theme.palette.primary.contrastText }} />
              </IconButton>
              <Typography variant="h2" fontWeight="bold" color={theme.palette.primary.contrastText}>
                SSCC {id}
              </Typography>
              <LoadingSpinner isLoading={isLoading}>
                <Typography color={theme.palette.primary.contrastText}>{data.nickname}</Typography>
              </LoadingSpinner>
            </Box>
          </Stack>
          <Stack direction="row" spacing={8} alignItems="center">
            <LoadingSpinner isLoading={isLoading}>
              {isMobile ? (
                <> </>
              ) : (
                <Card
                  sx={{
                    borderColor: theme.palette.primary.contrastText,
                    borderWidth: '1px',
                    borderStyle: 'solid',
                    padding: '0.5em 1.5em',
                    paddingRight: '3em',
                    whiteSpace: 'nowrap',
                    color: theme.palette.primary.contrastText,
                    backgroundColor: theme.palette.text.primary
                  }}>
                  <Typography>{getTotal()}</Typography>
                </Card>
              )}
              <Stack>
                <Typography color={theme.palette.secondary.contrastText} variant="body2" sx={{ whiteSpace: 'nowrap' }}>
                  Staging Location
                </Typography>
                <Typography color={theme.palette.primary.contrastText}>{data.stagingLocation ?? '-'}</Typography>
              </Stack>
              <Stack>
                <Typography color={theme.palette.secondary.contrastText} variant="body2">
                  Status
                </Typography>
                <Typography color={theme.palette.primary.contrastText}>{data.status}</Typography>
              </Stack>
              <Stack>
                <Typography color={theme.palette.secondary.contrastText} variant="body2">
                  Date
                </Typography>
                <Typography color={theme.palette.primary.contrastText}>{getStatusDate() ?? '-'}</Typography>
              </Stack>

              <Stack direction="row" spacing={1} sx={{ flex: 1, justifyContent: 'end' }}>
                {!isAnyPrinterDisabled && !user.isReadOnly && (
                  <DarkButton variant="outlined" onClick={onPrintLabel} sx={{ whiteSpace: 'nowrap' }}>
                    Print Label
                  </DarkButton>
                )}
                {canEdit && (
                  <Stack spacing={1} direction="row">
                    <DarkButton
                      variant="outlined"
                      disabled={user.isReadOnly}
                      onClick={() => setOpenEditDialog(true)}
                      sx={{ whiteSpace: 'nowrap' }}>
                      Edit SSCC
                    </DarkButton>
                  </Stack>
                )}
                {!data.staged && (
                  <DarkButton
                    variant="outlined"
                    onClick={onLockSSCC}
                    sx={{ whiteSpace: 'nowrap' }}
                    disabled={user.isReadOnly}>
                    {data.lock ? 'Unlock SSCC' : 'Lock SSCC'}
                  </DarkButton>
                )}
                {canDelete ? (
                  <WarningButton
                    variant="contained"
                    onClick={() => setOpenDeleteDialog(true)}
                    disabled={!canDelete || user.isReadOnly}
                    sx={{ padding: '0.5em 3em', whiteSpace: 'nowrap' }}>
                    Delete SSCC
                  </WarningButton>
                ) : (
                  <StyledButton
                    variant="contained"
                    sx={{ whiteSpace: 'nowrap' }}
                    onClick={() => setOpenWeightDimensionsModal(true)}
                    disabled={
                      isAnyPrinterDisabled ||
                      !data.lock ||
                      !!data.staged ||
                      !data.receiptedEvents?.some((site) => site.packages?.length > 0) ||
                      user.isReadOnly
                    }>
                    Complete SSCC
                  </StyledButton>
                )}
              </Stack>
            </LoadingSpinner>
          </Stack>
        </Stack>
      </DarkUpperSection>
      <PageSection>
        <Stack spacing={2} sx={{ marginTop: '1em' }}>
          {isAnyPrinterDisabled && <DisabledPrintAlert />}
          <LoadingSpinner isLoading={isLoading}>
            <Table>
              <StyledTableHead>
                <TableCell width={isMobile ? '12%' : '12%'}>PO</TableCell>
                <TableCell width={isMobile ? '14%' : '12%'}>ASN</TableCell>
                <TableCell width={isMobile ? '16%' : '14%'}>ASN Status</TableCell>
                <TableCell width={isMobile ? '12%' : '14%'}>Site</TableCell>
                {!isMobile ? <TableCell width="14%">Supplier</TableCell> : <></>}
                <TableCell width={isMobile ? '14%' : '12%'}>Package</TableCell>
                <TableCell width={isMobile ? '7%' : '7%'}>Qty</TableCell>
                <TableCell width={isMobile ? '12%' : '10%'}>Receipted</TableCell>
                <TableCell />
              </StyledTableHead>
              <TableBody>
                {data.receiptedEvents?.map((site) => {
                  return site.packages?.map((pkg, index) => {
                    const isFirst = site.packages?.indexOf(pkg) === 0;
                    const isLast = site.packages ? site.packages.indexOf(pkg) === site.packages.length - 1 : false;
                    return (
                      <StyledTableRow
                        key={`${site.name}-${pkg.po}-${index}`}
                        sx={
                          isLast
                            ? {}
                            : {
                                '& td': {
                                  padding: '1em 0',
                                  textAlign: 'start',
                                  borderBottomWidth: 0
                                }
                              }
                        }>
                        <TableCell sx={{ fontWeight: 'bold' }}>
                          {isFirst ? (
                            <Stack>
                              <Typography fontWeight="bold">{pkg.po}</Typography>
                              <MuiLink
                                component="a"
                                href={`${process.env.REACT_APP_COUPA_BASE_URL}${pkg.po}`}
                                target="_blank"
                                rel="noopener noreferrer">
                                View
                              </MuiLink>
                            </Stack>
                          ) : (
                            <></>
                          )}
                        </TableCell>
                        <TableCell>{isFirst ? pkg.asn : null}</TableCell>
                        <TableCell>
                          <Stack>
                            <PackageStatusDate pkg={pkg} defaultStatus={data.status ?? ''} />
                          </Stack>
                        </TableCell>
                        <TableCell>
                          {isFirst ? (
                            <Stack>
                              <Typography>
                                {data.isTransportBooking
                                  ? data.transportBookingDestination?.businessName || 'Unknown'
                                  : site.name}
                              </Typography>
                              <Typography variant="body2" color={theme.palette.text.secondary}>
                                {data.isTransportBooking
                                  ? transportBookingLocationAlias(data.transportBookingDestination)
                                  : site.site}
                              </Typography>
                            </Stack>
                          ) : (
                            <></>
                          )}
                        </TableCell>
                        {isMobile ? <></> : <TableCell>{isFirst ? pkg.supplierName : ''}</TableCell>}
                        <TableCell>
                          <Stack>
                            <Typography>{pkg.packageType}</Typography>
                          </Stack>
                        </TableCell>
                        <TableCell>{pkg.quantity}</TableCell>
                        <TableCell>
                          {isFirst ? (
                            <Stack>
                              <Typography>{pkg.items[0]?.receipt?.format(dateFormat)}</Typography>
                              <Typography variant="body2" color={theme.palette.text.secondary}>
                                {pkg.items[0]?.receipt?.format(timeFormat)}
                              </Typography>
                            </Stack>
                          ) : (
                            <></>
                          )}
                        </TableCell>
                        <TableCell>
                          {!data.staged && isFirst ? (
                            <LightErrorButton
                              variant="contained"
                              onClick={() => {
                                setRemoveItem(pkg);
                                setRemoveLocationName(site.name || '');
                              }}
                              disabled={data.lock}>
                              Remove
                            </LightErrorButton>
                          ) : (
                            <></>
                          )}
                        </TableCell>
                      </StyledTableRow>
                    );
                  });
                })}
              </TableBody>
            </Table>
          </LoadingSpinner>
        </Stack>
      </PageSection>
      {removeItem && (
        <RemoveSSCCItemForm
          itemId={removeItem.id}
          open={removeItem !== null}
          onSubmit={onReasonSubmit}
          onClose={() => setRemoveItem(null)}
        />
      )}
      {openWeightDimensionsModal && (
        <WeightDimensionsForm
          open={openWeightDimensionsModal}
          data={weightDimensionsData}
          onSubmit={onWeightDimensionsSubmit}
          onClose={() => setOpenWeightDimensionsModal(false)}
        />
      )}

      {openMoveToStagingModal && (
        <MoveToStagingForm
          open={openMoveToStagingModal}
          onSubmit={onMoveToStagingSubmit}
          onBack={onMoveToStagingBack}
          onClose={() => setOpenMoveToStagingModal(false)}
        />
      )}
      {openReassignModal && (
        <ReassignToSSCCForm
          open={openReassignModal}
          onClose={() => setOpenReassignModal(false)}
          onBack={() => {
            setOpenReassignModal(false);
          }}
          onSubmit={handleDestinationMatch}
          isLargeItem={data.isLargeItem}
          locationName={removeLocationName}
          originSsccId={history.originSSCC}
        />
      )}
      <ConfirmationDialog
        open={openConfirmDialog}
        onClose={() => {
          setOpenConfirmDialog(false);
          setSsccChoice(undefined);
          setIsRemoveReady(false);
        }}
        onConfirm={() => {
          setOpenConfirmDialog(false);
          setIsRemoveReady(true);
        }}
        title="Location Conflict"
        content="The receipted items destination site does not match the SCCC destination. Are you sure wish to continue?"
      />
      <WarningConfirmDialog
        open={openDeleteDialog}
        onClose={() => {
          setOpenDeleteDialog(false);
        }}
        onConfirm={() => {
          setOpenDeleteDialog(false);
          handleDeleteSscc();
        }}
        title="Delete SSCC"
        content="Are you sure that you want to delete this SSCC?"
      />
      <EditSsccForm
        open={openEditDialog}
        sscc={data}
        onSubmit={(updatedSscc) => {
          setOpenEditDialog(false);
          handleEditSscc(updatedSscc);
        }}
        onClose={() => setOpenEditDialog(false)}
      />
      <LoadingModal
        isLoading={isCompleteLoading}
        titleText={'Completing SSCC'}
        bodyText={'Please wait, completing SSCC and Printing'}
      />
      <LoadingModal isLoading={isPrintLoading} titleText={'Printing SSCC'} bodyText={'Please wait, printing'} />
    </>
  );
}
