import { Stack, SxProps, TextField } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Controller, Control, FieldError } from 'react-hook-form';
import ErrorFormInput from '../error';

interface TextFieldFormInputProps {
  parentSx?: SxProps;
  sx?: SxProps;
  required?: boolean;
  control: Control<any>;
  name: string;
  label?: string;
  error?: FieldError;
  multiline?: boolean;
  rows?: number;
  onChange?: (value: string) => void;
}

export default function TextFieldFormInput({
  parentSx,
  sx,
  required = false,
  control,
  name,
  label,
  error,
  multiline = false,
  rows = 1,
  onChange
}: TextFieldFormInputProps) {
  const theme = useTheme();

  return (
    <Stack sx={parentSx}>
      <Controller
        rules={{ required: required }}
        control={control}
        name={name}
        defaultValue={''}
        render={({ field }) => (
          <TextField
            {...field}
            label={label}
            size="small"
            multiline={multiline}
            rows={rows}
            inputProps={{ sx: { padding: '0.6em 1em' } }}
            sx={{
              backgroundColor: theme.palette.background.default,
              borderRadius: '4px',
              minWidth: '12em',
              ...sx
            }}
            onChange={(e) => {
              field.onChange(e);
              if (onChange) {
                onChange(e.target.value);
              }
            }}
          />
        )}
      />
      {error && <ErrorFormInput error={error} />}
    </Stack>
  );
}
