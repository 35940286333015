import { Box, Dialog, Divider, IconButton, Stack, Typography } from '@mui/material';
import { useForm, useFieldArray, SubmitHandler } from 'react-hook-form';
import CloseIcon from '@mui/icons-material/Close';
import StyledButton from '../buttons/StyledButton';
import { useEffect } from 'react';
import { TransportBookingLocationProps } from '../../datastore/transportBookingEvent';
import ReadOnlyBoxField from '../readOnly/ReadOnlyBoxField';
import ReadOnlyCheckbox from '../readOnly/ReadOnlyCheckbox';
import { useCurrentUser } from '../../hooks';

interface ReprintShippingLabelsFormProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (data: ReprintShippingLabelsModel) => Promise<void>;
  data: ReprintShippingLabelsModel;
}

interface Package {
  id: string;
  quantity: number | null;
  packageType: string | null;
  asn: string | null;
  po: string | null;
  purchaseType: string | null;
  priority: string | null;
  jobId: string | null;
  companyName: string | null;
  assetNumber: string | null;
  originLocationId: string;
  destinationLocationId: string;
  hasDangerousGoods: boolean;
  destinationLocationAlias: string;
  isTransportBooking: boolean;
  transportBookingDestination: TransportBookingLocationProps | null;
  ssccId: string;
  shipToAttention: string;
}

export interface ReprintShippingLabelsModel {
  packages: Package[];
}

export default function ReprintShippingLabelsForm({ open, onClose, onSubmit, data }: ReprintShippingLabelsFormProps) {
  const { control, handleSubmit, reset } = useForm({ defaultValues: { packages: data.packages } });
  const user = useCurrentUser();
  const { fields } = useFieldArray({
    control,
    name: 'packages'
  });

  useEffect(() => {
    reset({ packages: data.packages });
  }, [data.packages, reset]);

  const onSubmitForm: SubmitHandler<ReprintShippingLabelsModel> = (data) => onSubmit(data);

  return (
    <Dialog open={open} maxWidth={'md'}>
      <form onSubmit={handleSubmit(onSubmitForm)}>
        <Stack spacing={3} sx={{ padding: '1em 2em' }}>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Typography variant="h4" fontWeight="bold">
              Reprint Shipping Labels
            </Typography>
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Stack>
          <Divider />
          <Typography variant="body2">
            All configured labels for this transaction must be transported together.
          </Typography>

          {fields.map((pkg) => (
            <Stack spacing={1} key={pkg.id} direction="row" justifyContent="space-evenly" alignItems="center">
              <Box>
                <Typography variant="body2" gutterBottom>
                  Quantity
                </Typography>
                <ReadOnlyBoxField value={pkg.quantity?.toString() || ''} />
              </Box>
              <Box>
                <Typography variant="body2" gutterBottom>
                  Package Type
                </Typography>
                <ReadOnlyBoxField value={pkg.packageType || ''} />
              </Box>
              <Box
                sx={{
                  minWidth: '130px',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}>
                <Typography variant="body2" gutterBottom>
                  Dangerous Goods
                </Typography>
                <ReadOnlyCheckbox checked={pkg.hasDangerousGoods} />
              </Box>
            </Stack>
          ))}
          <Divider />
          <StyledButton variant="contained" type="submit" disabled={user.isReadOnly}>
            Reprint Labels
          </StyledButton>
        </Stack>
      </form>
    </Dialog>
  );
}
