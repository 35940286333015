import {
  AppBar,
  Box,
  Button,
  Container,
  Divider,
  IconButton,
  List,
  Stack,
  Tab,
  Tabs,
  Toolbar,
  Tooltip,
  Typography,
  useMediaQuery
} from '@mui/material';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import SettingsIcon from '@mui/icons-material/Settings';
import PrintIcon from '@mui/icons-material/Print';
import ReceiptIcon from '@mui/icons-material/Receipt';
import LogoutIcon from '@mui/icons-material/Logout';
import InventoryIcon from '@mui/icons-material/Inventory';
import { styled, useTheme } from '@mui/material/styles';
import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { PageSectionWithLogo } from '../pageSection';
import { Auth, DataStore } from 'aws-amplify';
import { useAlert, useCurrentUser, useDatastore, useIsOnline } from '../../hooks';
import RaiseQMForm, { RaiseQMModel } from '../modals/RaiseQMForm';
import LoadingModal from '../modals/LoadingModal';
import { CreateQMProps } from '../../api/postQM';
import { createQMCoupa } from '../../common/helperFunctions/coupa';
import WifiOffIcon from '@mui/icons-material/WifiOff';
import { useDirtyContext } from '../../hooks/useDirtyProvider';
import { ConfirmationDialog } from '../modals/ConfirmDialog';
import { useDisabledPrinterContext } from '../../hooks/useIsDisabledPrinter';
import MenuIcon from '@mui/icons-material/Menu';
import Drawer from '@mui/material/Drawer';
import logo from '../../assets/images/headerlogo.png';
import { navbarListItems, navbarListItemsIconEnum } from '../navbar/navbardata';
import NavbarListItem from '../navbar/NavbarListItem';
import { ReactComponent as Logo } from '../../assets/images/mrllogo.svg';
import { galaxyNoteThreeDimensions } from '../../common/strings';
import RaiseQMButton from '../buttons/RaiseQMButton';
import VersionEnvironment from './VersionEnvironment';

interface LinkTabProps {
  label: string;
  onClick: () => void;
}

export default function Header() {
  const { pathname } = useLocation();
  const theme = useTheme();
  const alert = useAlert();
  const { isOnline } = useIsOnline();
  const user = useCurrentUser();
  const isMobile = useMediaQuery(galaxyNoteThreeDimensions);
  const { isDisabled: isAnyPrinterDisabled } = useDisabledPrinterContext();
  const store = useDatastore();
  const navigate = useNavigate();
  const { isDirty, setIsDirty } = useDirtyContext();
  const [pendingTab, setPendingTab] = useState<number>(0);
  const [pendingPath, setPendingPath] = useState<string>('');
  const [openRaiseQM, setOpenRaiseQM] = useState(false);
  const [openDirtyDialog, setOpenDirtyDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [tab, setTab] = useState<number>(
    pathname.includes('/receipt-purchase-orders') || pathname === '/'
      ? 0
      : pathname.includes('/manage-ssccs')
        ? 1
        : pathname.includes('/search-reprint')
          ? 2
          : pathname.includes('/receive-manifest')
            ? 3
            : 4
  );

  const StyledTabs = styled(Tabs)(({ theme }) => ({
    '& .MuiTabs-indicator': {
      top: 0,
      height: '0.4em',
      backgroundColor: theme.palette.secondary.light
    }
  }));

  const LinkTab = styled(({ label, onClick, ...otherProps }: LinkTabProps) => (
    <Tab
      {...otherProps}
      label={label}
      onClick={(event) => {
        event.preventDefault();
        onClick();
      }}
    />
  ))(({ theme }) => ({
    fontSize: '14px',
    color: theme.palette.primary.contrastText,
    marginRight: '1em',
    '&.Mui-selected': {
      color: theme.palette.primary.contrastText,
      fontWeight: theme.typography.fontWeightBold
    }
  }));

  async function onQMSubmit(data: RaiseQMModel) {
    try {
      setIsLoading(true);

      const statuses = (await store.workflowStatuses.getArray())?.filter((s) => s.name === 'Created');
      if (!statuses || !statuses[0].workflowStatusId) {
        alert('Error getting workflow status, please try again.', 'warning');
        return;
      }
      const createdId = statuses[0].workflowStatusId;
      const userPrinter = await store.printers.getPrinterForUser(user.cognitoUser.username);

      if (!userPrinter) {
        alert('Error getting printer, please set your printer and try again', 'error');
        return;
      }

      const createQMPayload: CreateQMProps = {
        workflowStatusId: createdId,
        reasonId: data.reason.reasonId,
        issueId: data.issue.issueId,
        issue: data.issue.name,
        reason: data.reason.name,
        locationName: data.locationName,
        description: data.description,
        purchaseOrderNumber: data.purchaseOrderNumber,
        progress: data.progress,
        // attachments: data.attachments, //@Phase3: Add attachments
        printerIP: userPrinter?.printerIP,
        port: userPrinter?.printerPort,
        numberOfLabels: data.numberOfLabels,
        createdBy: user.name
      };

      const response = await createQMCoupa(createQMPayload);

      if (response.error) {
        alert('Error creating QM', 'error');
        return;
      }

      alert('QM created', 'success');
    } catch (error) {
      alert(`An error occurred when submitting QM: ${error}`, 'error');
      console.error('QM submission error: ', error);
    } finally {
      setIsLoading(false);
      setOpenRaiseQM(false);
    }
  }

  function handleChangeTab(newValue: number, path: string) {
    if (isDirty) {
      setPendingPath(path);
      setPendingTab(newValue);
      setOpenDirtyDialog(true);
    } else {
      setTab(newValue);
      navigate(path);
    }
  }

  function handleDialogConfirm() {
    setOpenDirtyDialog(false);
    setTab(pendingTab);
    navigate(pendingPath);
    setIsDirty(false);
  }

  function getIcon(icon: any) {
    switch (icon) {
      case navbarListItemsIconEnum.RECEIPT:
        return <ReceiptIcon />;
      case navbarListItemsIconEnum.SSCC:
        return <InventoryIcon />;
      case navbarListItemsIconEnum.REPRINT:
        return <PrintIcon />;
      case navbarListItemsIconEnum.MANIFEST:
        return <LocalShippingIcon />;
      case navbarListItemsIconEnum.SETTINGS:
        return <SettingsIcon />;
      case navbarListItemsIconEnum.LOG_OUT:
        return <LogoutIcon />;
    }
  }

  function logoutUser() {
    DataStore.stop().then(() => DataStore.clear());
    Auth.signOut();
  }

  return (
    <>
      <AppBar color="secondary" sx={{ height: '4em' }}>
        {isMobile ? (
          <>
            <Container maxWidth="xl">
              <Toolbar disableGutters>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <IconButton size="large" aria-label="open drawer" onClick={() => setDrawerOpen(true)} color="inherit">
                    <MenuIcon />
                  </IconButton>
                </Box>
                <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'center' }}>
                  <img src={logo} alt="Logo" height="35em" style={{ paddingTop: '0.30em', paddingLeft: '0.50em' }} />
                </Box>
                <Box>
                  <RaiseQMButton
                    isOnline={isOnline}
                    isAnyPrinterDisabled={isAnyPrinterDisabled}
                    setOpenRaiseQM={setOpenRaiseQM}
                  />
                </Box>
              </Toolbar>
            </Container>
            <Drawer anchor="left" open={drawerOpen} onClose={() => setDrawerOpen(false)}>
              <Box
                sx={{
                  width: 250,
                  display: 'flex',
                  flexDirection: 'column',
                  height: '100%'
                }}
                role="presentation"
                onClick={() => setDrawerOpen(false)}
                onKeyDown={() => setDrawerOpen(false)}>
                <Box sx={{ overflow: 'auto' }}>
                  <List>
                    <Logo width="90%" height="50%" />
                    <Divider />
                    {navbarListItems.map((item, index) => (
                      <React.Fragment key={index}>
                        <NavbarListItem
                          text={item.text}
                          icon={getIcon(item.icon)}
                          handleSelect={() => {
                            item.path === '/logout' ? logoutUser() : navigate(item.path);
                          }}
                        />
                        <Divider sx={{ m: 0, p: 0 }} />
                      </React.Fragment>
                    ))}
                  </List>
                </Box>
                <Box sx={{ padding: '1em', mt: 'auto' }}>
                  <Typography variant="body2" color={theme.palette.text.primary}>
                    {isOnline ? (
                      ''
                    ) : (
                      <Tooltip
                        enterTouchDelay={0}
                        title="You are offline. Any data created will sync the next time you are online.">
                        <WifiOffIcon color="info" />
                      </Tooltip>
                    )}
                  </Typography>
                  <Stack>
                    <Box>
                      <Typography variant="body2" color={theme.palette.text.primary}>
                        {process.env.REACT_APP_ENV === 'prod' ? '' : <VersionEnvironment />}
                      </Typography>
                    </Box>
                    <Box>
                      <Typography
                        component="div"
                        variant="body2"
                        color={theme.palette.text.primary}
                        sx={{ maxWidth: '100%', wordWrap: 'break-word' }}>
                        {isOnline ? `${user.name} (${user.role})` : ``}
                      </Typography>
                    </Box>
                  </Stack>
                </Box>
              </Box>
            </Drawer>
          </>
        ) : (
          <PageSectionWithLogo>
            <Stack spacing={4} direction="row" sx={{ flex: 'space-between' }}>
              <StyledTabs
                value={tab}
                onChange={(event, newValue) => {
                  const paths = [
                    '/receipt-purchase-orders',
                    '/manage-ssccs',
                    '/search-reprint',
                    '/receive-manifest',
                    '/settings'
                  ];
                  handleChangeTab(newValue, paths[newValue]);
                }}
                centered>
                <LinkTab
                  label="Receipt Purchase Orders"
                  onClick={() => handleChangeTab(0, '/receipt-purchase-orders')}
                />
                <LinkTab label="Manage SSCCs" onClick={() => handleChangeTab(1, '/manage-ssccs')} />
                <LinkTab label="Search + Reprint" onClick={() => handleChangeTab(2, '/search-reprint')} />
                <LinkTab label="Receive Manifest" onClick={() => handleChangeTab(3, '/receive-manifest')} />
                <LinkTab label="Settings" onClick={() => handleChangeTab(4, '/settings')} />
              </StyledTabs>
              <Stack direction="row" justifyContent="end" spacing={6} sx={{ flex: 1 }}>
                <Stack direction="row" alignItems="center" spacing={2}>
                  <Typography variant="body2" color={theme.palette.primary.contrastText}>
                    {isOnline ? (
                      ''
                    ) : (
                      <Tooltip
                        enterTouchDelay={0}
                        title="You are offline. Any data created will sync the next time you are online.">
                        <WifiOffIcon color="info" />
                      </Tooltip>
                    )}
                  </Typography>
                  <Typography variant="body2" color={theme.palette.primary.contrastText}>
                    {process.env.REACT_APP_ENV === 'prod' ? '' : <VersionEnvironment />}
                  </Typography>
                </Stack>
                <Stack direction="row" spacing={1} alignItems="center">
                  <Typography variant="body2" color={theme.palette.primary.contrastText}>
                    {isOnline ? `${user.name} (${user.role})` : ``}
                  </Typography>
                  <Button onClick={logoutUser} sx={{ textDecoration: 'underline', color: theme.palette.primary.light }}>
                    Logout
                  </Button>
                </Stack>
                <RaiseQMButton
                  isOnline={isOnline}
                  isAnyPrinterDisabled={isAnyPrinterDisabled}
                  setOpenRaiseQM={setOpenRaiseQM}
                />
              </Stack>
            </Stack>
          </PageSectionWithLogo>
        )}
      </AppBar>
      {openRaiseQM && <RaiseQMForm open={openRaiseQM} onClose={() => setOpenRaiseQM(false)} onSubmit={onQMSubmit} />}
      <LoadingModal
        isLoading={isLoading}
        titleText={'Creating QM and Printing'}
        bodyText={'Please wait, creating a QM and printing labels.'}
      />
      <ConfirmationDialog
        open={openDirtyDialog}
        onClose={() => setOpenDirtyDialog(false)}
        onConfirm={handleDialogConfirm}
        title="Unsaved Changes"
        content="You have unsaved changes. Are you sure you want to leave the page?"
      />
    </>
  );
}
