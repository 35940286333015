import { DataStore } from 'aws-amplify';
import { QMReason } from '../models';

export interface QMReasonProps {
  id: string;
  reasonId: string;
  name: string;
}

export interface QMReasonDataProps {
  create: (data: QMReasonProps) => Promise<QMReasonProps | undefined>;
  getArray: () => Promise<QMReasonProps[] | undefined>;
}

export const qmReasonData = () => {
  async function create(data: QMReasonProps): Promise<QMReasonProps | undefined> {
    const created = await DataStore.save(
      new QMReason({
        id: data.id,
        reasonId: data.reasonId,
        name: data.name
      })
    );

    if (created) {
      return convertToProps(created);
    }

    return undefined;
  }

  async function getArray(): Promise<QMReasonProps[] | undefined> {
    const results = await DataStore.query(QMReason);

    if (results) {
      return results.map((r) => convertToProps(r));
    }

    return undefined;
  }

  function convertToProps(data: QMReason) {
    const qmReasonProps: QMReasonProps = {
      id: data.id,
      reasonId: data.reasonId,
      name: data.name
    };
    return qmReasonProps;
  }

  const returned: QMReasonDataProps = {
    create,
    getArray
  };

  return returned;
};
