import { DataStore } from 'aws-amplify';
import dayjs from 'dayjs';
import { AdminActionHistory } from '../models';

export const adminActionType = {
  PRINTING_CREATE: 'CreatePrinterAction',
  PRINTING_UPDATE: 'UpdatePrinterAction',
  LOCATION_CREATE: 'CreateLocationAction',
  LOCATION_UPDATE: 'UpdateLocationAction'
};

export interface AdminActionHistoryProps {
  id: string;
  actionType: string;
  beforeAction: string | null;
  afterAction: string;
  actionBy: string;
  actionTime: dayjs.Dayjs;
}

export interface AdminActionHistoryDataProps {
  create: (data: AdminActionHistoryProps) => Promise<AdminActionHistoryProps | undefined>;
}

export const adminActionHistory = () => {
  async function create(data: AdminActionHistoryProps): Promise<AdminActionHistoryProps | undefined> {
    const created = await DataStore.save(
      new AdminActionHistory({
        id: data.id,
        actionType: data.actionType,
        beforeAction: data.beforeAction,
        afterAction: data.afterAction,
        actionBy: data.actionBy,
        actionTime: data.actionTime.toISOString()
      })
    );

    if (created) {
      return convertToProps(created);
    }
    return undefined;
  }

  function convertToProps(data: AdminActionHistory) {
    const props: AdminActionHistoryProps = {
      id: data.id,
      actionType: data.actionType,
      beforeAction: data.beforeAction || null,
      afterAction: data.afterAction,
      actionBy: data.actionBy,
      actionTime: dayjs(data.actionTime)
    };
    return props;
  }
  const returned: AdminActionHistoryDataProps = {
    create
  };

  return returned;
};
