/* eslint-disable react-hooks/exhaustive-deps */
import {
  MenuItem,
  Pagination,
  Stack,
  Table,
  TableBody,
  TableCell,
  Typography,
  useMediaQuery,
  Link as MuiLink
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import {
  DarkUpperSection,
  LightInfoButton,
  LoadingSpinner,
  PageSection,
  SearchField,
  SearchNotFoundCard,
  StyledSelect,
  StyledTableHead,
  StyledTableRow
} from '../../components';
import SearchIcon from '@mui/icons-material/Search';
import { useEffect, useState } from 'react';
import { useAlert, useCurrentUser, useDatastore, useIsOnline } from '../../hooks';
import { dateFormat, timeFormat } from '../../common';
import dayjs from 'dayjs';
import { SSCCProps, ReceiptEventPackageProps } from '../../datastore/ssccs';
import ReprintShippingLabelsForm, {
  ReprintShippingLabelsModel
} from '../../components/modals/ReprintShippingLabelsForm';
import { printPackages } from '../../common/helperFunctions/print';
import { PackagePrintProps } from '../../../../Common/Types/types';
import LoadingModal from '../../components/modals/LoadingModal';
import { distinct } from '../../common/array';
import OfflineCard from '../../components/cards/OfflineCard';
import DisabledPrintAlert from '../../components/alerts/DisabledPrintAlert';
import { useDisabledPrinterContext } from '../../hooks/useIsDisabledPrinter';
import { yearMonthDateFormat } from '../../common/dateTimes';
import { UnknownLocation, LocationType } from '../../datastore/locations';
import { galaxyNoteThreeDimensions } from '../../common/strings';
import { TransportBookingLocationProps, transportBookingLocationAlias } from '../../datastore/transportBookingEvent';
import { PackageStatusDate } from '../manage-ssccs/components/PackageStatusDate';

interface ExtendedReceiptEventPackageProps extends ReceiptEventPackageProps {
  originLocationId: string;
  destinationLocationId: string;
  isTransportBooking: boolean;
  transportBookingDestination: TransportBookingLocationProps | null;
  destinationLocationAlias: string;
  ssccId: string;
}
export default function SearchReprint() {
  const theme = useTheme();
  const alert = useAlert();
  const { isOnline } = useIsOnline();
  const store = useDatastore();
  const user = useCurrentUser();
  const isMobile = useMediaQuery(galaxyNoteThreeDimensions);
  const { isDisabled: isAnyPrinterDisabled } = useDisabledPrinterContext();
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState<SSCCProps[]>([]);
  const [dateLimit, setDateLimit] = useState<number>(0);
  const [search, setSearch] = useState('');
  const [showReprintModal, setShowReprintModal] = useState(false);
  const [printingData, setPrintingData] = useState<ExtendedReceiptEventPackageProps[]>([]);
  const [printLoading, setPrintLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    async function getData() {
      setIsLoading(true);
      setCurrentPage(1);
      const date = dateLimit && dateLimit !== 0 ? dayjs().subtract(dateLimit, 'days') : null;

      const results = await store.ssccs.getListForReprint(search, date);

      if (results) {
        const sortedSsccs = results.sort((a, b) => {
          const dateA = a.open;
          const dateB = b.open;

          return dateB?.diff(dateA) || 0;
        });
        setData(sortedSsccs);
        setIsLoading(false);
      }
    }
    getData();
  }, [search, dateLimit]);

  async function onReprintLabels(data: ReprintShippingLabelsModel) {
    try {
      const currentDate = dayjs();
      const validPackages = data.packages.filter(
        (pkg) =>
          pkg.quantity && pkg.packageType && pkg.asn && pkg.po && pkg.originLocationId && pkg.destinationLocationId
      );

      if (validPackages.length !== data.packages.length || validPackages.length === 0) {
        alert('There are missing fields in the packages, cant print.', 'error');
        setShowReprintModal(false);
        return;
      }
      const originLocation = await store.locations.getById(validPackages[0].originLocationId);
      const destinationLocation = await store.locations.getById(validPackages[0].destinationLocationId);

      if (!originLocation || !destinationLocation) {
        alert('There was an error getting the origin or destination location, cant print.', 'error');
        setShowReprintModal(false);
        return;
      }

      const sourceAddress = originLocation.toOneLineAddress();
      let destinationAddress = destinationLocation.toOneLineAddress();
      let locationAlias = data.packages[0].destinationLocationAlias;
      if (destinationLocation.type === LocationType.subLocation) {
        const parentLocation = await store.locations.getById(destinationLocation.parentLocationId ?? '');
        destinationAddress = parentLocation?.toOneLineAddress() ?? '';
        locationAlias = parentLocation?.alias || '';
      }

      if (data.packages[0].isTransportBooking) {
        destinationAddress = data.packages[0].transportBookingDestination?.businessName || '';
        locationAlias = transportBookingLocationAlias(data.packages[0].transportBookingDestination) || '';
      }

      const packages: PackagePrintProps[] = validPackages.flatMap((pkg) => {
        return Array.from({ length: pkg.quantity || 0 }, (_, i) => ({
          id: pkg.id,
          createdAt: currentDate.format(yearMonthDateFormat),
          packageNumber: null,
          totalPackages: validPackages.reduce((acc, pkg) => acc + (pkg.quantity || 0), 0),
          sourceAddress: sourceAddress,
          destinationAddress: destinationAddress,
          destinationAlias: locationAlias,
          companyName: pkg.companyName || '',
          containerType: pkg.packageType || null,
          asn: pkg.asn || null,
          poNumber: pkg.po || null,
          purchaseType: pkg.purchaseType || '',
          priority: pkg.priority?.split('-')[0] || '',
          jobId: pkg.jobId || '',
          assetNumber: pkg.assetNumber || '',
          hasDangerousGoods: pkg.hasDangerousGoods || false,
          ssccId: pkg.ssccId,
          shipToAttention: pkg.shipToAttention
        }));
      });
      packages.forEach((pkg, i) => {
        pkg.packageNumber = i + 1;
      });
      setPrintLoading(true);
      const userPrinter = await store.printers.getPrinterForUser(user.cognitoUser.username);

      if (!userPrinter) {
        alert('No printer set, please set a printer and try again', 'warning');
        setShowReprintModal(false);
        return;
      }

      const response = await printPackages(packages, userPrinter.printerIP, userPrinter.printerPort);

      if (response.error) {
        alert('An error occured when printing packages, please try again.', 'error');
        console.error('error: ', response.error);
        setShowReprintModal(false);
        return;
      }

      alert('Reprint labels was succesful', 'success');
    } catch (error) {
      console.log('Error reprinting labels: ', error);
    } finally {
      setPrintLoading(false);
      setShowReprintModal(false);
    }
  }

  function handlePageChange(event: React.ChangeEvent<unknown>, value: number) {
    setCurrentPage(value);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  const pageSize = 10;
  const totalNumberOfPages = Math.ceil(data.length / pageSize);
  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = Math.min(startIndex + pageSize, data.length);
  const paginatedSsccs = data.slice(startIndex, endIndex);
  const emptyResults = paginatedSsccs.every((sscc) =>
    sscc.receiptedEvents.every((event) => event.packages.length === 0)
  );

  return (
    <>
      <DarkUpperSection>
        <Stack spacing={2} sx={{ padding: '1em 0' }}>
          <Typography variant="h2" fontWeight="bold" color={theme.palette.primary.contrastText}>
            Item Search + Reprint Labels
          </Typography>
          <Stack direction="row" spacing={2} alignItems="center">
            <SearchField placeholder="Search" startIcon={<SearchIcon />} onTrigger={setSearch} />
            <StyledSelect value={dateLimit?.toString()} onChange={(value) => setDateLimit(Number(value))}>
              <MenuItem value="0">None</MenuItem>
              <MenuItem value="1">Last 24 Hours</MenuItem>
              <MenuItem value="7">Last 7 Days</MenuItem>
              <MenuItem value="14">Last 14 Days</MenuItem>
            </StyledSelect>
          </Stack>
          <Typography color={theme.palette.primary.contrastText} variant="body2">
            Search by Purchase Order Id, ASN or Shipping Label
          </Typography>
        </Stack>
      </DarkUpperSection>
      {isOnline ? (
        <PageSection>
          <Stack spacing={2} sx={{ marginTop: '1em' }}>
            {isAnyPrinterDisabled && <DisabledPrintAlert />}
            <LoadingSpinner isLoading={isLoading}>
              {emptyResults ? (
                <SearchNotFoundCard />
              ) : (
                <Table>
                  <StyledTableHead>
                    <TableCell width={isMobile ? '9%' : '12%'}>PO</TableCell>
                    <TableCell width={isMobile ? '13%' : '12%'}>ASN</TableCell>
                    <TableCell width={isMobile ? '13%' : '12%'}>ASN Status</TableCell>
                    <TableCell width={isMobile ? '18%' : '14%'}>SSCC #</TableCell>
                    <TableCell width={isMobile ? '16%' : '14%'}>Site</TableCell>
                    <TableCell width={isMobile ? '11%' : '12%'}>Package</TableCell>
                    <TableCell width={isMobile ? '6%' : '5%'}>Qty</TableCell>
                    <TableCell width={isMobile ? '12%' : '12%'}>Receipted</TableCell>
                    <TableCell />
                  </StyledTableHead>
                  <TableBody>
                    {paginatedSsccs.map((sscc) => {
                      return sscc.receiptedEvents?.map((site, index) => {
                        return site.packages?.map((pkg) => {
                          if (!pkg.items) return null;
                          const isFirst = site.packages?.indexOf(pkg) === 0;
                          const isLast = site.packages
                            ? site.packages.indexOf(pkg) === site.packages.length - 1
                            : false;
                          const firstReceiptItem = pkg.items.find((item) => item.receipt);
                          const receiptDate = firstReceiptItem
                            ? dayjs(firstReceiptItem.receipt).format(dateFormat)
                            : null;
                          const receiptTime = firstReceiptItem
                            ? dayjs(firstReceiptItem.receipt).format(timeFormat)
                            : null;
                          return (
                            <StyledTableRow
                              key={`${site}-${pkg.id}-${index}`}
                              sx={
                                isLast
                                  ? {}
                                  : {
                                      '& td': {
                                        padding: '1em 0',
                                        textAlign: 'start',
                                        borderBottomWidth: 0
                                      }
                                    }
                              }>
                              <TableCell sx={{ fontWeight: 'bold' }}>
                                {isFirst ? (
                                  <Stack>
                                    <Typography fontWeight="bold">{pkg.po}</Typography>
                                    <MuiLink
                                      component="a"
                                      href={`${process.env.REACT_APP_COUPA_BASE_URL}${pkg.po}`}
                                      target="_blank"
                                      rel="noopener noreferrer">
                                      View
                                    </MuiLink>
                                  </Stack>
                                ) : null}
                              </TableCell>

                              <TableCell>{isFirst ? pkg.asn : null}</TableCell>
                              <TableCell>
                                <PackageStatusDate pkg={pkg} defaultStatus={sscc.status ?? ''} />
                              </TableCell>
                              <TableCell>
                                {isFirst ? (
                                  <Stack>
                                    <Typography>{sscc.id}</Typography>
                                    <Typography variant="body2" color={theme.palette.text.secondary}>
                                      {sscc.nickname}
                                    </Typography>
                                  </Stack>
                                ) : (
                                  ''
                                )}
                              </TableCell>
                              <TableCell>
                                {isFirst ? (
                                  <Stack>
                                    <Typography>{site.name}</Typography>
                                    <Typography variant="body2" color={theme.palette.text.secondary}>
                                      {site.site}
                                    </Typography>
                                  </Stack>
                                ) : (
                                  <></>
                                )}
                              </TableCell>

                              <TableCell>
                                <Stack>
                                  <Typography>{pkg.packageType}</Typography>
                                </Stack>
                              </TableCell>
                              <TableCell>{pkg.quantity}</TableCell>
                              <TableCell>
                                {isFirst ? (
                                  <Stack>
                                    <Typography>{receiptDate}</Typography>
                                    <Typography variant="body2" color={theme.palette.text.secondary}>
                                      {receiptTime}
                                    </Typography>
                                  </Stack>
                                ) : (
                                  <></>
                                )}
                              </TableCell>
                              <TableCell>
                                {isFirst ? (
                                  <LightInfoButton
                                    variant="contained"
                                    sx={{ whiteSpace: 'nowrap' }}
                                    disabled={isAnyPrinterDisabled}
                                    onClick={() => {
                                      setPrintingData(
                                        site.packages.map((pkg) => ({
                                          ...pkg,
                                          id: pkg.id,
                                          originLocationId: sscc.originLocationId,
                                          destinationLocationId: sscc.destinationLocationId,
                                          isTransportBooking: sscc.isTransportBooking,
                                          transportBookingDestination: sscc.transportBookingDestination,
                                          destinationLocationAlias: site.site || '',
                                          ssccId: sscc.id
                                        }))
                                      );

                                      setShowReprintModal(true);
                                    }}>
                                    {isMobile ? 'Reprint' : 'Reprint Labels'}
                                  </LightInfoButton>
                                ) : (
                                  <></>
                                )}
                              </TableCell>
                            </StyledTableRow>
                          );
                        });
                      });
                    })}
                  </TableBody>
                </Table>
              )}
            </LoadingSpinner>
          </Stack>
          <Stack spacing={2} direction="row" justifyContent="center" sx={{ paddingTop: '1em', paddingBottom: '1em' }}>
            <Pagination count={totalNumberOfPages} page={currentPage} onChange={handlePageChange} color="primary" />
          </Stack>
        </PageSection>
      ) : (
        <PageSection>
          <Stack justifyContent="center">
            <OfflineCard />
          </Stack>
        </PageSection>
      )}
      <ReprintShippingLabelsForm
        open={showReprintModal}
        onSubmit={onReprintLabels}
        onClose={() => setShowReprintModal(false)}
        data={{
          packages: printingData.map((printData) => {
            return {
              id: printData.id,
              quantity: printData.quantity || null,
              packageType: printData.packageType || null,
              asn: printData.asn || null,
              po: printData.po || null,
              purchaseType: distinct(printData.items?.map((i) => i?.purchaseType).filter(Boolean)).join(', ') || null,
              priority: printData.priority || null,
              jobId: printData.jobId || null,
              assetNumber: printData.assetNumber || null,
              companyName: printData.companyName || null,
              originLocationId: printData.originLocationId,
              destinationLocationId: printData.destinationLocationId,
              destinationLocationAlias: printData.destinationLocationAlias || UnknownLocation.Alias,
              hasDangerousGoods: printData.hasDangerousGoods || false,
              isTransportBooking: printData.isTransportBooking,
              transportBookingDestination: printData.transportBookingDestination || null,
              ssccId: printData.ssccId,
              shipToAttention: printData.shipToAttention
            };
          })
        }}
      />
      <LoadingModal isLoading={printLoading} titleText="Printing" bodyText="Please wait, printing packages" />
    </>
  );
}
