import { DataStore } from 'aws-amplify';
import dayjs from 'dayjs';
import {
  Manifest,
  ManifestAddress,
  ManifestCarrier,
  ManifestInvLineItem,
  ManifestLeg,
  ManifestPackage,
  ManifestSSCC,
  TripSheet
} from '../models';
import { castStringToEnum } from '../common/enum';

export interface TripSheetProps {
  id: string;
  carrier: ManifestCarrierProps;
  legs: ManifestLegProps[];
}

export interface ManifestCarrierProps {
  organisation: string;
  businessName: string;
  name: string;
  number: string;
  contractor: boolean;
  driver: string;
  secondaryDriver: string;
}

export interface ManifestAddressProps {
  businessName: string;
  addressLine1: string;
  addressLine2: string;
  suburb: string;
  postCode: string;
  state: string;
}

export interface ManifestLegProps {
  id: string;
  status: string;
  origin: ManifestAddressProps;
  destination: ManifestAddressProps;
  departureDate: dayjs.Dayjs;
  arrivalDate: dayjs.Dayjs;
  manifests: ManifestProps[];
}

export enum ManifestStatus {
  open = 'Open',
  completed = 'Completed'
}

export interface ManifestProps {
  id: string; //this was 'reference'
  status: ManifestStatus;
  tripId: string;
  trailerId: string;
  ssccs: ManifestSSCCProps[]; //this was 'jobs'
}

export interface ManifestSSCCProps {
  //this was 'Job'
  ssccId: string; //this was 'reference'
  packages: ManifestPackageProps[];
  totalWeight: number;
}

//we will consolidate all 'line items' up into the package level if they exist.
export interface ManifestPackageProps {
  id: string; //this is new we need to generate from backend
  receivedStatus: string; // this is a status we use in FTL to indicate if the package is received, not received or partially received.
  quantity: number; //this is created in FTL
  maxQuantity: number; // this was 'count'
  asn: string; //this was 'reference'
  packageType: string; //this was 'type'
  height: string;
  width: string;
  depth: string;
  asnExistsOnSscc?: boolean;
  poNumber?: string;
  inventoryLines?: ManifestPackageInvLineItemProps[];
}

export interface ManifestPackageInvLineItemProps {
  orderLineId: number;
  partId: number;
  sourcePartId: string;
  description?: string;
  quantityReceived: number;
  packageType?: string;
  purchaseType?: string;
  receivingWarehouseId?: number;
  receivingWarehouseDefaultLocationId?: number;
  inTransitWarehouseId?: number;
  inTransitWarehouseLocationId?: number;
}

export interface TripSheetsDataProps {
  get: (id: string) => Promise<TripSheetProps | undefined>;
  getList: () => Promise<TripSheetProps[] | undefined>;
  create: (data: TripSheetProps) => Promise<TripSheetProps | undefined>;
  update: (data: TripSheetProps) => Promise<TripSheetProps | undefined>;
}

export const tripSheetsData = () => {
  async function get(id: string) {
    const result = await DataStore.query(TripSheet, id);
    if (result) {
      return convertTripSheetToProps(result);
    }
    return undefined;
  }

  async function getList() {
    const results = await DataStore.query(TripSheet);
    return results.map((r) => convertTripSheetToProps(r)).filter((r): r is TripSheetProps => r !== undefined);
  }

  async function create(data: TripSheetProps) {
    const created = await DataStore.save(
      new TripSheet({
        id: data.id,
        carrier: data.carrier,
        legs: data.legs.map((leg) => ({
          id: leg.id,
          status: leg.status,
          destination: leg.destination,
          origin: leg.origin,
          departureDate: leg.departureDate.toISOString(),
          arrivalDate: leg.arrivalDate.toISOString(),
          manifests: leg.manifests
        }))
      })
    );
    if (created) {
      return convertTripSheetToProps(created);
    }
  }

  async function update(data: TripSheetProps) {
    const original = await DataStore.query(TripSheet, data.id);
    if (original) {
      const updated = await DataStore.save(
        TripSheet.copyOf(original, (updated) => {
          updated.carrier = data.carrier;
          updated.legs = data.legs.map((leg) => ({
            id: leg.id,
            status: leg.status,
            destination: leg.destination,
            origin: leg.origin,
            departureDate: leg.departureDate.toISOString(),
            arrivalDate: leg.arrivalDate.toISOString(),
            manifests: leg.manifests
          }));
        })
      );
      if (updated) {
        return convertTripSheetToProps(updated);
      }
    }
  }

  const returned: TripSheetsDataProps = {
    get,
    getList,
    create,
    update
  };

  return returned;
};

export function convertTripSheetToProps(tripSheet: TripSheet): TripSheetProps | undefined {
  if (!tripSheet || !tripSheet.id || !tripSheet.legs) {
    console.info(`TripSheet is null or incomplete ${tripSheet}`);
    return undefined;
  }

  return {
    id: tripSheet.id,
    carrier: convertManifestCarrier(tripSheet.carrier),
    legs: tripSheet.legs
      .map((leg) => convertManifestLeg(leg))
      .filter((leg): leg is ManifestLegProps => leg !== undefined)
  };
}

function convertManifestLeg(leg: ManifestLeg | null | undefined): ManifestLegProps | undefined {
  if (!leg || !leg.status || !leg.destination || !leg.departureDate || !leg.arrivalDate || !leg.manifests) {
    console.info(`Leg is null or incomplete. ${leg}`);
    return undefined;
  }
  return {
    id: leg.id,
    status: leg.status,
    origin: convertManifestAddress(leg.origin),
    destination: convertManifestAddress(leg.destination),
    departureDate: dayjs(leg.departureDate),
    arrivalDate: dayjs(leg.arrivalDate),
    manifests: leg.manifests
      .map((manifest) => convertManifest(manifest))
      .filter((manifest): manifest is ManifestProps => manifest !== undefined)
  };
}

function convertManifest(manifest: Manifest | null | undefined): ManifestProps | undefined {
  if (!manifest) {
    console.info(`Manifest is null or incomplete: ${JSON.stringify(manifest)}`);
    return undefined;
  }

  const ssccsArray = manifest.ssccs || [];

  return {
    id: manifest.id,
    tripId: manifest.tripId || '',
    trailerId: manifest.trailerId || '',
    status: castStringToEnum(ManifestStatus, manifest.status) ?? ManifestStatus.open,
    ssccs: ssccsArray
      .map((sscc) => convertManifestSSCC(sscc))
      .filter((sscc): sscc is ManifestSSCCProps => sscc !== undefined)
  };
}

function convertManifestSSCC(sscc: ManifestSSCC | null | undefined): ManifestSSCCProps | undefined {
  if (!sscc || !sscc.ssccId || !sscc.packages) {
    console.info(`SSCC is null or incomplete ${JSON.stringify(sscc)}`);
    return undefined;
  }
  return {
    ssccId: sscc.ssccId,
    packages: sscc.packages
      .map((pkg) => convertManifestPackage(pkg))
      .filter((pkg): pkg is ManifestPackageProps => pkg !== undefined),
    totalWeight: sscc.totalWeight || 0
  };
}

function convertManifestPackage(pkg: ManifestPackage | null | undefined): ManifestPackageProps | undefined {
  if (!pkg || !pkg.id || !pkg?.receivedStatus || !pkg.packageType || !pkg.maxQuantity) {
    console.info(`Package is null or incomplete ${pkg}`);
    return undefined;
  }

  return {
    id: pkg.id,
    receivedStatus: pkg.receivedStatus ?? '',
    quantity: pkg.quantity ?? 0,
    maxQuantity: pkg.maxQuantity,
    poNumber: pkg.poNumber ?? '',
    asn: pkg?.asn ?? '',
    packageType: pkg?.packageType,
    height: pkg?.height ?? '',
    width: pkg?.width ?? '',
    depth: pkg?.depth ?? '',
    inventoryLines: (pkg?.inventoryLines ?? []).map((line) => convertManifestPackageLineItems(line))
  };
}

function convertManifestPackageLineItems(
  line: ManifestInvLineItem | null | undefined
): ManifestPackageInvLineItemProps {
  if (!line) {
    console.info(`Package Asn line is null`);
  }
  if (!line?.orderLineId || !line.quantityReceived || !line.purchaseType) {
    console.warn(`Package Asn line is incomplete`);
  }
  const itm: ManifestPackageInvLineItemProps = {
    orderLineId: line?.orderLineId ?? 0,
    partId: line?.partId || 0,
    sourcePartId: line?.sourcePartId ?? '',
    description: line?.description ?? undefined,
    quantityReceived: line?.quantityReceived ?? 0,
    purchaseType: line?.purchaseType ?? undefined,
    receivingWarehouseId: line?.receivingWarehouseId ?? undefined,
    receivingWarehouseDefaultLocationId: line?.receivingWarehouseDefaultLocationId ?? undefined,
    inTransitWarehouseId: line?.inTransitWarehouseId ?? undefined,
    inTransitWarehouseLocationId: line?.inTransitWarehouseLocationId ?? undefined
  };
  return itm;
}

function convertManifestAddress(address: ManifestAddress | null | undefined): ManifestAddressProps {
  return {
    businessName: address?.businessName || 'Unknown',
    addressLine1: address?.addressLine1 || 'Unknown',
    addressLine2: address?.addressLine2 || '',
    suburb: address?.suburb || '',
    postCode: address?.postCode || '',
    state: address?.state || ''
  };
}

function convertManifestCarrier(carrier: ManifestCarrier | null | undefined): ManifestCarrierProps {
  return {
    organisation: carrier?.organisation || 'Unknown',
    businessName: carrier?.businessName || 'Unknown',
    name: carrier?.name || 'Unknown',
    number: carrier?.number || '',
    contractor: carrier?.contractor || false,
    driver: carrier?.driver || 'Unknown',
    secondaryDriver: carrier?.secondaryDriver || ''
  };
}
