/* eslint-disable react-hooks/exhaustive-deps */
import { Dialog, Divider, FormControlLabel, IconButton, Radio, RadioGroup, Stack, Typography } from '@mui/material';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import CloseIcon from '@mui/icons-material/Close';
import StyledButton from '../buttons/StyledButton';

interface RemoveSSCCItemFormProps {
  itemId: string;
  open: boolean;
  onSubmit: (data: RemoveSSCCItemModel) => void;
  onClose: () => void;
}

export interface RemoveSSCCItemModel {
  itemId: string;
  removalReason: string;
}

export enum RemoveItemReasons {
  AssignedToWrongSSCC = 'Assigned to the wrong SSCC',
  RequiredPriorityShipping = 'Required priority shipping',
  ItemDoesntFitInCage = 'Item doesn`t fit in cage'
}

const reasons = ['Assigned to the wrong SSCC', 'Required priority shipping', 'Item doesn`t fit in cage'];

export default function RemoveSSCCItemForm({ open, itemId, onSubmit, onClose }: RemoveSSCCItemFormProps) {
  const defaultValues: RemoveSSCCItemModel = {
    itemId: itemId,
    removalReason: reasons[0]
  };

  const { handleSubmit, control } = useForm({ defaultValues });

  const onSubmitForm: SubmitHandler<RemoveSSCCItemModel> = (data) => onSubmit(data);

  return (
    <Dialog open={open} maxWidth="xs" fullWidth>
      <form onSubmit={handleSubmit(onSubmitForm)}>
        <Stack spacing={3} sx={{ padding: '1em 2em' }}>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Typography variant="h4" fontWeight="bold">
              Remove this item?
            </Typography>
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Stack>
          <Typography>You are about to remove the selected item from this SSCC, are you sure?</Typography>
          <Divider />
          <Stack spacing={1}>
            <Typography>Destination Site</Typography>
            <Controller
              control={control}
              name="removalReason"
              render={({ field }) => (
                <RadioGroup {...field}>
                  {reasons.map((reason) => {
                    return <FormControlLabel key={reason} value={reason} control={<Radio />} label={reason} />;
                  })}
                </RadioGroup>
              )}
            />
          </Stack>

          <StyledButton variant="contained" type="submit">
            Complete
          </StyledButton>
        </Stack>
      </form>
    </Dialog>
  );
}
