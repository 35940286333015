import { Alert, Typography, Link } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import PrintDisabledIcon from '@mui/icons-material/PrintDisabled';

export default function DisabledPrintAlert() {
  const navigate = useNavigate();
  return (
    <Alert
      severity="error"
      variant="filled"
      icon={<PrintDisabledIcon />}
      sx={{ display: 'flex', alignItems: 'center' }}>
      <Typography variant="body2" sx={{ flex: 1, textAlign: 'center' }}>
        <b>Error:</b> A Printer in your settings is disabled, some functionality is restricted. Please select a new
        printer in{' '}
        <Link
          component="button"
          onClick={() => navigate('/settings')}
          sx={{ cursor: 'pointer', textDecoration: 'underline', paddingBottom: '0.2em' }}>
          Settings
        </Link>
        .
      </Typography>
    </Alert>
  );
}
