import dayjs from 'dayjs';
import { BaseEvent, PackagePrintProps, PrintDetailPayloads, SsccPrintProps } from '../../../../Common/Types/types';
import { postPrintItem } from '../../api/printItem';
import { ReceiptEventPackageProps } from '../../datastore/ssccs';
import { LocationDataProps, LocationProps, LocationType, UnknownLocation } from '../../datastore/locations';
import { distinct } from '../array';
import { yearMonthDateFormat } from '../dateTimes';
import {
  TransportBookingLocationProps,
  transportBookingLocationAlias,
  transportBookingLocationOneLine
} from '../../datastore/transportBookingEvent';

export async function printPackages(printEvents: PackagePrintProps[], printerIP: string, port: string) {
  const printDetail: PrintDetailPayloads = {
    payload: printEvents,
    printerIP: printerIP,
    port: port
  };

  const event: BaseEvent<'ftl_package_print', PrintDetailPayloads> = {
    id: crypto.randomUUID(),
    source: 'ftl',
    'detail-type': 'ftl_package_print',
    detail: printDetail
  };

  const serializedEvent = JSON.stringify(event);
  const response = await postPrintItem(serializedEvent);

  return response;
}

export async function printSscc(
  printEvents: Partial<SsccPrintProps>[],
  printerIP: string,
  port: string,
  printType: 'ftl_sscc_print' | 'ftl_ssccOpen_print' = 'ftl_sscc_print'
) {
  const printDetail: PrintDetailPayloads = {
    payload: printEvents,
    printerIP: printerIP,
    port: port
  };

  const event: BaseEvent<'ftl_sscc_print' | 'ftl_ssccOpen_print', PrintDetailPayloads> = {
    id: crypto.randomUUID(),
    source: 'ftl',
    'detail-type': printType,
    detail: printDetail
  };

  const serializedEvent = JSON.stringify(event);
  const response = await postPrintItem(serializedEvent);

  return response;
}

export function preparePackagesForPrinting(
  validPackages: ReceiptEventPackageProps[],
  originLocation: LocationProps,
  destinationLocation: LocationProps,
  ssccId: string,
  transportBookingLocation?: TransportBookingLocationProps | null
): PackagePrintProps[] {
  const currentDate = dayjs();
  const sourceAddress = originLocation.toOneLineAddress();
  const destinationAddress = destinationLocation.toOneLineAddress();

  const packages: PackagePrintProps[] = validPackages.flatMap((pkg) => {
    return Array.from({ length: pkg.quantity || 0 }, (_, i) => ({
      id: pkg.id,
      createdAt: currentDate.format(yearMonthDateFormat),
      packageNumber: null,
      totalPackages: validPackages.reduce((acc, pkg) => acc + (pkg.quantity || 0), 0),
      sourceAddress: sourceAddress,
      destinationAddress: transportBookingLocation
        ? transportBookingLocationOneLine(transportBookingLocation)
        : destinationAddress,
      destinationAlias: transportBookingLocation
        ? transportBookingLocationAlias(transportBookingLocation)
        : destinationLocation.alias || '',
      containerType: pkg.packageType || null,
      asn: pkg.asn || null,
      companyName: pkg.companyName || '',
      poNumber: pkg.po || null,
      purchaseType: distinct(pkg.items?.map((i) => i?.purchaseType || '').filter(Boolean)).join(', '),
      priority: pkg.priority?.split('-')[0] || '',
      jobId: pkg.jobId || '',
      shipToAttention: pkg.shipToAttention || '',
      assetNumber: pkg.assetNumber || '',
      hasDangerousGoods: pkg.hasDangerousGoods || false,
      ssccId: ssccId
    }));
  });
  packages.forEach((pkg, i) => {
    pkg.packageNumber = i + 1;
  });
  return packages;
}

export async function getDestinationAlias(
  isTransportBooking: boolean,
  destinationLocation: { name: string; alias: string },
  locationApi: LocationDataProps,
  transportBookingDestinationLocation?: TransportBookingLocationProps
): Promise<string> {
  if (isTransportBooking && transportBookingDestinationLocation) {
    return (
      (await getAliasFromBusinessName(transportBookingDestinationLocation.businessName, locationApi)) ||
      transportBookingLocationAlias(transportBookingDestinationLocation)
    );
  }

  return (
    (await getAliasFromLocationName(destinationLocation.name, locationApi)) ||
    destinationLocation.alias ||
    UnknownLocation.Alias.toString()
  );
}

async function getAliasFromBusinessName(businessName: string, locationApi: LocationDataProps): Promise<string | null> {
  const result = await locationApi.getNameOrUnknown(businessName);
  if (!result) return null;
  return await getAliasFromResult(result, locationApi);
}

async function getAliasFromLocationName(locationName: string, locationApi: LocationDataProps): Promise<string | null> {
  const result = await locationApi.getNameOrUnknown(locationName);
  if (!result) return null;
  return await getAliasFromResult(result, locationApi);
}

async function getAliasFromResult(
  result: LocationProps | null,
  locationApi: LocationDataProps
): Promise<string | null> {
  if (result?.type === LocationType.mainLocation) {
    return result.alias || null;
  }
  if (result?.parentLocationId) {
    const parentLocation = await locationApi.getById(result.parentLocationId);
    return parentLocation?.alias || null;
  }
  return null;
}
