/* eslint-disable react-hooks/exhaustive-deps */
import { Dialog, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import StyledButton from '../buttons/StyledButton';
import { SubmitHandler, useForm } from 'react-hook-form';
import { TextFieldFormInput } from '../formInputs';
import { useIsOnline } from '../../hooks';

interface MoveToStagingFormProps {
  open: boolean;
  onSubmit: (data: MoveToStagingModel) => void;
  onClose: () => void;
  onBack: () => void;
}

export interface MoveToStagingModel {
  location: string;
}

export default function MoveToStagingForm({ open, onSubmit, onClose, onBack }: MoveToStagingFormProps) {
  const {
    handleSubmit,
    control,
    formState: { errors }
  } = useForm<MoveToStagingModel>();
  const { isOnline } = useIsOnline();
  const onSubmitForm: SubmitHandler<MoveToStagingModel> = (data) => onSubmit(data);

  return (
    <Dialog open={open} maxWidth="xs" fullWidth>
      <form onSubmit={handleSubmit(onSubmitForm)}>
        <Stack spacing={3} sx={{ padding: '1em 2em' }}>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <IconButton onClick={onBack}>
              <ArrowBackIcon />
            </IconButton>
            <Typography variant="h4" fontWeight="bold">
              Move to Staging
            </Typography>
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Stack>
          <Typography variant="body2">
            In order to track SSCCs in the DC, please select the appropriate staging location.
          </Typography>
          <TextFieldFormInput
            name="location"
            label="Optional: Enter Staging Location"
            control={control}
            error={errors['location']}
          />
          {isOnline ? (
            <StyledButton variant="contained" type="submit">
              Complete + Print
            </StyledButton>
          ) : (
            <Tooltip title="You are offline. This feature is not available when offline." enterTouchDelay={0}>
              <StyledButton variant="contained">Complete + Print</StyledButton>
            </Tooltip>
          )}
        </Stack>
      </form>
    </Dialog>
  );
}
