import { InputAdornment, Stack, SxProps, TextField } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Controller, Control, FieldError } from 'react-hook-form';
import ErrorFormInput from '../error';
import { NumericFormat } from 'react-number-format';

interface NumberFieldFormInputProps {
  parentSx?: SxProps;
  sx?: SxProps;
  required?: boolean;
  control: Control<any>;
  name: string;
  label?: string;
  min?: number;
  max?: number;
  endIcon?: JSX.Element;
  error?: FieldError;
  onChange?: (value: string) => void;
  wholeNumbersOnly?: boolean;
}

export default function NumberFieldFormInput({
  parentSx,
  sx,
  required = false,
  control,
  name,
  label,
  min,
  max,
  error,
  endIcon,
  onChange,
  wholeNumbersOnly
}: NumberFieldFormInputProps) {
  const theme = useTheme();
  return (
    <Stack sx={parentSx}>
      <Controller
        control={control}
        name={name}
        defaultValue={''}
        rules={{
          required: required,
          max: max ?? Number.MAX_SAFE_INTEGER,
          validate: (value) => {
            const number = Number(value);
            const minimum = min ?? 1;

            if (wholeNumbersOnly && !Number.isInteger(number)) {
              return 'Enter whole number';
            }

            return required && (isNaN(number) || number < minimum) ? 'Invalid value.' : true;
          }
        }}
        render={({ field: { name, value, onChange: onFieldChange } }) => (
          <NumericFormat
            size="small"
            name={name}
            value={value}
            valueIsNumericString
            onValueChange={(values) => {
              onFieldChange(values.value);
              if (onChange) {
                onChange(values.value);
              }
            }}
            customInput={TextField}
            label={label}
            InputProps={{
              endAdornment: endIcon ? <InputAdornment position="end">{endIcon}</InputAdornment> : null
            }}
            inputProps={{ sx: { padding: '0.6em 1em' } }}
            sx={{
              backgroundColor: theme.palette.background.default,
              borderRadius: '4px',
              minWidth: '12em',
              ...sx
            }}
          />
        )}
      />
      {error && <ErrorFormInput error={error} />}
    </Stack>
  );
}
