import { DataStore } from 'aws-amplify';
import dayjs from 'dayjs';
import { ReceiptEventPackageHistory } from '../models';

export interface ReceiptEventPackageHistoryProps {
  id: string;
  asn: string;
  receiptType: string;
  associatedSSCC: string;
  receiptedBy: string;
  actionTime: dayjs.Dayjs;
}

export interface ReceiptEventPackageHistoryDataProps {
  create: (data: ReceiptEventPackageHistoryProps) => Promise<ReceiptEventPackageHistoryProps | undefined>;
}

export const receiptEventPackageHistory = () => {
  async function create(data: ReceiptEventPackageHistoryProps): Promise<ReceiptEventPackageHistoryProps | undefined> {
    const created = await DataStore.save(
      new ReceiptEventPackageHistory({
        id: data.id,
        asn: data.asn,
        receiptType: data.receiptType,
        associatedSSCC: data.associatedSSCC,
        receiptedBy: data.receiptedBy,
        actionTime: data.actionTime.toISOString()
      })
    );

    if (created) {
      return convertToProps(created);
    }
    return undefined;
  }

  function convertToProps(data: ReceiptEventPackageHistory) {
    const props: ReceiptEventPackageHistoryProps = {
      id: data.id,
      asn: data.asn || '',
      receiptType: data.receiptType || '',
      associatedSSCC: data.associatedSSCC || '',
      receiptedBy: data.receiptedBy || '',
      actionTime: dayjs(data.actionTime)
    };
    return props;
  }
  const returned: ReceiptEventPackageHistoryDataProps = {
    create
  };

  return returned;
};
