import { Typography } from '@mui/material';
import { FieldError } from 'react-hook-form';
import { useTheme } from '@mui/material/styles';

interface ErrorFormInputProps {
  error?: FieldError;
}

export default function ErrorFormInput({ error }: ErrorFormInputProps) {
  const theme = useTheme();

  if (error?.type === 'required') {
    return <Typography color={theme.palette.error.main}>This is required.</Typography>;
  }

  return <Typography color={theme.palette.error.main}>{error?.message}</Typography>;
}
