import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline, CircularProgress } from '@mui/material';
import { themeOptions } from './themes/themes';
import { RouterProvider } from 'react-router-dom';
import { router } from './Routes';
import { AlertProvider, DatastoreProvider, IsOnlineProvider, LoadingProvider, UserLoginProvider } from './hooks';
import { ServiceWorkerUpdate } from './components';

function App() {
  return (
    <ThemeProvider theme={themeOptions}>
      <CssBaseline />
      <IsOnlineProvider>
        <ServiceWorkerUpdate>
          <LoadingProvider>
            <UserLoginProvider>
              <DatastoreProvider>
                <AlertProvider>
                  <RouterProvider router={router} fallbackElement={<CircularProgress />} />
                </AlertProvider>
              </DatastoreProvider>
            </UserLoginProvider>
          </LoadingProvider>
        </ServiceWorkerUpdate>
      </IsOnlineProvider>
    </ThemeProvider>
  );
}

export default App;
