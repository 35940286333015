import { Button, ButtonProps } from '@mui/material';
import { styled } from '@mui/material/styles';

export const WarningButton = styled(Button)<ButtonProps>(({ theme }) => ({
  backgroundColor: theme.palette.error.main,
  color: theme.palette.primary.contrastText,
  '&:hover': {
    backgroundColor: theme.palette.error.dark
  },
  '&.Mui-disabled': {
    backgroundColor: theme.palette.text.secondary,
    color: theme.palette.primary.contrastText
  }
}));

export default WarningButton;
