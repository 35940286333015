import { InputAdornment, Stack, SxProps, TextField } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Controller, Control, FieldError } from 'react-hook-form';
import ErrorFormInput from '../error';
import dayjs from 'dayjs';

interface DateFieldFormInputProps {
  parentSx?: SxProps;
  sx?: SxProps;
  required?: boolean;
  control: Control<any>;
  name: string;
  label?: string;
  minDate?: string;
  maxDate?: string;
  error?: FieldError;
  endIcon?: JSX.Element;
  onChange?: (value: string) => void;
}

export default function DateFieldFormInput({
  parentSx,
  sx,
  required = false,
  control,
  name,
  label,
  minDate,
  maxDate,
  error,
  endIcon,
  onChange
}: DateFieldFormInputProps) {
  const theme = useTheme();

  return (
    <Stack sx={parentSx}>
      <Controller
        control={control}
        name={name}
        defaultValue=""
        rules={{
          required: required ? 'This field is required' : false,
          validate: (value) => {
            if (dayjs(value).isAfter(dayjs(maxDate))) {
              return 'Date cannot be in the future.';
            }
            if (dayjs(value).isBefore(dayjs(minDate))) {
              return `Date cannot be before ${minDate}.`;
            }
            return true;
          }
        }}
        render={({ field: { onChange: onFieldChange, value, ref } }) => (
          <TextField
            type="date"
            size="small"
            name={name}
            value={value || ''}
            onChange={(e) => {
              onFieldChange(e.target.value);
              if (onChange) {
                onChange(e.target.value);
              }
            }}
            label={label}
            InputLabelProps={{
              shrink: true
            }}
            InputProps={{
              endAdornment: endIcon ? <InputAdornment position="end">{endIcon}</InputAdornment> : null
            }}
            sx={{
              backgroundColor: theme.palette.background.default,
              borderRadius: '4px',
              minWidth: '12em',
              ...sx
            }}
            error={!!error}
            helperText={error ? error.message : null}
            required={required}
            inputRef={ref}
            inputProps={{
              min: minDate || '',
              max: maxDate || ''
            }}
          />
        )}
      />
      {error && <ErrorFormInput error={error} />}
    </Stack>
  );
}
