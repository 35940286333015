import { Card, Stack, Typography } from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';

export default function SearchNotFoundCard() {
  return (
    <Card sx={{ width: '50%', alignSelf: 'center', marginTop: '4em !important' }}>
      <Stack spacing={2} alignItems="center" justifyContent="center" sx={{ padding: '1em' }}>
        <ErrorIcon color="error" />
        <Typography fontWeight="bold">Sorry, your search did not return any results</Typography>
        <Typography>Please check your search criteria and try again</Typography>
      </Stack>
    </Card>
  );
}
