import { FormControlLabel, Switch } from '@mui/material';
import { Controller, Control, FieldError } from 'react-hook-form';

interface ToggleFormInputProps {
  control: Control<any>;
  name: string;
  label?: string;
  error?: FieldError;
}

export default function ToggleFormInput({ control, name, label, error }: ToggleFormInputProps) {
  return (
    <Controller
      control={control}
      name={name}
      defaultValue={false}
      render={({ field: { onChange, value, ref } }) => (
        <>
          <FormControlLabel control={<Switch checked={value} onChange={onChange} ref={ref} />} label={label || ''} />
          {error && <p style={{ color: 'red', fontSize: '0.8rem' }}>{error.message}</p>}
        </>
      )}
    />
  );
}
