/* eslint-disable react-hooks/exhaustive-deps */
import { MenuItem, Pagination, Stack, Table, TableBody, TableCell, Typography, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import {
  DarkUpperSection,
  LoadingSpinner,
  NewSSCCForm,
  PageSection,
  SearchField,
  SearchNotFoundCard,
  StyledButton,
  StyledSelect,
  StyledSwitch,
  StyledTableHead,
  StyledTableRow
} from '../../components';
import { useEffect, useState } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import { useAlert, useCurrentUser, useDatastore, useIsOnline } from '../../hooks';
import { SSCCProps, ReceiptEventSiteProps, SsccType } from '../../datastore/ssccs';
import { useLocation, useNavigate } from 'react-router-dom';
import { NewSsccModel } from '../../components/modals/NewSSCCForm';
import { buildSSCC, SsccStatus, buildReceiptEvent, dateFormat, timeFormat } from '../../common';
import { LocationProps, UnknownLocation } from '../../datastore/locations';
import OfflineCard from '../../components/cards/OfflineCard';
import useObserveSsccs from '../../hooks/useSubscribeSsccs';
import { galaxyNoteThreeDimensions } from '../../common/strings';
import { CurrentStatusDate } from './components/CurrentStatusDate';
import { StatusDate } from './components/StatusDate';
import { transportBookingLocationAlias } from '../../datastore/transportBookingEvent';
import { sumQuantity } from '../../common/helperFunctions/sscc';
import { SsccPrintProps } from '../../../../Common/Types/types';
import { printSscc } from '../../common/helperFunctions/print';
import dayjs from 'dayjs';
import { yearMonthDateFormat } from '../../common/dateTimes';
import { UserDetailsProps } from '../../datastore/userDetails';

export default function ManageSsccs() {
  const theme = useTheme();
  const store = useDatastore();
  const user = useCurrentUser();
  const isMobile = useMediaQuery(galaxyNoteThreeDimensions);
  const { isOnline } = useIsOnline();
  const navigate = useNavigate();
  const alert = useAlert();
  const location = useLocation();
  const setPreviousSearch = location.state?.returnNavigation;
  const initialSearchValues = setPreviousSearch
    ? getSearchHistory()
    : { search: '', searchSites: '', searchStatus: '' };
  const [search, setSearch] = useState(initialSearchValues.search);
  const [searchSites, setSearchSites] = useState(initialSearchValues.searchSites);
  const [searchStatus, setSearchStatus] = useState(initialSearchValues.searchStatus);
  const [hideDelivered, setHideDelivered] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState<SSCCProps[]>([]);
  const [openNewModal, setOpenNewModal] = useState(false);
  const [locations, setLocations] = useState<LocationProps[]>([]);
  const [isLoadingLocations, setIsLoadingLocations] = useState(true);
  const [userLocation, setUserLocation] = useState<LocationProps>();
  const [isSsccPrintLoading, setIsSsccPrintLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [userDetails, setUserDetails] = useState<UserDetailsProps | null>();
  const [dateLimit, setDateLimit] = useState<number>(0);
  const ssccs = useObserveSsccs(dateLimit);

  useEffect(() => {
    async function getUser() {
      const result = await store.userDetails.get(user.cognitoUser.username);
      setUserDetails(result);
    }
    getUser();
  }, []);

  useEffect(() => {
    async function getData() {
      const ssccsResults = applySearchFilter(ssccs, search, searchSites, searchStatus, hideDelivered);

      if (ssccsResults && userDetails) {
        if (userDetails.location) {
          setUserLocation(userDetails?.location);
        }

        setData(ssccsResults);
        setIsLoading(false);
      }
    }

    getData();
  }, [search, searchSites, searchStatus, hideDelivered, openNewModal, dateLimit, ssccs, userDetails]);

  useEffect(() => {
    async function fetchLocations() {
      const locationResults = await store.locations.getList();
      const allLocations = locationResults?.filter((l) => !l.isDisabled) || [];
      setLocations(allLocations);
      setIsLoadingLocations(false);
    }

    fetchLocations();
  }, []);

  useEffect(() => {
    setCurrentPage(1);
  }, [search, searchSites, searchStatus, hideDelivered, dateLimit]);

  function applySearchFilter(
    data: SSCCProps[],
    search: string,
    siteName?: string,
    status?: string,
    hideDelivered?: boolean
  ) {
    let results = data;
    if (!results) return;

    if (search.length) {
      const lowerSearch = search.toLowerCase();
      results = results.filter(
        (sscc) =>
          sscc.id.toString().includes(lowerSearch) ||
          sscc.receiptedEvents?.some(
            (s) => s?.name?.toLowerCase().includes(lowerSearch) || s?.site?.toLowerCase().includes(lowerSearch)
          )
      );
    }

    if (siteName?.length) {
      results = results.filter((sscc) => sscc.receiptedEvents?.some((s) => s?.name === siteName));
    }

    if (status?.length) {
      results = results.filter((sscc) => sscc.status?.toLowerCase() === status);
    }

    if (hideDelivered) {
      results = results.filter((sscc) => sscc.status !== SsccStatus.delivered);
    }

    const sortedResults = results
      .filter((sscc) => sscc.originLocationId === userDetails?.location?.id)
      .sort((a, b) => {
        const dateA = a.open;
        const dateB = b.open;

        return dateB?.diff(dateA) || 0;
      });

    return sortedResults;
  }

  function storeSearchHistory() {
    const key = `${user.email}_searchText`;
    const searchHistory = {
      search,
      searchSites,
      searchStatus
    };

    const value = JSON.stringify(searchHistory);
    localStorage.setItem(key, value);
  }

  function getSearchHistory() {
    const searchHistoryString = localStorage.getItem(user.email);
    if (searchHistoryString) {
      return JSON.parse(searchHistoryString);
    } else {
      return { search: '', searchSites: '', searchStatus: '' };
    }
  }

  async function onCreateNew(data: NewSsccModel) {
    if (!userLocation || !userLocation.alias || !userLocation.id) {
      alert('You must set a location in `settings` before you can create an SSCC', 'warning');
      return;
    }
    setIsSsccPrintLoading(true);
    const newSSCC = buildSSCC({
      locationAlias: userLocation.alias,
      destinationLocationId: data.site,
      originLocationId: userLocation.id,
      isLargeItem: false,
      isTransportBooking: false,
      transportBookingDestination: null,
      height: data.height,
      width: data.width,
      depth: data.depth,
      weight: undefined,
      ssccStatus: SsccStatus.open,
      ssccType: SsccType.standard,
      stagingLocation: undefined,
      nickname: data.nickname
    });

    const newSite = buildReceiptEvent({
      name: locations.find((location) => location.id === data.site)?.name || '',
      site: locations.find((location) => location.id === data.site)?.alias || ''
    });

    if (!newSSCC) {
      alert('An error has occured whilst creating the SSCC, try again.', 'warning');
      return;
    }

    newSSCC.receiptedEvents.push(newSite);
    const ssccPrint: Partial<SsccPrintProps> = {
      nickname: newSSCC.nickname || '',
      createdAt: dayjs().format(yearMonthDateFormat),
      ssccId: newSSCC.id,
      destinationAlias: locations.find((location) => location.id === data.site)?.alias || ''
    };

    try {
      await store.ssccs.create(newSSCC);
      alert('Created SSCC', 'success');

      const userPrinter = await store.printers.getPrinterForUser(user.cognitoUser.username);
      if (!userPrinter) {
        alert('No printer found, please set a printer and reprint from Manage SSCC screen', 'warning');
        return;
      }
      await printSscc([ssccPrint], userPrinter?.printerIP, userPrinter?.printerPort, 'ftl_ssccOpen_print');
    } catch (error) {
      alert('An error occured when creating the SSCC', 'error');
      console.error(error);
    }

    setIsSsccPrintLoading(false);
    setOpenNewModal(false);
  }

  function handlePageChange(event: React.ChangeEvent<unknown>, value: number) {
    setCurrentPage(value);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  const pageSize = 50;
  const totalNumberOfPages = Math.ceil(data.length / pageSize);
  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = Math.min(startIndex + pageSize, data.length);
  const paginatedSsccs = data.slice(startIndex, endIndex);

  const orderedSites = [
    { value: '', name: 'No Filter' },
    ...locations.map((location) => ({ value: location.name, name: location.name }))
  ].sort((a, b) => {
    if (a.name === 'No Filter') return -1;
    if (b.name === 'No Filter') return 1;
    return a.name.localeCompare(b.name);
  });

  const ssccStatuses = [
    { key: '', value: 'No Filter' },
    ...Object.entries(SsccStatus).map(([key, value]) => ({ key, value }))
  ].filter((status) => status.value !== SsccStatus.cancelled);

  const renderSwitches = () => (
    <>
      <StyledSelect
        label={searchSites === '' ? 'Filter Sites' : ''}
        value={searchSites}
        disabled={isLoadingLocations}
        disabledOnDark={true}
        shrink={false}
        onChange={setSearchSites}>
        {orderedSites.map((location, i) => (
          <MenuItem key={`${location.name}-${i}-${location.value}`} value={location.value}>
            {location.name}
          </MenuItem>
        ))}
      </StyledSelect>
      <StyledSelect
        label={searchStatus === '' ? 'Filter Status' : ''}
        shrink={false}
        value={searchStatus}
        onChange={setSearchStatus}>
        {ssccStatuses.map((status) => (
          <MenuItem key={status.value} value={status.key}>
            {status.value}
          </MenuItem>
        ))}
      </StyledSelect>
      <StyledSelect value={dateLimit?.toString()} onChange={(value) => setDateLimit(Number(value))}>
        <MenuItem value="0">None</MenuItem>
        <MenuItem value="1">Last 24 Hours</MenuItem>
        <MenuItem value="7">Last 7 Days</MenuItem>
        <MenuItem value="14">Last 14 Days</MenuItem>
        <MenuItem value="30">Last 30 Days</MenuItem>
      </StyledSelect>
    </>
  );

  return (
    <>
      <DarkUpperSection>
        <Stack spacing={2} sx={{ padding: '1em 0' }}>
          <Typography variant="h2" fontWeight="bold" color={theme.palette.primary.contrastText}>
            Manage SSCCs
          </Typography>
          <Stack direction="row" spacing={3} alignItems="center">
            <SearchField
              sx={isMobile ? { width: '300px' } : {}}
              placeholder="Search by SSCC or Site"
              startIcon={<SearchIcon />}
              onTrigger={setSearch}
              value={search}
            />
            {!isMobile && renderSwitches()}
            <StyledSwitch value={hideDelivered} onChange={setHideDelivered} label="Hide Delivered" />
            <Stack direction="row" sx={{ flex: 1, justifyContent: 'end' }}>
              <StyledButton variant="contained" onClick={() => setOpenNewModal(true)} disabled={!isOnline}>
                Create New SSCC
              </StyledButton>
            </Stack>
          </Stack>
          <Stack direction="row" spacing="2em">
            {isMobile && renderSwitches()}
          </Stack>
        </Stack>
      </DarkUpperSection>
      {isOnline ? (
        <PageSection>
          <Stack spacing={2} sx={{ marginTop: '1em' }}>
            <LoadingSpinner isLoading={isLoading}>
              {search && paginatedSsccs.length === 0 ? (
                <SearchNotFoundCard />
              ) : (
                <Table>
                  <StyledTableHead>
                    <TableCell width={isMobile ? '25%' : '14%'}>SSCC #</TableCell>
                    <TableCell width={isMobile ? '20%' : '13%'}>Nickname</TableCell>
                    <TableCell width={isMobile ? '13%' : '8%'}>Item Count</TableCell>
                    <TableCell width={isMobile ? '30%' : '16%'}>Site</TableCell>
                    {isMobile ? (
                      <>
                        <TableCell width="12%">Status</TableCell>
                      </>
                    ) : (
                      <>
                        <TableCell width="8%">Open</TableCell>
                        <TableCell width="8%">Staged</TableCell>
                        <TableCell width="8%">Manifested</TableCell>
                        <TableCell width="8%">In Transit</TableCell>
                        <TableCell width="8%">Approaching</TableCell>
                        <TableCell width="8%">Delivered</TableCell>
                      </>
                    )}
                  </StyledTableHead>
                  <TableBody>
                    {paginatedSsccs.map((sscc) => {
                      const openDate = sscc.open?.format(dateFormat);
                      const openTime = sscc.open?.format(timeFormat);
                      const stagedDate = sscc.staged?.format(dateFormat);
                      const stagedTime = sscc.staged?.format(timeFormat);
                      const manifestedDate = sscc.manifested?.format(dateFormat);
                      const manifestedTime = sscc.manifested?.format(timeFormat);
                      const inTransitDate = sscc.inTransit?.format(dateFormat);
                      const inTransitTime = sscc.inTransit?.format(timeFormat);
                      const approachingDate = sscc.approaching?.format(dateFormat);
                      const approachingTime = sscc.approaching?.format(timeFormat);
                      const deliveredDate = sscc.delivered?.format(dateFormat);
                      const deliveredTime = sscc.delivered?.format(timeFormat);
                      const uniqueSites: ReceiptEventSiteProps[] = (sscc.receiptedEvents || []).reduce<
                        ReceiptEventSiteProps[]
                      >((acc, site) => {
                        if (!acc.some((s) => s.name === site.name)) acc.push(site);
                        return acc;
                      }, []);

                      return uniqueSites.map((site, i) => {
                        const isFirst = i === 0;
                        const isLast = i === uniqueSites.length - 1;

                        return (
                          <StyledTableRow
                            key={`${sscc.nickname}${i}${sscc.id}`}
                            onClick={() => {
                              storeSearchHistory();
                              navigate(`${sscc.id}`);
                            }}
                            sx={
                              isLast
                                ? { cursor: 'pointer' }
                                : {
                                    cursor: 'pointer',
                                    '& td': {
                                      padding: '1em 0',
                                      textAlign: 'start',
                                      borderBottomWidth: 0
                                    }
                                  }
                            }>
                            <TableCell sx={{ fontWeight: 'bold' }}>{isFirst ? sscc.id : ''}</TableCell>
                            <TableCell>{isFirst ? sscc.nickname : null}</TableCell>
                            <TableCell>
                              <Typography>
                                {isFirst
                                  ? sscc?.receiptedEvents?.reduce((acc, site) => {
                                      const siteTotal = site?.packages?.reduce(sumQuantity, 0) || 0;
                                      return acc + siteTotal;
                                    }, 0)
                                  : null}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Stack>
                                <Typography>
                                  {sscc.isTransportBooking
                                    ? sscc.transportBookingDestination?.businessName || 'Unknown'
                                    : site.name}
                                </Typography>
                                <Typography variant="body2" color={theme.palette.text.secondary}>
                                  {sscc.isTransportBooking
                                    ? transportBookingLocationAlias(sscc.transportBookingDestination)
                                    : site.site}
                                </Typography>
                              </Stack>
                            </TableCell>
                            {isMobile ? (
                              <TableCell>
                                {isFirst ? <CurrentStatusDate sscc={sscc} makeStatusBold /> : <></>}
                              </TableCell>
                            ) : (
                              <>
                                <TableCell>
                                  {isFirst && <StatusDate date={openDate} time={openTime} nextDate={stagedDate} />}
                                </TableCell>
                                <TableCell>
                                  {isFirst && (
                                    <StatusDate date={stagedDate} time={stagedTime} nextDate={manifestedDate} />
                                  )}
                                </TableCell>
                                <TableCell>
                                  {isFirst && (
                                    <StatusDate date={manifestedDate} time={manifestedTime} nextDate={inTransitDate} />
                                  )}
                                </TableCell>
                                <TableCell>
                                  {isFirst && (
                                    <StatusDate date={inTransitDate} time={inTransitTime} nextDate={approachingDate} />
                                  )}
                                </TableCell>
                                <TableCell>
                                  {isFirst && (
                                    <StatusDate
                                      date={approachingDate}
                                      time={approachingTime}
                                      nextDate={deliveredDate}
                                    />
                                  )}
                                </TableCell>
                                <TableCell>
                                  {isFirst && <StatusDate date={deliveredDate} time={deliveredTime} nextDate={''} />}
                                </TableCell>
                              </>
                            )}
                          </StyledTableRow>
                        );
                      });
                    })}
                  </TableBody>
                </Table>
              )}
            </LoadingSpinner>
          </Stack>
          <Stack spacing={2} direction="row" justifyContent="center" sx={{ paddingTop: '1em', paddingBottom: '1em' }}>
            <Pagination count={totalNumberOfPages} page={currentPage} onChange={handlePageChange} color="primary" />
          </Stack>
        </PageSection>
      ) : (
        <PageSection>
          <Stack justifyContent="center">
            <OfflineCard />
          </Stack>
        </PageSection>
      )}
      {openNewModal && (
        <NewSSCCForm
          open={openNewModal}
          onClose={() => setOpenNewModal(false)}
          onSubmit={onCreateNew}
          isLoading={isSsccPrintLoading}
          sites={locations
            .map((location) => ({ name: location.name, value: location.id, type: location.type }))
            .filter((location) => location.name !== UnknownLocation.Name)}
          prePopulateSite={false}
        />
      )}
    </>
  );
}
